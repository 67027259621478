import { Link, useLocation } from "react-router-dom";
import { AppBar, Button, FormGroup, Switch, Toolbar } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useStoreDispatch, useStoreSelector } from "../hooks/useStore";
import { selectKeyword } from "../store/filterSlice";
import { selectTheme, switchTheme } from "../store/themeSlice";
import { selectUserId } from "../store/userSlice";
import { VIEW_HISTORY_USERS } from "../data/constants";
import { FaChartBar, FaClipboardList } from "react-icons/fa";

const PREFIX = "NavBar";

const classes = {
  container: `${PREFIX}-container`,
};

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  [`& .${classes.container}`]: {
    height: "5vh",
    borderBottom: "1px solid " + theme.palette.text.primary,
  },
}));

const ThemeSwitch = styled(Switch)(({ theme }) => ({
  width: 55,
  height: 30,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        right: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "60%",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="%23c2c6ed" d="M20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69L23.31 12 20 8.69zM12 18c-.89 0-1.74-.2-2.5-.55C11.56 16.5 13 14.42 13 12s-1.44-4.5-3.5-5.45C10.26 6.2 11.11 6 12 6c3.31 0 6 2.69 6 6s-2.69 6-6 6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.background.paper,
    width: 28,
    height: 28,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      right: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "60%",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="%23dbad09" d="M20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69L23.31 12 20 8.69zM12 18c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm0-10c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const NavBar = () => {
  const theme = useTheme();
  const location = useLocation();

  const userId = useStoreSelector(selectUserId);
  const dispatch = useStoreDispatch();
  const keyword = useStoreSelector(selectKeyword);
  const darkTheme = useStoreSelector(selectTheme) === "dark";

  return (
    <AppBarStyled
      position={"static"}
      className={classes.container}
      sx={{ bgcolor: "background.default", boxShadow: 0 }}
    >
      <Toolbar variant={"dense"} style={{ gap: 10 }}>
        <Link to={keyword ? `/?keyword=${keyword}` : "/"} style={{ textDecoration: "unset" }}>
          <Button
            sx={{
              color: location.pathname === "/" ? "primary.main" : "text.primary",
              fontWeight: "bold",
            }}
          >
            Home
          </Button>
        </Link>
        <Link to={keyword ? `/dashboard?keyword=${keyword}` : "/dashboard"} style={{ textDecoration: "unset" }}>
          <Button
            sx={{ color: location.pathname === "/dashboard" ? "primary.main" : "text.primary", fontWeight: "bold" }}
          >
            Dashboard
          </Button>
        </Link>
        {VIEW_HISTORY_USERS.includes(parseInt(userId.toString())) && (
          <>
            <Link to={"/price-changes"} style={{ textDecoration: "unset" }}>
              <Button
                sx={{ color: location.pathname === "/price-changes" ? "primary.main" : "text.primary", fontWeight: "bold" }}
                startIcon={<FaChartBar />}
              >
                Preishistorie
              </Button>
            </Link>
            <Link to={keyword ? `/offer-history?keyword=${keyword}` : "/offer-history"} style={{ textDecoration: "unset" }}>
              <Button
                sx={{ color: location.pathname === "/offer-history" ? "primary.main" : "text.primary", fontWeight: "bold" }}
                startIcon={<FaClipboardList />}
              >
                Angebots Log
              </Button>
            </Link>
          </>
        )}
        <FormGroup style={{ marginLeft: "auto" }}>
          <ThemeSwitch theme={theme} checked={darkTheme} onClick={() => dispatch(switchTheme())} />
        </FormGroup>
      </Toolbar>
    </AppBarStyled>
  );
};

export default NavBar;
