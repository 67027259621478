import { useCallback, useEffect, useState } from 'react';
import { Box, TableHead, TableContainer, Table, TableBody, TableRow, TableCell, TableSortLabel, Card, Checkbox, Toolbar, Typography, IconButton } from '@mui/material';
import { FaTrash } from 'react-icons/fa';
import { CgMergeHorizontal } from 'react-icons/cg';
import { captureException, captureMessage } from '@sentry/react';
import { IOffer } from '../data/constants';
import { OfferContext } from '../data/contexts';
import { useStoreDispatch, useStoreSelector } from '../hooks/useStore';
import { fetchOffers, removeOffer, checkAllOffers, selectOffers, selectOffersChecked, sortOffers, selectOffersMaxAmount, selectIsSearching } from '../store/offersSlice';
import { alert } from '../store/alertSlice';
import APIOffers from '../helper/APIOffers';
import DashboardOffer from '../components/Offers/DashboardOffer';
import Button from '../components/Button';
import useLoadMoreButtonRef from '../hooks/useLoadMoreButton';

interface ITableHeadCell {
    id: keyof IOffer | 'undo_button',
    align: "right" | "left" | "inherit" | "center" | "justify",
    disablePadding: boolean,
    label: string
}

const Dashboard = ({ enqueueCallback }: { enqueueCallback: (callback: () => any) => Promise<any> }) => {
    const dispatch = useStoreDispatch();
    const offerApi = new APIOffers(dispatch);

    const offers = useStoreSelector(selectOffers);
    const maxAmountOffers = useStoreSelector(selectOffersMaxAmount);
    const checkedOffers = useStoreSelector(selectOffersChecked);
    const loading = useStoreSelector(selectIsSearching);
    const numberCheckedOffers = checkedOffers.length;

    const loadMore = useCallback(() => {
        if (offers.length < maxAmountOffers) {
            enqueueCallback(() => dispatch(fetchOffers()));
        }
    }, [dispatch, offers.length, maxAmountOffers, enqueueCallback]);

    useEffect(() => {
        enqueueCallback(() => dispatch(fetchOffers()));
    }, [dispatch, enqueueCallback]);

    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<keyof IOffer>('email');
    const loadMoreRef = useLoadMoreButtonRef(loadMore);

    ///////////////////////////////////////////
    //
    //                Sorting
    //
    ///////////////////////////////////////////

    const handleRequestSort = (property: keyof IOffer | 'undo_button') => {
        if (property === 'undo_button') return;

        const newOrder = orderBy === property && order === 'asc' ? 'desc' : 'asc';

        dispatch(sortOffers({ order: newOrder, orderBy: orderBy }));
        setOrder(newOrder);
        setOrderBy(property);
    }

    ///////////////////////////////////////////
    //
    //               Merging
    //
    ///////////////////////////////////////////

    /**
     * Merges all offers that were checked
     * @returns when it fails
     */
    const handleMergeAllChecked = () => {
        if (checkedOffers.length < 2) {
            dispatch(alert({ severity: 'error', message: 'Braucht mehr als 1 ausgewähltes Angebot.' }));
            return
        }

        const checkEmailDuplicate = checkedOffers.filter((offer, index, array) => array.findIndex(element => element.email === offer.email) === index)
        if (checkEmailDuplicate.length !== 1) {
            dispatch(alert({ severity: 'error', message: 'Nicht alle ausgewählten Angebote sind vom selben Kunden (E-Mail).' }));
            return
        }

        offerApi.mergeOffers(checkedOffers.map(offer => offer.angebot_id))
            .then(response => {
                if (response.data) {
                    enqueueCallback(() => dispatch(fetchOffers()));
                    dispatch(alert({ severity: 'success', message: `Die Angebote wurden erfolgreich zusammengeführt. Neue Id ist ${response.data}.` }));
                } else {
                    dispatch(alert({ severity: 'error', message: 'Bei der Zusammenführung der Angebote ist ein Fehler aufgetreten.' }));
                }
            })
            .catch(error => {
                dispatch(alert({ severity: 'error', message: 'Bei der Zusammenführung der Angebote ist ein Fehler aufgetreten.' }));
                captureException(error);
            })
    }

    /**
     * Closes all checked offers, setting their state to 7
     */
    const handleCloseCheckedOffers = () => {
        const newStatus = 7.00;

        checkedOffers.forEach(offer => {
            offerApi.changeOfferStatus(offer.angebot_id, offer.status_id, newStatus)
                .then(response => {
                    if (response.data === newStatus) {
                        dispatch(removeOffer({ offerId: offer.angebot_id }));
                        dispatch(alert({ severity: 'success', message: `Angebot gespeichert. Neuer Status: ${response.data.msg}.` }));
                    } else {
                        dispatch(alert({ severity: 'error', message: 'Fehler beim Speichern des Angebots.' }));
                        captureMessage('Fehler beim Speichern des Angebots' + response)
                    }
                })
                .catch(error => {
                    dispatch(alert({ severity: 'error', message: 'Fehler beim Speichern des Angebots.' }));
                    captureException(error);
                })
        });
    }

    ///////////////////////////////////////////
    //
    //                Rendering
    //
    ///////////////////////////////////////////

    const enhancedTableHeadCells: ITableHeadCell[] = [
        { id: 'angebot_id', align: 'left', disablePadding: true, label: 'Angebot-ID' },
        { id: 'status_id', align: 'left', disablePadding: false, label: 'Status-ID' },
        { id: 'first_status_date', align: 'left', disablePadding: false, label: 'Datum' },
        { id: 'name', align: 'left', disablePadding: false, label: 'Kundenname' },
        { id: 'email', align: 'left', disablePadding: false, label: 'Kunden E-mail' },
        { id: 'undo_button', align: 'center', disablePadding: false, label: '' },
    ];

    return (
        <Box px={4} sx={{ bgcolor: 'background.default' }}>
            <Card style={{ overflow: 'visible' }}>
                {/* ---- Toolbar ---- */}
                <Toolbar
                    sx={{
                        paddingLeft: 16,
                        paddingRight: 16,
                        position: "fixed",
                        top: 46,
                        left: 32,
                        right: 32,
                        zIndex: 1,
                        bgcolor: numberCheckedOffers > 0 ? "secondary.main" : "background.paper",
                    }}
                >
                    <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography color={'inherit'} variant={'h6'} sx={{ color: 'text.primary' }}>
                            {numberCheckedOffers} ausgewählt
                        </Typography>
                        <Box>
                            <IconButton onClick={handleMergeAllChecked} sx={{ color: 'text.primary' }} title={'Zusammenfassen'}>
                                <CgMergeHorizontal />
                            </IconButton>
                            <IconButton onClick={handleCloseCheckedOffers} sx={{ color: 'text.primary' }} title={'Löschen'}>
                                <FaTrash />
                            </IconButton>
                        </Box>
                    </Box>
                </Toolbar>

                {/* ---- TableContainer ---- */}
                <TableContainer>
                    <Table
                        aria-labelledby={'tableTitle'}
                        aria-label={'enhanced table'}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell padding={'checkbox'}>
                                    <Checkbox
                                        indeterminate={numberCheckedOffers > 0 && numberCheckedOffers < offers.length}
                                        checked={offers.length > 0 && numberCheckedOffers === offers.length}
                                        onChange={() => dispatch(checkAllOffers())}
                                        inputProps={{ 'aria-label': 'select all desserts' }}
                                        sx={{ color: 'text.primary' }}
                                    />
                                </TableCell>
                                {enhancedTableHeadCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.align}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                        sx={{ color: 'text.primary' }}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={() => handleRequestSort(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {offers.map(offer => (
                                <OfferContext.Provider value={offer} key={offer.angebot_id}>
                                    <DashboardOffer />
                                </ OfferContext.Provider>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </Card>
            <Box sx={{ textAlign: "center" }} mt={2} ref={loadMoreRef}>
                <Button
                    color={'primary'}
                    onClick={() => enqueueCallback(() => dispatch(fetchOffers()))}
                    disabled={offers.length === maxAmountOffers || loading}
                >
                    Mehr laden
                </Button>
            </Box>
        </Box>
    )
}

export default Dashboard;
