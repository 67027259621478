import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/react';
import axios from 'axios';
import { DEBUG, DEBUG_USER, MARIO_URL } from '../data/constants';
import type { RootState } from './store';

export interface IUserState {
    id: number;
    name: string;
    loginString: string;
}

interface IPermissionResponse {
    userId: number;
    userName: string;
    loginString: string;
}

const initialState: IUserState = {
    id: 0,
    name: '',
    loginString: ''
}

/**
 * Uses the passed login data to attempt a login and fetch token and permissions for the user, also storing the token locally
 */
export const attemptLogin = createAsyncThunk<IUserState, void, { state: RootState }>(
    'attemptLogin',
    async (_, { rejectWithValue }) => {
        if (DEBUG) return DEBUG_USER;

        try {
            const userData = (await axios.get<IPermissionResponse>(`${MARIO_URL}/ankauf-4.0/php/permission.php`, { withCredentials: true })).data;
            if (!userData.userId || userData.userId === 0) window.location.href = `${MARIO_URL}/SecureLogin/login.php`;

            return { id: userData.userId, name: userData.userName, loginString: userData.loginString };
        } catch (error) {
            captureException(error);
            return rejectWithValue(error);
        }
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
        builder
            .addCase(attemptLogin.fulfilled, (state, action: PayloadAction<IUserState>) => {
                state.id = action.payload.id;
                state.name = action.payload.name;
                state.loginString = action.payload.loginString;
            })
    }
})

export const selectUser = (state: RootState) => state.user;
export const selectUserId = (state: RootState) => state.user.id;
export const selectUserName = (state: RootState) => state.user.name;
export const selectUserLoginString = (state: RootState) => state.user.loginString;

export default userSlice.reducer;