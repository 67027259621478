import { EWordfilterBlocked, IOffersState } from "../store/offersSlice";

const sanitizeArticleName = (name: string) => {
  return name.replace("+ Netzteil", "").replace("+ Netzkabel", "").replace(/\s\s+/, " ").trim();
};

const getArticleName = (name: string) => {
  name = sanitizeArticleName(name);
  const index = name.indexOf("(");
  return name.substring(0, index !== -1 ? index : name.length).trim();
};

const getArticleCondition = (name: string) => {
  const index = name.indexOf("(");
  return index !== -1 ? name.substring(index + 1, name.length).trim() : "";
};

const getArticleLanguage = (name: string) => {
  const attributes = getArticleCondition(name);

  const languages = [];
  if (attributes.match(/[/(]DE[/)]/) || attributes.match(/[/(]DEUTSCH[/)]/)) languages.push("DE");
  if (attributes.match(/[/(]EN[/)]/) || attributes.match(/[/(]ENGLISCH[/)]/) || attributes.match(/[/(]UK[/)]/))
    languages.push("UK");
  if (attributes.match(/[/(]US[/)]/)) languages.push("US");
  if (attributes.match(/[/(]JAP[/)]/) || attributes.match(/[/(]JAP Version[/)]/) || attributes.match(/[/(]JPN[/)]/))
    languages.push("JP");
  if (attributes.match(/[/(]FR[/)]/) || attributes.match(/[/(]FRANZÖSISCH[/)]/)) languages.push("FR");
  if (attributes.match(/[/(]IT[/)]/) || attributes.match(/[/(]ITALIENISCH[/)]/)) languages.push("IT");

  return languages;
};

const getArticleConditionColor = (name: string) => {
  const condition = getArticleCondition(name).toLowerCase();

  if (condition.includes("defekt")) return "error.main";
  if (condition.includes("ohne zubehör")) return "primary.main";
  if (condition.includes("mit ovp")) return "success.main";
  if (condition.includes("neu & ovp")) return "success.main";
  if (condition.includes("nur cd")) return "warning.main";
  if (condition.includes("(modul)")) return "warning.main";
  if (condition.includes("ohne ovp")) return "warning.main";
}

const normalizeName = (name: string) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9ßäöü]+/, "")
    .replace(/\s+/, "");
}

const wordFilterDescription = (description: string, wordFilter: IOffersState["wordfilter"]) => {
  let _description = description;
  const sorted = [...wordFilter]
    .filter(
      ({ isBlocked }) =>
        isBlocked === EWordfilterBlocked.BLOCKED ||
        isBlocked === EWordfilterBlocked.POSITIVE ||
        isBlocked === EWordfilterBlocked.HIGHLIGHT
    )
    .sort((a, b) => b.word.length - a.word.length);

  for (const { word, isBlocked, wordException } of sorted) {
    const color =
      isBlocked === EWordfilterBlocked.BLOCKED
        ? "#ba1818"
        : isBlocked === EWordfilterBlocked.POSITIVE
        ? "#1ba11b"
        : isBlocked === EWordfilterBlocked.HIGHLIGHT
        ? "#f5a623"
        : "#007bff";
    const exceptionPositions = wordException.map((exception) => {
      const index = exception.indexOf(exception);
      return { prefix: exception.substring(0, index), suffix: exception.substring(index + word.length) };
    });

    const excludePrefixes = exceptionPositions.map(({ prefix }) => prefix).join("|");
    const excludeSuffixes = exceptionPositions.map(({ suffix }) => suffix).join("|");
    const regexp = new RegExp(
      `(?<!\\>)\\b${excludePrefixes && `(?<!${excludePrefixes})`}(${word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})${
        excludeSuffixes && `(?!${excludeSuffixes})`
      }\\b(?!\\<)`,
      "ig"
    );

    _description = _description.replaceAll(regexp, `<span style="color: ${color}">$1</span>`);
  }

  return _description;
}

const replaceTerms = (name: string, wordFilter: IOffersState["wordfilter"]) => {
  const replacements = [...wordFilter].filter(({ isBlocked }) => isBlocked === EWordfilterBlocked.REPLACE);
  return replacements.reduce(
    (result, { word, wordException }) => result.replaceAll(new RegExp(`\\b${word}\\b`, "ig"), wordException[0]),
    name
  );
};

export default function useArticleHelpers() {
  return {
    sanitizeArticleName,
    getArticleName,
    getArticleCondition,
    getArticleLanguage,
    getArticleConditionColor,
    normalizeName,
    replaceTerms,
    wordFilterDescription
  }
}