import { PaletteMode } from '@mui/material';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { captureException } from '@sentry/react';
import type { RootState } from './store';

export interface IThemeState {
    dark: PaletteMode;
}

const initialState: IThemeState = {
    dark: 'light'
}

/**
 * Fetches the locally stored theme or returns the initial state
 */
export const fetchTheme = createAsyncThunk<PaletteMode, { prefersDarkMode: boolean }, { state: RootState }>(
    'fetchTheme',
    async ({ prefersDarkMode }, { rejectWithValue }) => {
        try {
            const darkMode = localStorage.getItem('darkTheme');
            return darkMode ? (darkMode as 'dark' | 'light') : (prefersDarkMode ? 'dark' : 'light');
        } catch (error) {
            captureException(error);
            return rejectWithValue(error);
        }
    }
)

/**
 * Sets the theme state simultaneously saving it to the local storage
 */
export const switchTheme = createAsyncThunk<PaletteMode, void, { state: RootState }>(
    'switchTheme',
    async (_, { getState, rejectWithValue }) => {
        try {
            const { theme } = getState();
            const newTheme = theme.dark === 'dark' ? 'light' : 'dark';
            localStorage.setItem('darkTheme', newTheme);
            return newTheme;
        } catch (error) {
            captureException(error);
            return rejectWithValue(error);
        }
    }
)

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTheme.fulfilled, (state, action) => {
                state.dark = action.payload;
            })
            .addCase(fetchTheme.rejected, (_) => initialState)
            .addCase(switchTheme.fulfilled, (state, action) => {
                state.dark = action.payload;
            })
            .addCase(switchTheme.rejected, (_) => initialState)
    }
})

export const selectTheme = (state: RootState) => state.theme.dark;

export default themeSlice.reducer;