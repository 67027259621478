import { useState } from "react";

interface UploadState {
  loading: boolean;
  done: boolean;
  progress?: number;
  data?: any;
  xhr?: XMLHttpRequest;
  responseHeaders?: any;
  error?: any;
}

const useUpload = (
  beforeRequest: (options: { xhr: XMLHttpRequest; files: File[] }) => Promise<{
    method: string;
    url: string;
    headers?: Record<string, string>;
    body?: any;
  }>,
  onUpload?: (data: any, xhr: XMLHttpRequest, responseHeaders: Record<string, string>) => void
) => {
  const [state, setState] = useState<UploadState>({
    loading: false,
    done: false,
  });

  const reset = () => {
    setState({
      loading: false,
      done: false,
      error: undefined,
      progress: undefined,
    });
  }

  const upload = async ({ files }: { files: File[] }) => {
    setState({ loading: true, done: false });
    const xhr = new XMLHttpRequest();
    let options = await beforeRequest({ xhr, files }); // bail out if you return undefined from options

    if (!options) return setState({ loading: false, done: false });
    xhr.open(options.method, options.url);
    /*
      Helper method for setting headers on an xhr request, one of the only
      extra features of this hook
    */

    if (options.headers) {
      let headers = options.headers;
      Object.keys(options.headers).forEach((header) => xhr.setRequestHeader(header, headers[header]));
    }
    /*
      XHR Listeners
    */

    xhr.upload.addEventListener("progress", (event) => {
      setState((state) => ({ ...state, progress: Math.round((event.loaded / event.total) * 100) }));
    });
    xhr.addEventListener("load", () => {
      let data;

      try {
        data = JSON.parse(xhr.response);
      } catch (e) {
        data = xhr.response;
      }

      let responseHeaders = xhr
        .getAllResponseHeaders()
        .trim()
        .split(/[\r\n]+/)
        .map((line) => line.split(": "))
        .reduce((acc, [header, value]) => {
          acc[header] = value;
          return acc;
        }, {} as Record<string, string>);

      setState({
        data,
        loading: false,
        xhr,
        responseHeaders,
        done: true,
      });
      if (onUpload) onUpload(data, xhr, responseHeaders);
    });
    xhr.addEventListener("error", (error) => {
      setState({
        error,
        xhr,
        loading: false,
        done: true,
      });
    });
    xhr.addEventListener("abort", (error) => {
      setState({
        error,
        xhr,
        loading: false,
        done: true,
      });
    });
    /*
      send the request!
    */

    xhr.send(options.body);
  };

  return [upload, reset, state] as [(options: { files: File[] }) => Promise<void>, () => void, UploadState];
};

export default useUpload;
