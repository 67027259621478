import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  SelectChangeEvent,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
} from "@mui/material";
import Button from "./Button";
import { useStoreDispatch, useStoreSelector } from "../hooks/useStore";
import { ICustomFilter, IFilterState, selectFilter, setFilter } from "../store/filterSlice";
import { fetchOffers, selectStatusDescriptions } from "../store/offersSlice";
import DateFilter from "./Filter/DateFilter";
import StatusFilter from "./Filter/StatusFilter";

const PREFIX = "Searchbar";

const classes = {
  container: `${PREFIX}-container`,
  dialogContent: `${PREFIX}-dialogContent`,
  filterRow: `${PREFIX}-filterRow`,
  filterEnabled: `${PREFIX}-filterEnabled`,
  filterComparator: `${PREFIX}-filterComparator`,
  filterValue: `${PREFIX}-filterValue`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.container}`]: {
    flex: 1,
  },

  [`& .${classes.dialogContent}`]: {
    padding: "30px",
  },

  [`& .${classes.filterRow}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  [`& .${classes.filterEnabled}`]: {
    color: theme.palette.text.primary,
  },

  [`& .${classes.filterComparator}`]: {
    "& svg": { color: theme.palette.text.primary },
    "& fieldset": { border: 0, borderBottom: "1px solid" },
  },

  [`& .${classes.filterValue}`]: {
    "& svg": { color: theme.palette.text.primary },
    "& fieldset": { border: 0, borderBottom: "1px solid" },
    "& .Mui-disabled svg": { color: "rgba(0, 0, 0, 0.26)" },
  },
}));

interface IProps {
  open: boolean;
  handleClose: () => void;
  enqueueCallback: (callback: () => any) => Promise<any>;
}

interface IFilterSelectRow {
  filter: keyof Omit<IFilterState, "keyword" | "withHistory">;
  name: string;
  value: React.ReactChild;
}

const Searchbar = ({ open, handleClose, enqueueCallback }: IProps) => {
  const dispatch = useStoreDispatch();

  const filter = useStoreSelector(selectFilter);
  const statusDescriptions = useStoreSelector(selectStatusDescriptions);

  const [tempFilter, setTempFilter] = useState<IFilterState>(JSON.parse(JSON.stringify(filter)));

  ///////////////////////////////////////////
  //
  //            Logic Functions
  //
  ///////////////////////////////////////////

  /**
   * Save the temp filter to the global filter and fetch offers using the new filter
   */
  const handleSaveFilter = () => {
    dispatch(setFilter(tempFilter));
    enqueueCallback(() => dispatch(fetchOffers()));
    handleClose();
  };

  /**
   * Reset the temp filter to the global filter
   */
  const handleUndoFilter = () => {
    setTempFilter(JSON.parse(JSON.stringify(filter)));
    handleClose();
  };

  /**
   * Reset the temp filter to a copy of the global filter when it changes
   */
  useEffect(() => {
    setTempFilter(JSON.parse(JSON.stringify(filter)));
  }, [filter]);

  /**
   * Handle enabling a filter that isn't the keyword
   */
  const handleUpdateEnabled = (
    _filter: keyof Omit<IFilterState, "keyword" | "withHistory">,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    tempFilter[_filter].enabled = e.target.checked;
    setTempFilter({ ...tempFilter });
  };

  /**
   * Handle changing the comparator of a filter that isn't the keyword
   */
  const handleUpdateComparator = (
    _filter: keyof Omit<IFilterState, "keyword" | "withHistory">,
    e: SelectChangeEvent<ICustomFilter["comparator"]>
  ) => {
    tempFilter[_filter].comparator = e.target.value as ICustomFilter["comparator"];
    setTempFilter({ ...tempFilter });
  };

  /**
   * Handle updating the value of a filter that isn't the keyword
   */
  const handleUpdateValue = (_filter: keyof Omit<IFilterState, "keyword" | "withHistory">, value: any) => {
    tempFilter[_filter].value = value;
    setTempFilter({ ...tempFilter });
  };

  ///////////////////////////////////////////
  //
  //               Rendering
  //
  ///////////////////////////////////////////

  const rows: IFilterSelectRow[] = [
    {
      filter: "status",
      name: "Status",
      value: (
        <StatusFilter
          value={tempFilter.status}
          statusDescriptions={statusDescriptions}
          className={classes.filterValue}
          onChange={(status) => handleUpdateValue("status", status)}
        />
      ),
    },
    {
      filter: "date",
      name: "Datum",
      value: <DateFilter value={tempFilter.date} onChange={(date) => handleUpdateValue("date", date)} />,
    },
  ];

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle>Filter Optionen</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TableContainer>
          <Table sx={{ width: "100%" }} size={"small"}>
            <TableBody>
              {rows.map(({ filter, name, value }) => (
                <TableRow key={filter} sx={{ "& td, & th": { border: 0 }, margin: 0 }}>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tempFilter[filter].enabled}
                          onChange={(e) => handleUpdateEnabled(filter, e)}
                          className={classes.filterEnabled}
                        />
                      }
                      label={name}
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                      value={tempFilter[filter].comparator}
                      onChange={(e) => handleUpdateComparator(filter, e)}
                      disabled={!tempFilter[filter].enabled}
                      className={classes.filterComparator}
                      native
                    >
                      <option value={"="}>&#61;</option>
                      <option value={"<"}>&#60;</option>
                      <option value={">"}>&#62;</option>
                      <option value={"<="}>&#60;&#61;</option>
                      <option value={">="}>&#62;&#61;</option>
                    </Select>
                  </TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button color={"error"} onClick={handleUndoFilter}>
          Abbrechen
        </Button>
        <Button color={"success"} onClick={handleSaveFilter}>
          Filter Übernehmen
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default Searchbar;
