import {
  Box,
  Card,
  Chip,
  Grid,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "../components/Button";
import { IOffer, IOfferHistorySummary } from "../data/constants";
import useLoadMoreButtonRef from "../hooks/useLoadMoreButton";
import { useStoreDispatch, useStoreSelector } from "../hooks/useStore";
import { setWithHistory } from "../store/filterSlice";
import { fetchOffers, selectIsSearching, selectOffers, selectOffersMaxAmount } from "../store/offersSlice";
import { store } from "../store/store";
import useDeltaStyle from "../hooks/useDeltaStyle";

const OfferHistory = ({ enqueueCallback }: { enqueueCallback: (callback: () => any) => Promise<any> }) => {
  const dispatch = useStoreDispatch();
  const { getDeltaColor, getDeltaLabel } = useDeltaStyle();
  const offers = useStoreSelector(selectOffers);
  const loading = useStoreSelector(selectIsSearching);
  const maxAmountOffers = useStoreSelector(selectOffersMaxAmount);
  const [popupHistory, setPopupHistory] = useState<IOfferHistorySummary | null>(null);
  const [popupAnchor, setPopupAnchor] = useState<null | HTMLElement>(null);

  const loadMore = useCallback(() => {
    if (offers.length < maxAmountOffers) {
      enqueueCallback(() => dispatch(fetchOffers()));
    }
  }, [dispatch, offers.length, maxAmountOffers, enqueueCallback]);

  const loadMoreRef = useLoadMoreButtonRef(loadMore);

  const getTotalValue = useCallback((offer: IOffer) => {
    const adjustmentFactor = (100 + (offer.adjustment_percent ?? 0)) / 100;
    return offer.item_data.reduce((sum, { stamp_pr }) => sum + parseFloat(stamp_pr), 0) * adjustmentFactor;
  }, []);

  useEffect(() => {
    const previousWithHistory = store.getState().filter.withHistory;
    dispatch(setWithHistory(true));
    enqueueCallback(() => dispatch(fetchOffers(true)));

    return () => {
      dispatch(setWithHistory(previousWithHistory));
    };
  }, [dispatch, enqueueCallback]);

  const historyPopup = useMemo(
    () => (
      <Popover
        anchorEl={popupAnchor}
        open={!!popupHistory}
        onClose={() => setPopupHistory(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Artikelnummer</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Einzelpreis</TableCell>
                <TableCell>Anzahl</TableCell>
                <TableCell>Benutzer</TableCell>
                <TableCell>Kommentar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {popupHistory?.items.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    bgcolor: item.deleted ? "error.light" : item.created ? "success.light" : "warning.light",
                  }}
                >
                  <TableCell>
                    <img
                      height="120"
                      alt={item.matching_item_name}
                      src={`https://mario.konsolenkost-tools.de/product/image.php?size=md&id=${item.matching_item_id}`}
                    />
                  </TableCell>
                  <TableCell>{item.matching_item_id}</TableCell>
                  <TableCell>{item.matching_item_name}</TableCell>
                  <TableCell>
                    {item.price.toFixed(2)} €
                    <Chip
                      color={getDeltaColor(item.deltaPrice)}
                      sx={{ ml: 2 }}
                      label={`${getDeltaLabel(item.deltaPrice)} €`}
                    />
                  </TableCell>
                  <TableCell>
                    {item.quantity}
                    <Chip
                      color={getDeltaColor(item.deltaQuantity)}
                      sx={{ ml: 2 }}
                      label={getDeltaLabel(item.deltaQuantity, 0)}
                    />
                  </TableCell>
                  <TableCell>{item.user_name}</TableCell>
                  <TableCell>{item.comment}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Popover>
    ),
    [getDeltaColor, getDeltaLabel, popupAnchor, popupHistory]
  );

  return (
    <Box p={4}>
      {offers.map((offer) => (
        <Card sx={{ overflow: "visible", p: 2, mb: 2 }} key={offer.angebot_id}>
          <Grid container spacing={1}>
            <Grid item container spacing={1} xs="auto">
              <Grid item container xs={12}>
                <Grid item xs>
                  Angebot-Id: {offer.angebot_id}
                </Grid>
                <Grid item xs>
                  Name: {offer.name}
                </Grid>
                <Grid item xs>
                  E-mail: {offer.email}
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs>
                  Status-Id: {offer.status_id.toFixed(2)}
                </Grid>
                <Grid item xs>
                  Datum: {offer.first_status_date}
                </Grid>
                <Grid item xs></Grid>
              </Grid>
            </Grid>
            <Grid item xs sx={{ textAlign: "right" }}>
              {!!offer.adjustment_percent && (
                <Chip
                  label={
                    offer.adjustment_percent > 0
                      ? `＋ ${offer.adjustment_percent}% Aufschlag`
                      : `－ ${Math.abs(offer.adjustment_percent)}% Abschlag`
                  }
                  color={offer.adjustment_percent > 0 ? "success" : "error"}
                  sx={{
                    marginRight: "20px",
                    padding: 0,
                    fontWeight: "bold",
                  }}
                />
              )}
              Gesamtpreis: {getTotalValue(offer).toFixed(2)} €
            </Grid>
          </Grid>
          {!!offer.history?.length && <hr />}
          {!!offer.history?.length && (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Datum</TableCell>
                    <TableCell align="left">Nutzer</TableCell>
                    <TableCell align="right">Artikel</TableCell>
                    <TableCell align="left">Änderung</TableCell>
                    <TableCell align="right">Menge</TableCell>
                    <TableCell align="left">Änderung</TableCell>
                    <TableCell align="right">Preis</TableCell>
                    <TableCell align="left">Änderung</TableCell>
                    <TableCell align="right">Gesamt</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {offer.history.map((history, index) => (
                    <TableRow
                      sx={{ cursor: "pointer", opacity: history.users.length ? 1 : 0.5 }}
                      key={index}
                      hover
                      onClick={(e) => {
                        setPopupHistory(history);
                        setPopupAnchor(e.currentTarget);
                      }}
                    >
                      <TableCell align="left">{history.timestamp}</TableCell>
                      <TableCell align="left">{history.users.length ? history.users.join(', ') : 'System'}</TableCell>
                      <TableCell align="right">{history.total.items}</TableCell>
                      <TableCell align="left">
                        <Chip color={getDeltaColor(history.delta.items)} label={getDeltaLabel(history.delta.items)} />
                      </TableCell>
                      <TableCell align="right">{history.total.quantity}</TableCell>
                      <TableCell align="left">
                        <Chip
                          color={getDeltaColor(history.delta.quantity)}
                          label={getDeltaLabel(history.delta.quantity)}
                        />
                        {!!history.quantityChangedCount && (
                          <Chip label={`${history.quantityChangedCount} angepasst`} sx={{ ml: 2 }} />
                        )}
                      </TableCell>
                      <TableCell align="right">{history.total.price.toFixed(2)} €</TableCell>
                      <TableCell align="left">
                        <Chip color={getDeltaColor(history.delta.price)} label={`${getDeltaLabel(history.delta.price)} €`} />
                        {!!history.priceChangedCount && (
                          <Chip label={`${history.priceChangedCount} angepasst`} sx={{ ml: 2 }} />
                        )}
                      </TableCell>
                      <TableCell align="right">{`${history.total.price.toFixed(2)} €`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Card>
      ))}
      <Box sx={{ textAlign: "center" }} mt={2} ref={loadMoreRef}>
        <Button
          color={"primary"}
          onClick={() => enqueueCallback(() => dispatch(fetchOffers()))}
          disabled={offers.length === maxAmountOffers || loading}
        >
          Mehr laden
        </Button>
      </Box>
      {historyPopup}
    </Box>
  );
};

export default OfferHistory;
