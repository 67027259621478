import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { IFilterState } from "../../store/filterSlice";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const DateFilter = ({
  value,
  onChange,
  className,
}: {
  value: IFilterState["date"];
  onChange: (value: Date | null) => void;
  className?: string;
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        sx={{ width: "100%" }}
        format={"dd.MM.yyyy"}
        value={new Date(value.value)}
        onChange={(e: Date | null) => onChange(e)}
        disabled={!value.enabled}
      />
    </LocalizationProvider>
  );
};

export default DateFilter;