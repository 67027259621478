import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { Typography, Grid, TextField, Dialog, FormControl, InputLabel, Select, OutlinedInput, Chip, MenuItem, SelectChangeEvent } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { captureException, captureMessage } from "@sentry/react";
import { MdOutlineEmail, MdMoneyOff } from "react-icons/md";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineSafety, AiOutlineSend, AiOutlineWarning } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import Button from "../Button";
import { ArticleContext, CommentContext, OfferContext, TotalValuesContext } from "../../data/contexts";
import { ADJUSTMENT_USERS, MAIL_TYPE, REVIEW_PRICE_REVIEWERS, REVIEW_PRICE_THRESHOLD, parseReadableDate } from "../../data/constants";
import { useStoreDispatch, useStoreSelector } from "../../hooks/useStore";
import APIOffers from "../../helper/APIOffers";
import { closeDialog, openDialog } from "../../store/dialogSlice";
import { decreaseMaxAmountOffers, removeOffer, reviewOffer, setOfferAdjustment } from "../../store/offersSlice";
import { alert } from "../../store/alertSlice";
import { selectIsLoading } from "../../store/statusSlice";
import { selectUserId, selectUserName } from "../../store/userSlice";
import useLoadingQueue from "../../hooks/useLoadingQueue";

const PREFIX = "OfferHeader";

const classes = {
  mailContainer: `${PREFIX}-mailContainer`,
  mailContent: `${PREFIX}-mailContent`,
  adjustmentCommentContent: `${PREFIX}-adjustmentCommentContent`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.mailContainer}`]: {
    "& .MuiDialog-paper": {
      minHeight: "100px",
      maxHeight: "80vh",
      overflowY: "auto",
      padding: "20px",
    },
  },

  [`& .${classes.mailContent}`]: {
    margin: "10px 0",
    "& textarea": {
      width: "30vw !important",
      height: "300px !important",
    },
    "& fieldset": { border: "1px solid " + theme.palette.contrast.light },
  },
   [`& .${classes.adjustmentCommentContent}`]: {
    margin: "10px 0",
    "& textarea": {
      width: "30vw !important",
      height: "70px !important",
    },
    "& fieldset": { border: "1px solid " + theme.palette.contrast.light },
  },
}));

const OfferHeader = () => {
  const theme = useTheme();
  const dispatch = useStoreDispatch();
  const enqueueCallback = useLoadingQueue();
  const offerAPI = useMemo(() => new APIOffers(dispatch), [dispatch]);

  const loading = useStoreSelector(selectIsLoading);
  const userId = useStoreSelector(selectUserId);
  const userName = useStoreSelector(selectUserName);
  const offer = useContext(OfferContext);
  const { value: articles, changeValue: setArticles } = useContext(ArticleContext);
  const setComments = useContext(CommentContext).changeValue;
  const totalValues = useContext(TotalValuesContext).value;
  
  const [customMail, setCustomMail] = useState({
    open: false,
    content: "",
  });
  const [adjustmentComment, setAdjustmentComment] = useState({
    open: false,
    content: "",
    value: 0,
  });
  const [filteredConfirmations, setFilteredConfirmations] = useState(offer.confirm_users ?? []);
  const [previousOfferAttributes, setPreviousOfferAttributes] = useState<{
    adjustment_percent?: number;
    has_confirmed?: boolean;
  }>({
    adjustment_percent: offer.adjustment_percent,
    has_confirmed: false,
  });
  const [needsReview, setNeedsReview] = useState(
    offer.status_id === 1.6 &&
      (totalValues.unverifiedItems > 0 ||
        (totalValues.totalPrice >= REVIEW_PRICE_THRESHOLD &&
          filteredConfirmations?.some((user) => Number(user.user_id) === Number(userId))))
  );
  const [hasConfirmed, setHasConfirmed] = useState(false);

  const DISABLED = !(offer.status_id === 1.0 || offer.status_id === 1.6);
  const warning_price = totalValues.totalPrice >= 200 || totalValues.totalExchangeValue >= 200;

  useEffect(() => {
    if (previousOfferAttributes.adjustment_percent !== offer.adjustment_percent) {
      enqueueCallback(
        () => offerAPI.saveOfferAttributes(offer.angebot_id, {
          adjustment_percent: offer.adjustment_percent ?? 0,
        })
      );
      if (offer.adjustment_percent !== 0) {
        const adjustmentTitle =
          adjustmentComment.value > 0
            ? `+ ${adjustmentComment.value}% Aufschlag`
            : `- ${Math.abs(adjustmentComment.value)}% Abschlag`;
        enqueueCallback(() =>
          offerAPI.postComment(offer.angebot_id, `${adjustmentTitle}: ${offer.adjustment_comment}`).then((response) => {
            if (response.data.length !== 0) {
              setComments(response.data);
            } else {
              dispatch(alert({ severity: "error", message: "Fehler beim Speichern des Kommentars." }));
            }
          })
          .catch((error) => {
            dispatch(alert({ severity: "error", message: "Fehler beim Speichern des Kommentars." }));
          })
        );
      }
    }
    if (hasConfirmed && !previousOfferAttributes.has_confirmed) {
      enqueueCallback(async () => {
        try {
          await offerAPI.saveArticles(offer.angebot_id, articles, setArticles);
          await offerAPI.confirmReviewOffer(offer.angebot_id, totalValues.totalPrice);
        }catch (error) {
          dispatch(alert({ severity: "error", message: "Beim Speichern ist ein Fehler aufgetreten!" }));
          captureException(error);
        }
      });
    }

    if (
      previousOfferAttributes.adjustment_percent !== offer.adjustment_percent ||
      hasConfirmed !== previousOfferAttributes.has_confirmed
    ) {
      setPreviousOfferAttributes({
        adjustment_percent: offer.adjustment_percent,
        has_confirmed: hasConfirmed,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, offer, filteredConfirmations, previousOfferAttributes, totalValues, hasConfirmed, offerAPI, enqueueCallback]);

  useEffect(() => {
    setFilteredConfirmations(
      offer.confirm_users?.filter(
        (user) => Math.round(user.auszahlt_wert * 100) === Math.round(totalValues.totalPrice * 100)
      ) ?? []
    );
  }, [offer, totalValues]);

  useEffect(() => {
    setNeedsReview(
      offer.status_id === 1.6 &&
        (totalValues.unverifiedItems > 0 ||
          (totalValues.totalPrice >= REVIEW_PRICE_THRESHOLD &&
            filteredConfirmations?.some((user) => user.user_id === userId)))
    );
  }, [offer, totalValues, userId, filteredConfirmations]);

  ///////////////////////////////////////////
  //
  //              Mail Logic
  //
  ///////////////////////////////////////////

  /**
   * Changes the offer status, closing it, and sending an email to the customer depending on the passed mailType
   */
  const handleSendOffer = useCallback(
    (mailType: MAIL_TYPE) => {
      if (mailType === MAIL_TYPE.SEND_OFFER && totalValues.totalPrice === 0) return;

      let title = "";
      switch (mailType) {
        case MAIL_TYPE.TOO_LOW:
          title = "Willst du das Angebot wirklich als zu klein markieren?";
          break;
        case MAIL_TYPE.NOT_ACCEPTED:
          title = "Willst du das Angebot wirklich als unpassend markieren?";
          break;
        default:
          title = "Willst du das Angebot wirklich abschicken?";
      }

      const handleAgree = () => {
        if (
          totalValues.totalPrice >= REVIEW_PRICE_THRESHOLD &&
          !filteredConfirmations.some((user) => user.user_id === userId)
        ) {
          enqueueCallback(() => offerAPI.confirmReviewOffer(offer.angebot_id, totalValues.totalPrice));
        }
        enqueueCallback(() =>
          offerAPI
            .sendOffer(offer.angebot_id, mailType, articles, totalValues.totalPrice)
            .then((response) => {
              if (response.data.code === 1) {
                dispatch(removeOffer({ offerId: offer.angebot_id }));
                dispatch(
                  alert({ severity: "success", message: `Angebot gespeichert. Neuer Status: ${response.data.msg}.` })
                );
                dispatch(decreaseMaxAmountOffers());
              } else {
                dispatch(alert({ message: `Fehler beim Speichern des Angebotes.` }));
                captureMessage("Fehler beim Senden des Angebotes " + response);
              }
              dispatch(closeDialog());
            })
            .catch((error) => {
              dispatch(alert({ message: `Fehler beim Speichern des Angebotes.` }));
              dispatch(closeDialog());
              captureException(error);
            })
        );
      };

      const content =
        mailType === MAIL_TYPE.SEND_OFFER && warning_price
          ? "Das Angebot übersteigt 200€. Bitte das Angebot nochmal überprüfen!"
          : mailType === MAIL_TYPE.SEND_OFFER && totalValues.unverifiedItems
          ? "Das Angebot enthält manuell hinzugefügte Artikel oder veraltete Preise. Bitte das Angebot nochmal überprüfen!"
          : "";

      dispatch(
        openDialog({
          title,
          content,
          handleDisagree: () => dispatch(closeDialog()),
          handleAgree: () => handleAgree(),
        })
      );
    },
    [articles, dispatch, enqueueCallback, filteredConfirmations, offer, offerAPI, totalValues, userId, warning_price]
  );

  /**
   * Changes the offer with the passed id' status to closed, deleting it from all open offers
   */
  const handleCloseOffer = useCallback(() => {
    const handleAgree = () => {
      const newStatus = 7.0;

      enqueueCallback(() =>
        offerAPI
          .changeOfferStatus(offer.angebot_id, offer.status_id, newStatus)
          .then((response) => {
            if (response.data === newStatus) {
              dispatch(removeOffer({ offerId: offer.angebot_id }));
              dispatch(alert({ severity: "success", message: `Angebot gelöscht. Neuer Status: ${response.data.msg ?? response.data}.` }));
              dispatch(decreaseMaxAmountOffers());
            } else {
              dispatch(alert({ message: "Fehler beim Löschen des Angebotes." }));
              captureMessage("Fehler beim Löschen des Angebotes " + response);
            }
            dispatch(closeDialog());
          })
          .catch((error) => {
            dispatch(alert({ message: "Fehler beim Löschen des Angebotes." }));
            dispatch(closeDialog());
            captureException(error);
          })
      );
    };

    dispatch(
      openDialog({
        title: "Willst du das Angebot wirklich löschen?",
        handleDisagree: () => dispatch(closeDialog()),
        handleAgree: () => handleAgree(),
      })
    );
  }, [dispatch, enqueueCallback, offer, offerAPI]);

  /**
   * Sends mail of type mailType to customer of the passed offer
   */
  const handleSendEmail = useCallback(() => {
    enqueueCallback(() =>
      offerAPI
        .sendEmail(offer.angebot_id, MAIL_TYPE.CUSTOM_OFFER, customMail.content)
        .then((response) => {
          if (response.data.code === 1) {
            setComments(response.data.msg);
            dispatch(alert({ severity: "success", message: "E-mail wird gesendet. Neuer Status: 1.4." }));
          } else {
            dispatch(alert({ message: "Fehler beim Senden der E-mail." }));
            captureMessage("Fehler beim Senden der E-mail " + response);
          }
          dispatch(closeDialog());
        })
        .catch((error) => {
          dispatch(alert({ message: "Fehler beim Senden der E-mail." }));
          captureException(error);
        })
    );
  }, [customMail, dispatch, enqueueCallback, offer, offerAPI, setComments]);

  const handleRequestVerifyOffer = useCallback(() => {
    const handleAgree = () => {
      enqueueCallback(() =>
        offerAPI
          .requestVerifyOffer(offer.angebot_id, articles)
          .then((response) => {
            if (response.data.code === 1) {
              dispatch(removeOffer({ offerId: offer.angebot_id }));
              dispatch(
                alert({
                  severity: "success",
                  message: `Angebot zur Prüfung eingereicht. Neuer Status: ${response.data.msg}.`,
                })
              );
              dispatch(decreaseMaxAmountOffers());
            } else {
              dispatch(alert({ message: "Fehler beim Einreichen zur Prüfung." }));
              captureMessage("Fehler beim Einreichen zur Prüfung " + response);
            }
            dispatch(closeDialog());
          })
          .catch((error) => {
            dispatch(alert({ message: "Fehler beim Einreichen zur Prüfung." }));
            dispatch(closeDialog());
            captureException(error);
          })
      );
    }

    dispatch(
      openDialog({
        title: "Willst du das Angebot wirklich zur Überprüfung senden?",
        handleDisagree: () => dispatch(closeDialog()),
        handleAgree: () => handleAgree(),
      })
    );
  }, [articles, dispatch, enqueueCallback, offer, offerAPI]);

  const handleReviewOffer = useCallback(() => {
    setHasConfirmed(true);
    dispatch(reviewOffer({ offerId: offer.angebot_id, userId, userName, totalValue: totalValues.totalPrice }));
    dispatch(alert({ severity: "warning", message: `Angebot benötigt noch ${REVIEW_PRICE_REVIEWERS - (filteredConfirmations.length ?? 0) - 1} Freigaben.` }));
  }, [dispatch, filteredConfirmations, offer, totalValues, userId, userName]);

  const handleSetAdjustment = useCallback((e: SelectChangeEvent<number>) => {
    const value = parseFloat(e.target.value?.toString() ?? "0");
    if (value === 0) {
      enqueueCallback(() => dispatch(setOfferAdjustment({ offerId: offer.angebot_id, adjustment: 0, comment: "" })));
    } else {
      setAdjustmentComment((adjustmentComment) => ({ ...adjustmentComment, open: true, content: "", value }));
    }
  }, [dispatch, enqueueCallback, offer]);

  const closeAdjustmentComment = useCallback(() => {
    setAdjustmentComment((adjustmentComment) => ({ ...adjustmentComment, open: false }));
  }, [])

  ///////////////////////////////////////////
  //
  //               Rendering
  //
  ///////////////////////////////////////////

  const closeCustomMail = () => setCustomMail({ ...customMail, open: false });

  const MailDialog = (
    <StyledDialog open={customMail.open} onClose={closeCustomMail} className={classes.mailContainer}>
      <Typography variant={"h5"}>E-mail zum Kunden [{offer.angebot_id}]</Typography>
      <TextField
        className={classes.mailContent}
        variant={"outlined"}
        autoFocus
        multiline
        value={customMail.content}
        onChange={(e) => setCustomMail({ ...customMail, content: e.target.value })}
      />
      <Grid container mt={"auto"} spacing={1} style={{ justifyContent: "flex-end" }}>
        <Grid item>
          <Button onClick={closeCustomMail} color={"error"}>
            Abbrechen
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              handleSendEmail();
              closeCustomMail();
            }}
            disabled={loading}
            color={"success"}
          >
            Senden
          </Button>
        </Grid>
      </Grid>
    </StyledDialog>
  );

  const AdjustmentCommentDialog = (
    <StyledDialog open={adjustmentComment.open} onClose={closeAdjustmentComment} className={classes.mailContainer}>
      <Typography variant={"h5"}>
        Kommentar
        {adjustmentComment.value > 0
          ? ` ＋ ${adjustmentComment.value}% Aufschlag`
          : ` － ${Math.abs(adjustmentComment.value)}% Abschlag`}
      </Typography>
      <TextField
        className={classes.adjustmentCommentContent}
        variant={"outlined"}
        autoFocus
        multiline
        fullWidth
        value={adjustmentComment.content}
        onChange={(e) => setAdjustmentComment({ ...adjustmentComment, content: e.target.value })}
      />
      <Grid container mt={"auto"} spacing={1} style={{ justifyContent: "flex-end" }}>
        <Grid item>
          <Button onClick={closeAdjustmentComment} color={"error"}>
            Abbrechen
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              enqueueCallback(() =>
                dispatch(setOfferAdjustment({
                  offerId: offer.angebot_id,
                  adjustment: adjustmentComment.value,
                  comment: adjustmentComment.content,
                }))
              );
              closeAdjustmentComment();
            }}
            disabled={loading || !adjustmentComment.content}
            color={"success"}
          >
            Senden
          </Button>
        </Grid>
      </Grid>
    </StyledDialog>
  );

  return (
    <Grid item container spacing={1} xs="auto">
      <Grid container item xs={8} pb={2} spacing={2}>
        <Grid item style={{ paddingTop: "8px" }}>
          <Typography variant={"subtitle1"} style={{ fontWeight: "bold" }}>
            Angebot-Id: {offer.angebot_id}
          </Typography>
          <Typography variant={"subtitle1"} style={{ fontWeight: "bold" }}>
            Status-Id: {offer.status_id.toFixed(2)}
          </Typography>
          <Typography variant={"subtitle1"} style={{ fontWeight: "bold" }}>
            Datum: {parseReadableDate(offer.first_status_date)}
          </Typography>
        </Grid>
        <Grid item style={{ paddingTop: "8px" }}>
          <Typography variant={"subtitle1"} style={{ fontWeight: "bold" }}>
            Name: {offer.name}
          </Typography>
          <Typography variant={"subtitle1"} style={{ fontWeight: "bold" }}>
            E-mail: {offer.email}
          </Typography>
          <Typography variant={"subtitle1"} style={{ fontWeight: "bold" }}>
            Anzahl Bilder: {offer.upload_imgs?.length}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={4} pb={2} spacing={2}>
        <Grid item xs={12} style={{ paddingTop: "8px", display: "flex", justifyContent: "flex-end" }}>
          <Typography variant={"subtitle1"} style={{ fontWeight: "bold", float: "left", marginRight: 20 }}>
            Gesamtpreis:
            <i style={{ color: warning_price ? theme.palette.error.main : theme.palette.text.primary }}>
              {totalValues.totalPrice.toFixed(2) ?? 0.0}€
            </i>
          </Typography>
          <Typography variant={"subtitle1"} style={{ fontWeight: "bold", float: "left" }}>
            Tauschwert:
            <i style={{ color: warning_price ? theme.palette.error.main : theme.palette.text.primary }}>
              {totalValues.totalExchangeValue.toFixed(2) ?? 0.0}€
            </i>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0, display: "flex", justifyContent: "flex-end" }}>
          {offer.status_id === 1.6 && !!ADJUSTMENT_USERS[userId] ? (
            <FormControl sx={{ minWidth: 120, marginRight: "20px" }} size="small">
              <InputLabel id="offer-price-adjustment">Auf- / Abschlag</InputLabel>
              <Select
                autoWidth
                label="Auf- / Abschlag"
                labelId="offer-price-adjustment"
                input={<OutlinedInput label="Auf- / Abschlag" />}
                value={offer.adjustment_percent}
                onChange={handleSetAdjustment}
                sx={{ textAling: "center" }}
                renderValue={(value: number) => (
                  <Chip
                    size="small"
                    color={value === 0 ? undefined : value > 0 ? "success" : "error"}
                    label={value === 0 ? "Keiner" : `${value > 0 ? "＋" : "－"} ${Math.abs(value)}%`}
                  />
                )}
              >
                {ADJUSTMENT_USERS[userId]?.map((value) => (
                  <MenuItem key={value} value={value}>
                    <Chip
                      size="small"
                      color={value === 0 ? undefined : value > 0 ? "success" : "error"}
                      label={value === 0 ? "Keiner" : `${value > 0 ? "＋" : "－"} ${Math.abs(value)}%`}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            !!offer.adjustment_percent && (
              <Chip
                label={
                  offer.adjustment_percent > 0
                    ? `＋ ${offer.adjustment_percent}% Aufschlag`
                    : `－ ${Math.abs(offer.adjustment_percent)}% Abschlag`
                }
                color={offer.adjustment_percent > 0 ? "success" : "error"}
                sx={{
                  marginRight: "20px",
                  padding: 0,
                  fontWeight: "bold",
                }}
              />
            )
          )}
          {offer.status_id > 1.0 && totalValues.totalPrice >= REVIEW_PRICE_THRESHOLD && (
            <Chip
              label={`${filteredConfirmations.length ?? 0} / ${REVIEW_PRICE_REVIEWERS}`}
              title={filteredConfirmations.map(({ user_name, timestamp }) => `${user_name}: ${timestamp}`).join("\n")}
              icon={<AiOutlineSafety />}
              color={filteredConfirmations.length === REVIEW_PRICE_REVIEWERS ? "success" : "warning"}
              sx={{
                cursor: "pointer",
                marginRight: "20px",
                fontWeight: "bold",
              }}
            />
          )}
          <Button
            color={totalValues.unverifiedItems ? "warning" : "success"}
            style={{ height: "2rem", marginRight: "20px" }}
            disabled={totalValues.totalPrice === 0 || DISABLED || needsReview}
            onClick={() =>
              offer.status_id === 1.0
                ? handleRequestVerifyOffer()
                : totalValues.totalPrice >= REVIEW_PRICE_THRESHOLD &&
                  (filteredConfirmations.length ?? 0) < REVIEW_PRICE_REVIEWERS - 1
                ? handleReviewOffer()
                : handleSendOffer(MAIL_TYPE.SEND_OFFER)
            }
          >
            {offer.status_id === 1.0 ? (
              <>
                Angebot prüfen <AiOutlineWarning style={{ marginLeft: "5px", fontSize: "1rem" }} />
              </>
            ) : totalValues.totalPrice >= REVIEW_PRICE_THRESHOLD &&
              (filteredConfirmations.length ?? 0) < REVIEW_PRICE_REVIEWERS - 1 ? (
              <>
                Angebot freigeben <AiOutlineSafety style={{ marginLeft: "5px", fontSize: "1rem" }} />
              </>
            ) : (
              <>
                Angebot absenden <AiOutlineSend style={{ marginLeft: "5px", fontSize: "1rem" }} />
              </>
            )}
          </Button>
          <Button
            color={"success"}
            style={{ height: "2rem" }}
            disabled={DISABLED}
            onClick={() => setCustomMail({ ...customMail, open: true })}
          >
            <>
              E-mail <MdOutlineEmail style={{ marginLeft: "5px", fontSize: "1.25rem" }} />
            </>
          </Button>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "8px", display: "flex", justifyContent: "flex-end" }}>
          <Button
            color={"error"}
            style={{ height: "2rem", marginRight: "20px" }}
            disabled={DISABLED}
            onClick={() => handleSendOffer(MAIL_TYPE.TOO_LOW)}
          >
            <>
              zu klein <MdMoneyOff style={{ marginLeft: "5px", fontSize: "1.25rem" }} />
            </>
          </Button>
          <Button
            color={"error"}
            style={{ height: "2rem", marginRight: "20px" }}
            disabled={DISABLED}
            onClick={() => handleSendOffer(MAIL_TYPE.NOT_ACCEPTED)}
          >
            <>
              unpassend <ImCross style={{ marginLeft: "5px", fontSize: "0.75rem" }} />
            </>
          </Button>
          <Button color={"error"} style={{ height: "2rem" }} disabled={DISABLED} onClick={handleCloseOffer}>
            <>
              löschen <HiOutlineTrash style={{ marginLeft: "5px", fontSize: "1.25rem" }} />
            </>
          </Button>
        </Grid>
      </Grid>
      {MailDialog}
      {AdjustmentCommentDialog}
    </Grid>
  );
};

export default OfferHeader;
