import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store/store';

Sentry.init({
    dsn: "https://290308ef647e465f95dc47633064355e@o565792.ingest.sentry.io/6054352",
    integrations: [new Integrations.BrowserTracing()],
    environment: "production"
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <App tab="home" />
    </Provider>
)