import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  styled,
  Box,
  AppBar,
  Typography,
  CircularProgress,
  Input,
  InputAdornment,
  Chip,
  MenuItem,
  Select,
  Popover
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useStoreDispatch, useStoreSelector } from "../hooks/useStore";
import {
  fetchOffers,
  resetOfferIndex,
  selectCurrentOfferIndex,
  selectIsSearching,
  selectOffersAmount,
  selectOffersMaxAmount,
  selectStatusDescriptions,
} from "../store/offersSlice";
import { IFilterState, selectFilter, setFilter, setFilterKeyword, toggleFilter } from "../store/filterSlice";
import FilterSelect from "./FilterSelect";
import { FaFilter } from "react-icons/fa";
import Button from "./Button";
import StatusFilter from './Filter/StatusFilter';
import DateFilter from "./Filter/DateFilter";

const PREFIX = "StickyHeader";

const classes = {
  container: `${PREFIX}-container`,
  keywordInput: `${PREFIX}-keywordInput`,
};

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  [`&.${classes.container}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "5vh",
  },
  [`& .${classes.keywordInput}`]: {
    backgroundColor: theme.palette.background.default,
    "&&::before": {
      borderBottom: "1px solid " + theme.palette.text.primary,
    },
    "&& div svg": {
      cursor: 'url("' + window.location.href + 'block.png") 10 10, auto',
    },
  },
}));

interface IFilterSelect {
  filter: keyof Omit<IFilterState, "keyword" | "withHistory">;
  name: string;
  select: React.ReactChild;
}

const StickyHeader = ({
  showIndex,
  enqueueCallback,
}: {
  showIndex?: boolean;
  enqueueCallback: (callback: () => any) => Promise<any>;
}) => {
  const dispatch = useStoreDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const filter = useStoreSelector(selectFilter);
  const statusDescriptions = useStoreSelector(selectStatusDescriptions);
  const isSearching = useStoreSelector(selectIsSearching);
  const amountOffers = useStoreSelector(selectOffersAmount);
  const offerIndex = useStoreSelector(selectCurrentOfferIndex);
  const maxAmountOffers = useStoreSelector(selectOffersMaxAmount);
  const [keyword, setKeyword] = useState(new URLSearchParams(location.search).get("keyword") ?? "");
  const [filterDialog, setFilterDialog] = useState(false);
  const [filterPopoverAnchor, setFilterPopoverAnchor] = useState<null | HTMLElement>(null);
  const [filterPopover, setFilterPopover] = useState<false | React.ReactChild>(false);

  const filters: IFilterSelect[] = [
    {
      filter: "status",
      name: "Status",
      select: (
        <StatusFilter
          value={filter.status}
          statusDescriptions={statusDescriptions}
          open={true}
          onChange={(status) => {
            dispatch(setFilter({ ...filter, status: { ...filter.status, value: status } }));
            setFilterPopover(false);
            enqueueCallback(() => dispatch(fetchOffers()));
          }}
        />
      ),
    },
    {
      filter: "date",
      name: "Datum",
      select: (
        <DateFilter
          value={filter.date}
          onChange={(date) => {
            dispatch(setFilter({ ...filter, date: { ...filter.date, value: date } }));
            setFilterPopover(false);
            enqueueCallback(() => dispatch(fetchOffers()));
          }}
        />
      ),
    },
  ];

  const statusText = () => {
    if (amountOffers === 0) return "Keine Angebote gefunden.";
    if (!showIndex) return `${amountOffers} / ${maxAmountOffers} Angebote`;
    else
      return (
        <>
          <Select
            onChange={({ target }) => dispatch(resetOfferIndex((target.value as number) - 1))}
            value={offerIndex + 1}
            sx={{ mr: 1, mt: "-4px" }}
            SelectDisplayProps={{ style: { paddingTop: "4px", paddingBottom: "4px" } }}
          >
            {[...Array(maxAmountOffers)].map((_, i) => (
              <MenuItem key={i} value={i + 1}>
                {i + 1}
              </MenuItem>
            ))}
          </Select>
          <span>/ {maxAmountOffers} Angebote</span>
        </>
      );
  };

  const handleSearch = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== "Enter") return;
    dispatch(setFilterKeyword({ keyword }));
    dispatch(resetOfferIndex());
    enqueueCallback(() => dispatch(fetchOffers()));

    if (keyword === "") {
      navigate({ pathname: location.pathname, search: "" }, { replace: true });
    } else {
      navigate(
        { pathname: location.pathname, search: new URLSearchParams({ keyword: keyword }).toString() },
        { replace: true }
      );
    }
  };

  const formatDate = (date: string) => {
    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();

    return `${day}.${month}.${year}`;
  };

  useEffect(() => {
    dispatch(setFilterKeyword({ keyword }));
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <AppBarStyled
      position={"sticky"}
      sx={{ bgcolor: "background.default", flexDirection: "row", boxShadow: 0, paddingY: 2, paddingX: 4 }}
      className={classes.container}
    >
      <Box>
        <Input
          value={keyword}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          onKeyPress={(e) => handleSearch(e)}
          placeholder={"Artikel suchen"}
          className={classes.keywordInput}
          startAdornment={
            <InputAdornment position={"start"} sx={{ color: "text.primary" }}>
              <Search />
            </InputAdornment>
          }
        />
        <Button size={"small"} onClick={() => setFilterDialog(true)} sx={{ marginLeft: 1 }}>
          <>
            <FaFilter style={{ marginRight: "8px" }} /> Filter Bearbeiten
          </>
        </Button>
        <FilterSelect
          open={filterDialog}
          enqueueCallback={enqueueCallback}
          handleClose={() => setFilterDialog(false)}
        />
        {filters
          .filter(({ filter: id }) => filter[id].enabled)
          .map(({ filter: id, name, select }) => (
            <Chip
              key={id}
              label={`${name} ${filter[id].comparator} ${
                filter[id].value instanceof Date ? formatDate(filter[id].value) : filter[id].value.toString()
              }`}
              onDelete={() => {
                dispatch(toggleFilter(id));
                dispatch(resetOfferIndex());
                enqueueCallback(() => dispatch(fetchOffers()));
              }}
              onClick={(event) => {
                setFilterPopover(select);
                setFilterPopoverAnchor(event.currentTarget);
              }}
              sx={{ ml: 1 }}
            />
          ))}
        <Popover
          anchorEl={filterPopoverAnchor}
          open={!!filterPopover}
          onClose={() => setFilterPopover(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {filterPopover}
        </Popover>
      </Box>
      <Box>
        {isSearching ? (
          <CircularProgress size={25} />
        ) : (
          <Typography variant={"h6"} sx={{ color: "text.primary" }}>
            {statusText()}
          </Typography>
        )}
      </Box>
    </AppBarStyled>
  );
};

export default StickyHeader;
