import { useEffect } from 'react';
import { Alert, IconButton, Portal, Snackbar } from '@mui/material';
import { CheckCircleOutline, Close, ErrorOutline, InfoOutlined, SecurityUpdateWarningOutlined } from '@mui/icons-material';
import Slide, { SlideProps } from '@mui/material/Slide';
import { useStoreDispatch, useStoreSelector } from '../hooks/useStore';
import { selectAlert, stopAlert } from '../store/alertSlice';

type TransitionProps = Omit<SlideProps, 'direction' | 'timeout' | 'style'>;

const Toast = () => {
	const dispatch = useStoreDispatch();
	const alert = useStoreSelector(selectAlert);

	useEffect(() => {
		if (alert.open && alert.duration !== 0) {
			setTimeout(() => dispatch(stopAlert()), alert.duration);
		}
	}, [dispatch, alert.open, alert.duration]);

	const TransitionUp = (props: TransitionProps) => {
		return <Slide direction={'up'} style={{ transitionDelay: '300ms' }} {...props} />
	}

	return (
		<Portal>
			<Snackbar open={alert.open} onClose={() => dispatch(stopAlert())} TransitionComponent={TransitionUp}>
				<Alert
					variant={'filled'}
					severity={alert.severity ?? 'error'}
					iconMapping={{
						success: <CheckCircleOutline fontSize={'inherit'} />,
						info: <InfoOutlined fontSize={'inherit'} />,
						warning: <SecurityUpdateWarningOutlined fontSize={'inherit'} />,
						error: <ErrorOutline fontSize={'inherit'} />
					}}
					action={
						<IconButton
							aria-label={'close'}
							color={'inherit'}
							size={'small'}
							onClick={() => dispatch(stopAlert())}
						><Close fontSize={'inherit'} /></IconButton>
					}
				>{alert.message}</Alert>
			</Snackbar>
		</Portal>
	)
}

export default Toast;