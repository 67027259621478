import { useContext, useState } from 'react';
import { Card, Stack } from '@mui/material';
import { ArticleContext, CommentContext, OfferContext, ITotalValues, TotalValuesContext } from '../../data/contexts';
import { IOffer } from '../../data/constants';
import OfferHeader from './OfferHeader';
import OfferBody from './OfferBody';

const OfferContainer = () => {
    const offer = useContext(OfferContext);
    const [articles, setArticles] = useState(offer.item_data ?? []);
    const [comments, setComments] = useState(offer.comments);
    const [totalValues, setTotalValues] = useState<ITotalValues>({
      totalPrice: 0.0,
      totalQuantity: 0,
      totalExchangeValue: 0.0,
      unverifiedItems: 0,
    });

    return (
        <ArticleContext.Provider value={{ value: articles, changeValue: (_articles: IOffer['item_data']) => setArticles(_articles) }}>
            <CommentContext.Provider value={{ value: comments, changeValue: (_comments: IOffer['comments']) => setComments(_comments) }}>
                <TotalValuesContext.Provider value={{ value: totalValues, changeValue: (_totalValues: ITotalValues) => setTotalValues(_totalValues) }}>
                    <Card style={{ padding: 15, height: '100%', boxSizing: "border-box" }}>
                        <Stack direction="column" style={{ height: '100%' }}>
                            <OfferHeader />
                            <OfferBody />
                        </Stack>
                    </Card>
                </TotalValuesContext.Provider>
            </CommentContext.Provider>
        </ArticleContext.Provider>
    )
}

export default OfferContainer;