import { createContext } from 'react';
import { IOffer } from './constants';

export interface ITotalValues {
  totalPrice: number;
  totalQuantity: number;
  totalExchangeValue: number;
  unverifiedItems: number;
}

export interface ISearchValue {
  title: string;
  index?: number;
  original?: string;
  pinned?: boolean
}

export const OfferContext = createContext({} as IOffer);

export const ArticleContext = createContext({
    value: {} as IOffer['item_data'],
    changeValue: (_articles: IOffer['item_data']) => { }
});

export const CommentContext = createContext({
    value: {} as IOffer['comments'],
    changeValue: (_comments: IOffer['comments']) => { }
});

export const TotalValuesContext = createContext({
    value: {} as ITotalValues,
    changeValue: (_totalValues: ITotalValues) => { }
});

export const SearchContext = createContext({
  searchString: "",
  searchValueIndex: 1,
  searchValues: [] as ISearchValue[],
  currentSearchValue: { title: "" } as ISearchValue,
  setCurrentSearchValue: (_searchValue: ISearchValue) => {},
  setSearchValueIndex: (_searchValueIndex: number) => {},
  resetSearch(_searchValue: string, pinned?: boolean) {},
  setSearchValues: (_searchValues: ISearchValue[]) => {},
  addSearchValue(_searchValue: string, index?: number, pinned = false) {},
  clearSearchValues: () => {},
});