import {
  AppBar,
  Box,
  Checkbox,
  Chip,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ChangeEvent, Fragment, useCallback, useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IOfferPriceHistory, MARIO_URL } from "../data/constants";
import APIOffers from "../helper/APIOffers";
import useDeltaStyle from "../hooks/useDeltaStyle";

const PriceChanges = () => {
  const { getDeltaColor, getDeltaLabel } = useDeltaStyle();
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState<IOfferPriceHistory[]>([]);
  const [openRows, setOpenRows] = useState<Record<number, boolean>>({});
  const [orderBy, setOrderBy] = useState<keyof IOfferPriceHistory>("average_manual_delta");
  const [orderDir, setOrderDir] = useState<"asc" | "desc">("desc");
  const [from, setFrom] = useState<Date | null>(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  });
  const [to, setTo] = useState<Date | null>(null);
  const [includeAutoPrice, setIncludeAutoPrice] = useState(false);

  const sortHistory = useCallback(
    (history: IOfferPriceHistory[]) => {
      switch (orderBy) {
        case "manual_count":
        case "average_price":
        case "average_manual_price":
        case "average_delta":
        case "average_manual_delta":
          return history.sort((a, b) =>
            orderDir === "asc"
              ? a[orderBy] - b[orderBy]
              : b[orderBy] - a[orderBy]
          );
        case "last_update":
          return history.sort((a, b) =>
            orderDir === "asc"
              ? new Date(a[orderBy]).getTime() - new Date(b[orderBy]).getTime()
              : new Date(b[orderBy]).getTime() - new Date(a[orderBy]).getTime()
          );
        default:
          return history.sort((a, b) =>
            orderDir === "asc"
              ? a[orderBy] === b[orderBy]
                ? 1
                : a[orderBy] < b[orderBy]
                ? -1
                : 1
              : a[orderBy] === b[orderBy]
              ? -1
              : a[orderBy] < b[orderBy]
              ? 1
              : -1
          );
      }
    },
    [orderBy, orderDir]
  );

  const setDateRange = useCallback((days: number) => {
    const date = new Date();
    date.setDate(date.getDate() - days);

    setFrom(date);
    setTo(new Date());
  }, []);

  const reload = useCallback(() => {
    const offerApi = new APIOffers();
    setLoading(true);

    offerApi
      .getOfferPriceHistory(from, to, includeAutoPrice)
      .then((result) => {
        setHistory(sortHistory(result.data));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [from, to, includeAutoPrice, sortHistory]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reload(), [from, to, includeAutoPrice]);

  useEffect(() => {
    if (loading) return;
    setHistory((history) => sortHistory([...history]));
  }, [loading, sortHistory]);

  return (
    <Box>
      <AppBar
        position={"sticky"}
        sx={{ bgcolor: "background.default", flexDirection: "row", boxShadow: 0, paddingY: 2, paddingX: 4 }}
      >
        <Toolbar disableGutters sx={{ flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl>
              <DatePicker
                value={from}
                onChange={(newDate) => setFrom(newDate)}
                slotProps={{ textField: { size: "small" } }}
                disabled={loading}
              />
            </FormControl>
            <FormLabel sx={{ mx: 2, my: 2 }}>-</FormLabel>
            <FormControl>
              <DatePicker
                value={to}
                onChange={(newDate) => setTo(newDate)}
                slotProps={{ textField: { size: "small" } }}
                disabled={loading}
              />
            </FormControl>
          </LocalizationProvider>
          <Chip label="1 Woche" sx={{ ml: 2 }} onClick={() => !loading && setDateRange(7)} />
          <Chip label="2 Wochen" sx={{ ml: 2 }} onClick={() => !loading && setDateRange(14)} />
          <Chip label="1 Monat" sx={{ ml: 2 }} onClick={() => !loading && setDateRange(30)} />
          <Chip label="3 Monate" sx={{ ml: 2 }} onClick={() => !loading && setDateRange(90)} />
          <Chip label="6 Monate" sx={{ ml: 2 }} onClick={() => !loading && setDateRange(180)} />
          <Chip label="1 Jahr" sx={{ ml: 2 }} onClick={() => !loading && setDateRange(365)} />
          <FormControlLabel
            control={
              <Checkbox
                checked={includeAutoPrice}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.checked && orderBy === "average_manual_delta") {
                    setOrderBy("average_delta");
                  } else if (!e.target.checked && orderBy === "average_delta") {
                    setOrderBy("average_manual_delta");
                  }
                  setIncludeAutoPrice(e.target.checked)
                }}
                disabled={loading}
              />
            }
            label="Preisprüfung einbeziehen"
            sx={{ ml: 2, color: (theme) => theme.palette.text.primary }}
          />
        </Toolbar>
      </AppBar>
      <Container maxWidth="xl">
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell>Artikel&nbsp;ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="right" sortDirection={orderBy === "average_price" ? orderDir : false}>
                  <TableSortLabel
                    active={orderBy === "average_price"}
                    direction={orderBy === "average_price" ? orderDir : "desc"}
                    onClick={() => {
                      setOrderBy("average_price");
                      setOrderDir(orderBy === "average_price" && orderDir === "desc" ? "asc" : "desc");
                    }}
                  >
                    ∅&nbsp;Preis
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right" sortDirection={orderBy === "average_manual_delta" ? orderDir : false}>
                  <TableSortLabel
                    active={orderBy === (includeAutoPrice ? "average_delta" : "average_manual_delta")}
                    direction={
                      orderBy === (includeAutoPrice ? "average_delta" : "average_manual_delta") ? orderDir : "desc"
                    }
                    onClick={() => {
                      setOrderBy(includeAutoPrice ? "average_delta" : "average_manual_delta");
                      setOrderDir(
                        orderBy === (includeAutoPrice ? "average_delta" : "average_manual_delta") && orderDir === "desc"
                          ? "asc"
                          : "desc"
                      );
                    }}
                  >
                    ∅&nbsp;Anpassung
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right" sortDirection={orderBy === "manual_count" ? orderDir : false}>
                  <TableSortLabel
                    active={orderBy === "manual_count"}
                    direction={orderBy === "manual_count" ? orderDir : "desc"}
                    onClick={() => {
                      setOrderBy("manual_count");
                      setOrderDir(orderBy === "manual_count" && orderDir === "desc" ? "asc" : "desc");
                    }}
                  >
                    #&nbsp;Einträge
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right" sortDirection={orderBy === "last_update" ? orderDir : false}>
                  <TableSortLabel
                    active={orderBy === "last_update"}
                    direction={orderBy === "last_update" ? orderDir : "desc"}
                    onClick={() => {
                      setOrderBy("last_update");
                      setOrderDir(orderBy === "last_update" && orderDir === "desc" ? "asc" : "desc");
                    }}
                  >
                    Letztes Update
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((history, index) => (
                <Fragment key={index}>
                  <TableRow sx={{ cursor: "pointer" }} key={index} hover>
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpenRows({ ...openRows, [index]: !openRows[index] })}
                      >
                        {openRows[index] ? <FaChevronUp /> : <FaChevronDown />}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <img
                        alt={history.matching_item_name}
                        src={`${MARIO_URL}/product/image.php?size=md&id=${history.matching_item_id}`}
                        style={{ maxWidth: "120px" }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {history.matching_item_id}
                    </TableCell>
                    <TableCell>{history.matching_item_id ? history.matching_item_name : "Manueller Artikel"}</TableCell>
                    <TableCell align="right">∅&nbsp;{history.average_price.toFixed(2)}&nbsp;€</TableCell>
                    <TableCell align="right">
                      <Chip
                        color={getDeltaColor(includeAutoPrice ? history.average_delta : history.average_manual_delta)}
                        sx={{ ml: 2 }}
                        label={`∅ ${getDeltaLabel(
                          includeAutoPrice ? history.average_delta : history.average_manual_delta
                        )} €`}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {history.history.length}&nbsp;{history.history.length === 1 ? "Eintrag" : "Einträge"} (
                      {history.manual_count}&nbsp;manuelle)
                    </TableCell>
                    <TableCell align="right">{history.last_update}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                      <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Historie
                          </Typography>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                <TableCell>Datum</TableCell>
                                <TableCell>Angebot</TableCell>
                                <TableCell>Preis</TableCell>
                                <TableCell>Anpassung</TableCell>
                                <TableCell>Nutzer</TableCell>
                                <TableCell>Kommentar</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {history.history?.map((historyRow) => (
                                <TableRow
                                  key={historyRow.timestamp}
                                  hover
                                  sx={{ opacity: historyRow.comment ? 1 : 0.5 }}
                                >
                                  <TableCell component="th" scope="row">
                                    {historyRow.timestamp}
                                  </TableCell>
                                  <TableCell>{historyRow.angebot_id}</TableCell>
                                  <TableCell>{historyRow.value.toFixed(2)} €</TableCell>
                                  <TableCell>
                                    <Chip
                                      color={getDeltaColor(historyRow.delta)}
                                      sx={{ ml: 2 }}
                                      label={`${getDeltaLabel(historyRow.delta)} €`}
                                    />
                                  </TableCell>
                                  <TableCell>{historyRow.user_name}</TableCell>
                                  <TableCell>{historyRow.comment ?? "Preisprüfung"}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default PriceChanges;
