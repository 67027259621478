import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import alertReducer from './alertSlice';
import filterReducer from './filterSlice';
import offersReducer from './offersSlice';
import dialogReducer from './dialogSlice';
import themeReducer from './themeSlice';
import statusReducer from "./statusSlice";
import templatesReducer from "./templatesSlice";

/**
 * This is the global redux store for managing states.
 * To add a new global state add its reducer to the store configuration
 * and create a state, reducer and action for it in the corresponding slice file.
 */

export const store = configureStore({
  reducer: {
    user: userReducer,
    alert: alertReducer,
    filter: filterReducer,
    offers: offersReducer,
    dialog: dialogReducer,
    theme: themeReducer,
    status: statusReducer,
    templates: templatesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;