import { Dialog as MUIDialog, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from './Button';
import { useStoreDispatch, useStoreSelector } from '../hooks/useStore';
import { closeDialog, selectDialog } from '../store/dialogSlice';

const PREFIX = 'ConfirmDialog';

const classes = {
    container: `${PREFIX}-container`
};

const StyledMUIDialog = styled(MUIDialog)({
    [`&.${classes.container}`]: {
        '& .MuiDialog-paper': {
            maxWidth: '50vw',
            minHeight: '100px',
            maxHeight: '80vh',
            overflowY: 'auto',
            padding: '20px'
        }
    }
});

const ConfirmDialog = () => {

    const dialog = useStoreSelector(selectDialog);
    const dispatch = useStoreDispatch();

    return (
        <StyledMUIDialog open={dialog.open} onClose={() => dispatch(closeDialog())} className={classes.container}>
            {dialog.title && <Typography variant={'h5'}>{dialog.title}</Typography>}
            {dialog.content && <Typography>{dialog.content}</Typography>}
            {dialog.actions &&
                <Grid container mt={'auto'} spacing={1} style={{ justifyContent: 'flex-end' }}>
                    {dialog.actions.map((action, index) => (
                        <Grid item key={index}>
                            <Button color={action.type} onClick={action.onPress}>
                                {action.label}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            }
        </StyledMUIDialog>
    );
}

export default ConfirmDialog;