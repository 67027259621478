import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { setupGoogleApi } from '@konsolenkost-de/react-image-mousetools';
import { Box, useMediaQuery } from '@mui/material';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import StickyHeader from './components/StickyHeader';
import NavBar from './components/NavBar';
import ConfirmDialog from './components/ConfirmDialog';
import Toast from './components/Toast';
import { useStoreDispatch, useStoreSelector } from './hooks/useStore';
import { MARIO_URL, VIEW_HISTORY_USERS } from './data/constants';
import ThemeProvider from './data/theme';
import { fetchWordfilter, fetchStatusDescriptions } from './store/offersSlice';
import { attemptLogin, selectUser } from './store/userSlice';
import { fetchTheme } from './store/themeSlice';
import './App.css';
import '@konsolenkost-de/react-image-mousetools/dist/index.css';
import OfferHistory from './pages/OfferHistory';
import PriceChanges from './pages/PriceChanges';
import useLoadingQueue from './hooks/useLoadingQueue';

const DEBUG = false;

function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const dispatch = useStoreDispatch();
    const enqueueCallback = useLoadingQueue();
    const user = useStoreSelector(selectUser);

    /**
     * Checks if user has login permissions and if so sets his login data, sets up google cloud vision and retrieves other required data for the app
     */
    useEffect(() => {
        if(DEBUG) {
            dispatch(fetchWordfilter());
            dispatch(fetchStatusDescriptions());
        }

        Promise.all([
            dispatch(fetchTheme({ prefersDarkMode })),
            dispatch(attemptLogin())
                .unwrap()
                .then(() => {
                    setupGoogleApi(`${MARIO_URL}/ankauf-4.0/php/cloudVisionApiKey.php`);
                    dispatch(fetchWordfilter());
                    dispatch(fetchStatusDescriptions());
                })
                .catch(() => { })
        ])

        // eslint-disable-next-line
    }, [dispatch]);
    
    return (
      <BrowserRouter>
        {(DEBUG || (user.id !== 0 && user.name !== "" && user.loginString !== "")) && (
          <Box pb={1} sx={{ bgcolor: "background.default" }} width={"100%"} height={"100%"}>
            <NavBar />

            {/* ---- Body Container ---- */}
            <Box>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <StickyHeader showIndex enqueueCallback={enqueueCallback} />
                      <Home enqueueCallback={enqueueCallback}/>
                    </>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <>
                      <StickyHeader enqueueCallback={enqueueCallback} />
                      <Dashboard enqueueCallback={enqueueCallback}/>
                    </>
                  }
                />
                {VIEW_HISTORY_USERS.includes(parseInt(user.id.toString())) && [
                  <Route
                    path="/offer-history"
                    element={
                      <>
                        <StickyHeader enqueueCallback={enqueueCallback} />
                        <OfferHistory enqueueCallback={enqueueCallback}/>
                      </>
                    }
                  />,
                  <Route path="/price-changes" element={<PriceChanges />} />,
                ]}
              </Routes>
            </Box>

            <Toast />
            <ConfirmDialog />
          </Box>
        )}
      </BrowserRouter>
    );
}

const AppWrapper = () => {
    return (
        <ThemeProvider>
            <App />
        </ThemeProvider>
    )
}

export default AppWrapper;
