import React, { useState, useEffect, useContext, useCallback } from "react";
import { styled } from "@mui/material/styles";
import {
  FaMinusCircle,
  FaBold,
  FaShoppingCart,
  FaCubes,
  FaEuroSign,
  FaExternalLinkAlt,
  FaInfoCircle,
  FaSnowflake,
  FaAmazon,
  FaEdit,
  FaUndo,
} from "react-icons/fa";
import {
  alpha,
  Card,
  CardMedia,
  Box,
  TextField,
  Avatar,
  Typography,
  Grid,
  Chip,
  Link,
  Input,
  InputAdornment,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Stack,
  Autocomplete,
  IconButton,
  Menu,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { captureException } from "@sentry/react";
import Popper from "../Popper";
import { Accessory, Console, Game } from "../SvgIcons";
import { useStoreDispatch, useStoreSelector } from "../../hooks/useStore";
import { selectTheme } from "../../store/themeSlice";
import {
  MARIO_URL,
  ARTICLE_CATEGORY,
  ISearchResult,
  ISearchItem,
  SEARCH_DELAY,
  SEARCH_PREVIEW_ROWS,
  SEARCH_ROWS,
  SEARCH_THROTTLE,
} from "../../data/constants";
import { SearchContext } from "../../data/contexts";
import APISearch from "../../helper/APISearch";
import useArticleHelpers from "../../hooks/useArticleHelpers";
import { debounce, throttle } from "throttle-debounce";
import APIOffers from "../../helper/APIOffers";
import { EWordfilterBlocked } from "../../store/offersSlice";
import { alert } from "../../store/alertSlice";

const PREFIX = "OfferSearchBody";

const classes = {
  searchInput: `${PREFIX}-searchInput`,
  categoryContainer: `${PREFIX}-categoryContainer`,
  searchContent: `${PREFIX}-searchContent`,
  customPriceInput: `${PREFIX}-customPriceInput`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Stack)(({ theme }) => ({
  [`& .${classes.searchInput}`]: {
    flexGrow: 1,
    "& .MuiFormControl-root": {
      margin: 0,
    },
    "& fieldset": { borderColor: theme.palette.contrast.light },
  },

  [`& .${classes.categoryContainer}`]: {
    "& button": { border: "1px solid " + theme.palette.contrast.light },
    "& .Mui-selected": { backgroundColor: alpha(theme.palette.contrast.light, 0.5) },
    "& button:hover, & .Mui-selected:hover": { backgroundColor: alpha(theme.palette.contrast.light, 0.3) },
  },

  [`& .${classes.searchContent}`]: {
    borderColor: theme.palette.contrast.light,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      boxShadow: "inset 0 0 15px #222",
      borderRadius: "10px",
    },
  },

  [`& .${classes.customPriceInput}`]: {
    width: "60px",
    padding: 0,
    textAlign: "right",
    fontSize: 12,
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      webkitAppearance: "none",
      margin: 0,
    },
    "& input": {
      mozAppearance: "textfield",
    },
  },
}));

interface IProps {
  handleAddArticle: (item: ISearchItem, edit?: boolean) => void;
  disabled?: boolean;
}

interface ISearchValue {
  title: string;
  index?: number;
  original?: string
}

const OfferSearchBody = ({ handleAddArticle, disabled }: IProps) => {
  const theme = useTheme();
  const dispatch = useStoreDispatch();
  const mode = useStoreSelector(selectTheme);
  const {
    searchString,
    currentSearchValue,
    searchValueIndex,
    setCurrentSearchValue,
    setSearchValueIndex,
    searchValues,
    setSearchValues,
    addSearchValue,
  } = useContext(SearchContext);

  const [filterCategory, setFilterCategory] = useState(0);
  const [basicFilter, setBasicFilter] = useState(0);
  const [searchResult, setSearchResult] = useState<ISearchResult>({
    docs: [],
    maxScore: 0,
    numFound: 0,
    start: 0,
  });

  const [popper, setPopper] = useState<{ type: "" | "image" | "price" | "history"; anchorEl: any; item: ISearchItem }>({
    type: "",
    anchorEl: null,
    item: {} as ISearchItem,
  });

  const [searchContextMenu, setSearchContextMenu] = useState<{ open: boolean, anchorEl: any; item: ISearchValue }>({
    open: false,
    anchorEl: null,
    item: {} as ISearchValue,
  })

  ///////////////////////////////////////////
  //
  //           Helper Functions
  //
  ///////////////////////////////////////////

  const { getArticleName, getArticleCondition, getArticleLanguage, getArticleConditionColor } =
    useArticleHelpers();

  ///////////////////////////////////////////
  //
  //             Handle Input
  //
  ///////////////////////////////////////////

  /**
   * Adds the events innertext to the search string
   */
  const handleAddSearch = (e: React.MouseEvent<HTMLSpanElement>) => {
    const target = e.target as HTMLSpanElement;
    addSearchValue(target.innerText.replace("+", ""));
  };

  /**
   * Empties the search string, the filters and the results
   */
  const handleRemoveSearch = () => {
    setFilterCategory(0);
    setSearchResult({ docs: [], maxScore: 0, numFound: 0, start: 0 });
  };

  const handleSetBasicFilter = () => {
    setBasicFilter(basicFilter === 0 ? 1 : 0);
  };

  /**
   * Sets the category filter to the chosen one
   * @returns if 'remove category' was chosen
   */
  const handleSetFilterCategory = (value: number) => {
    if (value === -1) return;
    setFilterCategory(value ?? 0);
  };

  /**
   * Searches for articles using the searchstring
   * @returns returns if the search string is empty
   */
  //eslint-disable-next-line
  const startSearch = useCallback(
    throttle(SEARCH_THROTTLE, (customSearch: string | null, rows = SEARCH_ROWS) => {
      if (!customSearch) return;

      const _searchString = customSearch.replace(/\bor\b/i, "").replace(/\s+/g, " ");

      new APISearch()
        .searchArticles(_searchString, filterCategory, rows)
        .then((response) => {
          setSearchResult({
            ...response.data.response,
            docs: response.data.response.docs.map((docs: any) => {
              return {
                ...docs,
                julian_price_original: docs.julian_price,
                julian_price_temp: docs.julian_price + "",
              };
            }),
          });
        })
        .catch((error) => {
          captureException(error);
        });
    }),
    [filterCategory]
  );

  // eslint-disable-next-line
  const debouncedSearch = useCallback(debounce(SEARCH_DELAY, startSearch), [startSearch]);

  useEffect(() => {
    if (searchString === "") {
      handleRemoveSearch();
    } else {
      startSearch(searchString, SEARCH_PREVIEW_ROWS);
      debouncedSearch(searchString, SEARCH_ROWS);
    }

    // eslint-disable-next-line
  }, [searchString, filterCategory, basicFilter]);

  /**
   * Changes the temporary julian_price for the chosen item in the solr search items
   */
  const handleChangeItemPurchasePrice = (e: React.ChangeEvent<HTMLInputElement>, item: ISearchItem) => {
    item.julian_price = parseFloat(e.target.value);
    setSearchResult({ ...searchResult });
  };

  const handleAddWordListRule = (item: ISearchValue) => {
    if (!item.original) return;

    new APIOffers(dispatch)
      .saveWordListEntry(item.original, EWordfilterBlocked.REPLACE, [item.title])
      .then((response) => {
        if (response.data.success) {
          dispatch(alert({ severity: "success", message: "Regel gespeichert." }));
        } else {
          dispatch(alert({ severity: "error", message: "Fehler beim Speichern der Regel." }));
        }
      })
      .catch((error) => {
        dispatch(alert({ severity: "error", message: "Fehler beim Speichern der Regel." }));
        captureException(error);
      });
  }

  const handleDeleteWordListRule = (item: ISearchValue) => {
    if (!item.original) return;

    new APIOffers(dispatch)
      .deleteWordListEntry(item.original, EWordfilterBlocked.REPLACE)
      .then((response) => {
        if (response.data.success) {
          dispatch(alert({ severity: "success", message: "Regel gelöscht." }));
        } else {
          dispatch(alert({ severity: "error", message: "Fehler beim Löschen der Regel." }));
        }
      })
      .catch((error) => {
        dispatch(alert({ severity: "error", message: "Fehler beim Löschen der Regel." }));
        captureException(error);
      });
  }

  ///////////////////////////////////////////
  //
  //              Rendering
  //
  ///////////////////////////////////////////

  const TagWordListPopper = (
    <Menu
      open={searchContextMenu.open}
      anchorEl={searchContextMenu.anchorEl}
      onClose={() => setSearchContextMenu({ ...searchContextMenu, open: false })}
    >
      <MenuItem disabled>
        <ListItemText>
          <Typography variant={"caption"} color="error" sx={{ textDecoration: "line-through", mr: 1 }}>
            {searchContextMenu.item.original}
          </Typography>
          <Typography variant={"caption"} color="success">
            {searchContextMenu.item.title}
          </Typography>
        </ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        handleAddWordListRule(searchContextMenu.item);
        setSearchContextMenu({ ...searchContextMenu, open: false });
      }}>
        <ListItemText>Regel speichern</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        handleDeleteWordListRule(searchContextMenu.item);
        setSearchContextMenu({ ...searchContextMenu, open: false });
      }}>
        <ListItemText>Regel löschen</ListItemText>
      </MenuItem>
    </Menu>
  );

  const ImagePopper = (
    <Popper open={popper.type === "image"} anchorEl={popper.anchorEl} placement={"left"}>
      <Grid style={{ maxWidth: "200px", padding: "5px" }}>
        <Typography style={{ textAlign: "center", wordWrap: "normal", maxWidth: "100%" }}>
          {getArticleName(popper.item.name ?? "")}
        </Typography>
        <img
          src={
            popper.item.article_id
              ? `${MARIO_URL}/product/image.php?size=md&id=${popper.item.article_id}`
              : process.env.PUBLIC_URL + "/img/keinBild.png"
          }
          alt={getArticleName(popper.item.name ?? "")}
          style={{ width: "100%", height: "auto" }}
        />
      </Grid>
    </Popper>
  );

  const PricePopper = (
    <Popper
      open={popper.type === "price"}
      anchorEl={popper.anchorEl}
      placement={"top"}
      children={
        <>
          <Box px={1} py={0.5} style={{ backgroundColor: "#69b1ff" }}>
            <Typography
              variant={"subtitle1"}
              style={{ padding: 8, marginBottom: 0, fontWeight: "bold", color: "#000" }}
            >
              Einkaufspreis-Berechnung
            </Typography>
          </Box>
          <Divider />
          <Box p={1}>
            {popper.item.julian_price_source &&
              JSON.parse(popper.item?.julian_price_source)?.map((source: any, index: number) => (
                <Box key={index}>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                      {source.source === "price9" ? "Min.Preis" : source.source}:{" "}
                    </Typography>
                    <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                      {source.price} €
                    </Typography>
                  </Box>
                  {source.amazon && (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                        Amazon {source.storehouse === 1 ? "UK" : source.storehouse === 2 ? "DE" : ""}:{" "}
                      </Typography>
                      <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                        {source.amazon} €
                      </Typography>
                    </Box>
                  )}
                  {source.asin && (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                        Asin:{" "}
                      </Typography>
                      <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                        {source.asin}
                      </Typography>
                    </Box>
                  )}
                  {source.crawler_timestamp && (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                        Zeit:{" "}
                      </Typography>
                      <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                        {source.crawler_timestamp}
                      </Typography>
                    </Box>
                  )}
                  {source.condition_adjustment && (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                        Zustand-Aufschlag:{" "}
                      </Typography>
                      <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                        {source.condition_adjustment}
                      </Typography>
                    </Box>
                  )}
                  {source.condition && (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                        Zustand:{" "}
                      </Typography>
                      <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                        {source.condition}
                      </Typography>
                    </Box>
                  )}
                  {source.notes && (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                        Notiz:{" "}
                      </Typography>
                      <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                        {source.notes}
                      </Typography>
                    </Box>
                  )}
                  {source.is_junk && (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                        Type:{" "}
                      </Typography>
                      <Typography variant={"body2"} style={{ padding: "4px 8px" }}>
                        Schrott-Artikel <FaMinusCircle style={{ color: "red" }} />
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
          </Box>
        </>
      }
    />
  );

  const HistoryPopper = (
    <Popper
      open={popper.type === "history"}
      anchorEl={popper.anchorEl}
      placement={"bottom"}
      children={
        <Grid>
          <Box px={1} py={0.5} style={{ backgroundColor: "#69b1ff" }}>
            <Typography
              variant={"subtitle1"}
              style={{ padding: 8, marginBottom: 0, fontWeight: "bold", color: "#000" }}
            >
              Bedarf und Verkäufe
            </Typography>
          </Box>
          <Divider />
          <Table size="small">
            <TableHead style={{ fontWeight: "bold" }}>
              <TableRow>
                <TableCell>Tage</TableCell>
                <TableCell>Bedarf</TableCell>
                <TableCell>Verkäufe</TableCell>
                <TableCell>Preis</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>30</TableCell>
                <TableCell>{popper.item.bedarf30}</TableCell>
                <TableCell>{popper.item.sales30}</TableCell>
                <TableCell>{popper.item.vk30}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>90</TableCell>
                <TableCell>{popper.item.bedarf90}</TableCell>
                <TableCell>{popper.item.sales90}</TableCell>
                <TableCell>{popper.item.vk90}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>180</TableCell>
                <TableCell>{popper.item.bedarf180}</TableCell>
                <TableCell>{popper.item.sales180}</TableCell>
                <TableCell>{popper.item.vk180}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>365</TableCell>
                <TableCell>{popper.item.bedarf365}</TableCell>
                <TableCell>{popper.item.sales}</TableCell>
                <TableCell>{popper.item.vk365}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontSize: "25px", fontWeight: "bold" }}>&#8734;</TableCell>
                <TableCell></TableCell>
                <TableCell>{popper.item.sales999}</TableCell>
                <TableCell>{popper.item.vk999}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      }
    />
  );

  return (
    <Root direction="column" style={{ flex: "1 0 0" }}>
      {/* ---- Searchbar ---- */}
      <Box display={"flex"} style={{ marginTop: "10px" }}>
        <Autocomplete
          multiple
          freeSolo
          size="small"
          className={classes.searchInput}
          options={[]}
          value={searchValues}
          sx={{ margin: 0 }}
          inputValue={currentSearchValue.title}
          getOptionLabel={(option) => (typeof option == "string" ? option : option.title)}
          renderTags={(tags, getTagProps) => {
            return tags.map((tag, index) => (
              <Chip
                {...getTagProps({ index })}
                color={tag.pinned ? "primary" : undefined}
                size="small"
                label={tag.title}
                title={tag.original}
                sx={{ order: index >= searchValueIndex ? 1 : 0 }}
                onClick={() => {
                  setSearchValueIndex(index);
                  setCurrentSearchValue({ ...tag, original: tag.original ?? tag.title, index });
                  setSearchValues(searchValues.filter((value) => value !== tag));
                }}
                onContextMenu={ tag.original ? (e) => {
                  e.preventDefault();
                  setSearchContextMenu({ open: true, anchorEl: e.currentTarget, item: tag });
                } : undefined}
                onDelete={() => setSearchValues(searchValues.filter((value) => value !== tag))}
                icon={
                  tag.original && tag.title !== tag.original ? (
                    <IconButton
                      size="small"
                      sx={{ marginRight: "-8px !important", fontSize: "12px !important" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSearchValues(
                          searchValues.map((value) => {
                            if (value !== tag) return value;
                            const title = value.original!;
                            delete value.original;
                            return { ...value, title };
                          })
                        );
                      }}
                    >
                      <FaUndo />
                    </IconButton>
                  ) : undefined
                }
              />
            ));}
          }
          onInputChange={(e, title, reason) => {
            if (reason === "clear") setCurrentSearchValue({ title });
            else setCurrentSearchValue({ ...currentSearchValue, title });
          }}
          onChange={(event, newValue: (ISearchValue | string | { inputValue: string })[]) => {
            const mappedValues = newValue.map<ISearchValue>((value) => {
              if (typeof value === "string") return { ...currentSearchValue, title: value };
              else if (value && "inputValue" in value) return { ...currentSearchValue, title: value.inputValue };
              else return { ...value };
            });
            const insertedValue = mappedValues.pop();
            if (insertedValue) {
              mappedValues.splice(insertedValue.index ?? mappedValues.length, 0, insertedValue);
              delete insertedValue.index;
            }

            setSearchValues(mappedValues);
            setSearchValueIndex(newValue.length);
            setCurrentSearchValue({ title: "" });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin={"dense"}
              variant="outlined"
              label="Suche"
            />
          )}
        />
        <ToggleButtonGroup
          size={"small"}
          exclusive
          className={classes.categoryContainer}
          value={filterCategory}
          onChange={(_, value) => handleSetFilterCategory(value)}
        >
          <ToggleButton value={ARTICLE_CATEGORY.CONSOLE}>
            <Console
              width={25}
              height={25}
              style={{ paddingLeft: 4, paddingRight: 4 }}
              color={theme.palette.text.primary}
            />
          </ToggleButton>
          <ToggleButton value={ARTICLE_CATEGORY.GAME}>
            <Game
              width={25}
              height={25}
              style={{ paddingLeft: 4, paddingRight: 4 }}
              color={theme.palette.text.primary}
            />
          </ToggleButton>
          <ToggleButton value={ARTICLE_CATEGORY.ACCESSORY}>
            <Accessory
              width={25}
              height={25}
              style={{ paddingLeft: 4, paddingRight: 4 }}
              color={theme.palette.text.primary}
            />
          </ToggleButton>
          <ToggleButton value={-1} selected={basicFilter === 1} onClick={handleSetBasicFilter}>
            <FaBold
              style={{
                height: 20,
                width: 20,
                paddingLeft: 4,
                paddingRight: 4,
                color: basicFilter === 0 ? theme.palette.text.primary : theme.palette.success.main,
              }}
            />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box display={"flex"} style={{ cursor: "pointer" }}>
        <Typography variant={"subtitle2"} style={{ marginRight: 16 }} onClick={handleAddSearch}>
          +Neu
        </Typography>
        <Typography variant={"subtitle2"} style={{ marginRight: 16 }} onClick={handleAddSearch}>
          +Gebraucht
        </Typography>
        <Typography variant={"subtitle2"} style={{ marginRight: 16 }} onClick={handleAddSearch}>
          +Modul
        </Typography>
        <Typography variant={"subtitle2"} style={{ marginRight: 16 }} onClick={handleAddSearch}>
          +OVP
        </Typography>
        <Typography variant={"subtitle2"} style={{ marginRight: 16 }} onClick={handleAddSearch}>
          +Original
        </Typography>
        <Typography variant={"subtitle2"} style={{ marginRight: 16 }} onClick={handleAddSearch}>
          +Anl
        </Typography>
        <Typography variant={"subtitle2"} style={{ marginRight: 16 }} onClick={handleAddSearch}>
          +CD
        </Typography>
      </Box>
      {searchResult.docs.length !== 0 && (
        <Typography gutterBottom variant={"subtitle2"} style={{ fontWeight: "bold", textAlign: "right" }}>
          {searchResult.numFound} Ergebnisse
        </Typography>
      )}
      {/* ---- Searchresults ---- */}
      {searchResult.docs.length !== 0 && (
        <Card variant={"outlined"} className={classes.searchContent} style={{ overflowY: "auto", flex: "1 1 0" }}>
          <Stack direction="column">
            <Box py={1}>
              {searchResult.docs &&
                searchResult.docs
                  .filter((item) => item.basic === basicFilter || basicFilter === 0)
                  .map((item) => (
                    <Grid
                      key={item.article_id}
                      container
                      style={{ padding: "4px" }}
                      borderBottom={1}
                      borderColor="#888"
                    >
                      <Grid item xs={3} style={{ position: "relative" }}>
                        <CardMedia
                          component={"img"}
                          width={"70px"}
                          height={"90px"}
                          image={
                            item.article_id
                              ? `${MARIO_URL}/product/image.php?size=md&id=${item.article_id}`
                              : process.env.PUBLIC_URL + "/img/keinBild.png"
                          }
                          style={{ marginRight: 16, cursor: "pointer", objectFit: "contain" }}
                          onMouseEnter={(e: any) => setPopper({ type: "image", anchorEl: e.currentTarget, item: item })}
                          onMouseLeave={() => setPopper({ type: "", anchorEl: null, item: item })}
                        />
                        <Grid
                          style={{
                            position: "absolute",
                            right: 6,
                            top: 5,
                            zIndex: 100,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {getArticleLanguage(item.name)?.map((lang) => (
                            <Avatar
                              key={lang}
                              src={process.env.PUBLIC_URL + `/img/${lang}.png`}
                              style={{ height: 20, width: 20, paddingLeft: 2, paddingRight: 2 }}
                              variant={"square"}
                            >
                              N
                            </Avatar>
                          ))}
                        </Grid>
                      </Grid>
                      <Grid item xs={9}>
                        <Grid container>
                          <Grid item xs={8}>
                            <Typography
                              variant={"caption"}
                              onClick={() => !disabled && handleAddArticle(item)}
                              sx={{ cursor: "pointer", color: mode === "dark" ? "#69b1ff" : "#007bff" }}
                            >
                              {getArticleName(item.name)}
                            </Typography>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              mb={1}
                              onClick={() => !disabled && handleAddArticle(item)}
                              style={{ cursor: "pointer" }}
                            >
                              {item.basic === 1 && (
                                <Chip
                                  size={"small"}
                                  label="B"
                                  style={{ backgroundColor: "green", color: "white", height: 15, marginRight: 8 }}
                                />
                              )}
                              <Typography
                                variant={"caption"}
                                sx={{
                                  fontWeight: "bold",
                                  color:
                                    getArticleConditionColor(item.name) || (mode === "dark" ? "#69b1ff" : "#007bff"),
                                }}
                              >
                                {getArticleCondition(item.name)}
                              </Typography>
                            </Box>
                            <Box display={"flex"} justifyContent={"space-between"} style={{ color: "grey" }}>
                              <Box display={"flex"} alignItems={"center"}>
                                <FaShoppingCart
                                  style={{ cursor: "pointer" }}
                                  onMouseEnter={(e) =>
                                    setPopper({ type: "history", anchorEl: e.currentTarget, item: item })
                                  }
                                  onMouseLeave={() => setPopper({ type: "", anchorEl: null, item: item })}
                                />
                                <Typography
                                  variant={"caption"}
                                  style={{ marginLeft: 8, color: item.bedarf30 >= 0 ? "green" : "red" }}
                                >
                                  {item.bedarf30}
                                </Typography>
                              </Box>
                              <Box display={"flex"} alignItems={"center"}>
                                <FaCubes />
                                <Typography
                                  variant={"caption"}
                                  style={{ marginLeft: 8, color: item.bestand >= 0 ? "green" : "red" }}
                                >
                                  {item.bestand}
                                </Typography>
                              </Box>
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                title={item.price_reset || item.price === 0 ? "Preis veraltet" : "Preis"}
                              >
                                <FaEuroSign />
                                <Typography
                                  variant={"caption"}
                                  style={{
                                    marginLeft: 8,
                                    color: item.price_reset || item.price === 0 ? "red" : "green",
                                  }}
                                >
                                  {item.price}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={4} style={{ display: "flex", justifyContent: "space-between" }}>
                            <Box display={"flex"} style={{ gap: 4 }}>
                              <Link
                                href={`${MARIO_URL}/ebay/4.0/redirect.php?articleId=${item.article_id}`}
                                target={"_blank"}
                              >
                                <FaExternalLinkAlt
                                  style={{ color: mode === "dark" ? "#69b1ff" : "#007bff" }}
                                  title={"Artikel in 3.0 öffnen"}
                                />
                              </Link>
                              {item.julian_price_source && (
                                <FaInfoCircle
                                  style={{ cursor: "pointer", color: mode === "dark" ? "#69b1ff" : "#007bff" }}
                                  onMouseEnter={(e) =>
                                    setPopper({ type: "price", anchorEl: e.currentTarget, item: item })
                                  }
                                  onMouseLeave={() => setPopper({ type: "", anchorEl: null, item: item })}
                                />
                              )}
                              {item.julian_price_source &&
                                JSON.parse(item.julian_price_source).map((source: any, index: number) => (
                                  <Box key={index}>
                                    {source.asin && (
                                      <Link
                                        href={`https://www.amazon.${source.storehouse === 1 ? "co.uk" : "de"}/dp/${
                                          source.asin
                                        }`}
                                        target={"_blank"}
                                      >
                                        <FaAmazon
                                          style={{ color: mode === "dark" ? "#69b1ff" : "#007bff" }}
                                          title={"Amazon"}
                                        />
                                      </Link>
                                    )}
                                    {source.notes === "FROZEN" && (
                                      <FaSnowflake
                                        style={{ color: mode === "dark" ? "#69b1ff" : "#007bff" }}
                                        title={"Frozen"}
                                      />
                                    )}
                                  </Box>
                                ))}
                            </Box>
                            <Box style={{ marginLeft: 8 }}>
                              <Input
                                type={"number"}
                                className={classes.customPriceInput}
                                endAdornment={
                                  <InputAdornment
                                    position={"end"}
                                    disableTypography
                                    sx={{ color: "text.primary", fontSize: 12 }}
                                  >
                                    €
                                  </InputAdornment>
                                }
                                value={item.julian_price}
                                disabled={disabled}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleChangeItemPurchasePrice(e, item)
                                }
                              />
                              <Button
                                size={"small"}
                                variant={"contained"}
                                color={"warning"}
                                onClick={() => !disabled && handleAddArticle(item, true)}
                                disabled={disabled}
                                fullWidth
                                style={{ minWidth: 0, marginTop: "10px" }}
                              >
                                <FaEdit />
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
            </Box>
          </Stack>
        </Card>
      )}
      {TagWordListPopper}
      {ImagePopper}
      {PricePopper}
      {HistoryPopper}
    </Root>
  );
};

export default OfferSearchBody;
