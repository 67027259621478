import { Box, Button, Grid } from "@mui/material";
import Offer from "../components/Offers/OfferContainer";
import { OfferContext } from "../data/contexts";
import { useStoreDispatch, useStoreSelector } from "../hooks/useStore";
import {
  decrementOfferIndex,
  fetchOffers,
  incrementOfferIndex,
  selectCurrentOffer,
  selectCurrentOfferIndex,
  selectIsSearching,
  selectOffersAmount,
} from "../store/offersSlice";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useEffect, useRef, useState } from "react";
import { selectIsLoading } from "../store/statusSlice";

const FETCH_OFFSET = 3;
// 104
const Home = ({ enqueueCallback }: { enqueueCallback: (callback: () => any) => Promise<any> }) => {
  const dispatch = useStoreDispatch();
  const isSearching = useStoreSelector(selectIsSearching);
  const isLoading = useStoreSelector(selectIsLoading);
  const currentOffer = useStoreSelector(selectCurrentOffer);
  const currentOfferIndex = useStoreSelector(selectCurrentOfferIndex);
  const amountOffers = useStoreSelector(selectOffersAmount);

  const wrapperElement = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);

  const nextOffer = () => {
    dispatch(incrementOfferIndex());
  };
  const previousOffer = () => {
    dispatch(decrementOfferIndex());
  };

  useEffect(() => {
    if (currentOfferIndex + FETCH_OFFSET >= amountOffers) enqueueCallback(() => dispatch(fetchOffers()));
  }, [currentOfferIndex, amountOffers, dispatch, enqueueCallback]);

  useEffect(() => {
    const resizeListener = () =>
      setContentHeight(window.innerHeight - wrapperElement.current!.getBoundingClientRect().top + 39);
      setContentWidth(wrapperElement.current!.getBoundingClientRect().width);
    window.addEventListener("resize", resizeListener);
    resizeListener();

    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  return (
    <Grid container ref={wrapperElement} style={{ height: `${contentHeight}px`, flexWrap: "nowrap"  }}>
      <Grid item xs={"auto"}>
        <Button
          title={"Vorheriges Angebot"}
          disabled={isSearching || isLoading}
          onClick={previousOffer}
          size="large"
          style={{ height: "100%", borderRadius: 0, minWidth: 0 }}
        >
          <FiArrowLeft size="2em" />
        </Button>
      </Grid>
      <Grid item xs={true} >
        <Box sx={{ bgcolor: "background.default", height: `${contentHeight}px`, maxWidth: contentWidth - 104 }}>
          {currentOffer && (
            <OfferContext.Provider value={currentOffer} key={currentOffer.angebot_id}>
              <Offer />
            </OfferContext.Provider>
          )}
        </Box>
      </Grid>
      <Grid item xs={"auto"}>
        <Button
          title={"Nächstes Angebot"}
          disabled={isSearching || isLoading}
          onClick={nextOffer}
          size="large"
          style={{ height: "100%", borderRadius: 0, minWidth: 0 }}
        >
          <FiArrowRight size="2em" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default Home;
