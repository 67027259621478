import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { Button as MuiButton, Card, CardMedia, Grid, Stack, LinearProgress, Typography, IconButton } from "@mui/material";
import { FiCornerUpLeft, FiCornerUpRight, FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { SiGooglelens } from "react-icons/si";
import { FaCaretDown, FaCaretUp, FaTrash, FaUpload } from "react-icons/fa";
import { TextRecognitionImage, TextRecognitionImageRef } from "@konsolenkost-de/react-image-mousetools";
import Button from "../Button";
import { OfferContext, SearchContext } from "../../data/contexts";
import { Box } from "@mui/system";
import { Checkbox } from "@mui/material";
import { useStoreDispatch, useStoreSelector } from "../../hooks/useStore";
import { toggleOfferImage } from "../../store/offersSlice";
import { IOfferImage } from "../../data/constants";
import { useDropzone } from "react-dropzone";
import useUpload from "../../hooks/useUpload";
import { selectSessionId } from "../../store/statusSlice";
import APIOffers from "../../helper/APIOffers";

const PREFIX = "OfferImage";

const classes = {
  container: `${PREFIX}-container`,
  imageContainer: `${PREFIX}-imageContainer`,
  fullImage: `${PREFIX}-fullImage`,
  imageButtons: `${PREFIX}-imageButtons`,
  arrowRightButton: `${PREFIX}-arrowRightButton`,
  arrowLeftButton: `${PREFIX}-arrowLeftButton`,
  checkbox: `${PREFIX}-checkbox`,
  checkboxSmall: `${PREFIX}-checkboxSmall`,
  deleteImageButton: `${PREFIX}-deleteImageButton`,
  zoomPortal: `${PREFIX}-zoomPortal`,
  imagesContainer: `${PREFIX}-imagesContainer`,
  annotation: `${PREFIX}-annotation`,
};

const StyledGrid = styled(Grid)({
  [`& .${classes.container}`]: {
    display: "flex",
  },
  [`& .${classes.imageContainer}`]: {
    height: "100%",
    position: "relative",
    flex: "1 1 100%"
  },
  [`& .${classes.fullImage}`]: {
    height: "100%",
    width: "100%",
  },
  [`& .${classes.imageButtons}`]: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  [`& .${classes.arrowRightButton}`]: {
    position: "absolute",
    top: "50%",
    right: 0,
    transform: "translateY(-50%)",
  },
  [`& .${classes.arrowLeftButton}`]: {
    position: "absolute",
    top: "50%",
    left: 0,
    transform: "translateY(-50%)",
  },
  [`& .${classes.checkbox}`]: {
    position: "absolute",
    bottom: 16,
    right: 16,
    color: "#2e7d32",
  },
  [`& .${classes.checkboxSmall}`]: {
    position: "absolute",
    bottom: 0,
    right: 0,
    paddingBottom: 0,
    color: "#2e7d32",
  },
  [`& .${classes.deleteImageButton}`]: {
    position: "absolute",
    top: 20,
    right: 2,
    paddingTop: 0,
  },
  [`& .${classes.zoomPortal}`]: {
    "&>div>div>div:first-of-type": {
      pointerEvents: "none",
    },
  },
  [`& .${classes.imagesContainer}`]: {
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      boxShadow: "inset 0 0 15px #222",
      borderRadius: "10px",
    },
  },
  [`& .${classes.annotation}`]: {
    "& div:first-of-type": {
      pointerEvents: "none",
    },
  },
});

const OfferImage = () => {
  const IMAGE_ROTATE_DEGREE: 0 | 90 | 180 | 270 = 90;

  const dispatch = useStoreDispatch();
  const offer = useContext(OfferContext);
  const sessionId = useStoreSelector(selectSessionId);
  const { addSearchValue } = useContext(SearchContext);

  const [imageIndex, setImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState({
    src: offer.upload_imgs ? offer.upload_imgs[0] : "",
    recognizedText: undefined as
      | undefined
      | Exclude<IOfferImage["vision_data"], undefined>["simplifiedTextAnnotations"],
    rotate: 0 as 0 | 90 | 180 | 270,
  });
  const [overviewHeight, setOverviewHeight] = useState("136px");
  const [showTextRecognition, setShowTextRecognition] = useState(false);
  const imageRef = useRef<TextRecognitionImageRef | null>(null);

  const [upload, resetUpload, uploadState] = useUpload(
    async ({ files }) => {
      const formData = new FormData();
      formData.append("sessionId", sessionId);
      files.forEach((file) => formData.append("file[]", file, file.name));

      return {
        method: "POST",
        url: "https://ankauf.konsolenkost.de/api/model/uploadImage.php",
        body: formData,
      };
    },
    (data) => {
      const offerApi = new APIOffers(dispatch);
      const images = Object.keys(data.images);
      offerApi.addOfferImages(offer.angebot_id, images);
    }
  );
  const onDrop = useCallback(
    (files: File[]) => upload({ files }),
    [upload]
  );
  const deleteImage = useCallback((image: string) => {
    if (window.confirm("Bild wirklich löschen?") === false) return;

    const offerApi = new APIOffers(dispatch);
    offerApi.deleteOfferImage(offer.angebot_id, image);
  }, [dispatch, offer.angebot_id]);

  const handleAnnotationSelect = (annotation: string) => {
    const sanitized = annotation
      .replace(/[^A-Za-z0-9ÄÖÜäöüß'.-_]+/g, " ")
      .replace(/([^\s\d])(\d+)/g, "$1 $2")
      .replace(/\.(\s+|$)/g, " ")
      .replace(/\s\s+/g, " ")
      .trim();

    addSearchValue(sanitized);
  };

  const toggleOverviewHeight = () => {
    setOverviewHeight(overviewHeight === "60%" ? "136px" : "60%");
  };

  useEffect(() => {
    const imageData = offer.images?.find(({ path }) => path === offer.upload_imgs?.[imageIndex]);
    setSelectedImage((selectedImage) => ({
      ...selectedImage,
      rotate: 0,
      src: offer.upload_imgs?.[imageIndex],
      recognizedText: imageData?.vision_data?.simplifiedTextAnnotations ?? undefined,
    }));
  }, [imageIndex, offer.upload_imgs, offer.images]);

  useEffect(() => {
    if (!selectedImage.recognizedText && showTextRecognition) {
      imageRef.current?.recognizeText();
    }
  }, [selectedImage, showTextRecognition, imageRef]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      "image/png": [".png", ".PNG"],
      "image/jpeg": [".jpg", ".jpeg", ".JPG", ".JPEG"],
      "image/tiff": [".tif", ".tiff", ".TIF", ".TIFF"],
      "image/webp": [".webp", ".WEBP"],
    },
  });

  ///////////////////////////////////////////
  //
  //             Image Logic
  //
  ///////////////////////////////////////////

  /**
   * Changes the selected image to the chosen one
   * @param e hover-event of the chosen image
   */
  const handleChangeImageDisplay = (e: React.MouseEvent<HTMLImageElement>) => {
    const index = offer.upload_imgs.findIndex((img) => img === e.currentTarget.src);
    if (index >= 0) setImageIndex(index);
  };

  /**
   * Changes the selected image to the next image
   */
  const showNextImage = () => {
    if (imageIndex < offer.upload_imgs.length - 1) setImageIndex(imageIndex + 1);
  };

  /**
   * Change the selected image to the previous image
   */
  const showPreviousImage = () => {
    if (imageIndex > 0) setImageIndex(imageIndex - 1);
  };

  /**
   * Changes the selected image based on the pressed arrow key
   */
  const handleUserKeyPress = (e: any) => {
    const { key } = e;
    if (key === "ArrowLeft") {
      showPreviousImage();
    }
    if (key === "ArrowRight") {
      showNextImage();
    }
  };

  /**
   * Rotates the selected image to the left by the defined degrees
   */
  const handleImageRotateLeft = () => {
    selectedImage.rotate =
      selectedImage.rotate - IMAGE_ROTATE_DEGREE < 0
        ? ((selectedImage.rotate - IMAGE_ROTATE_DEGREE + 360) as 0 | 90 | 180 | 270)
        : ((selectedImage.rotate - IMAGE_ROTATE_DEGREE) as 0 | 90 | 180 | 270);
    setSelectedImage({ ...selectedImage });
  };

  /**
   * Rotates the selected image to the right by the defined degrees
   */
  const handleImageRotateRight = () => {
    selectedImage.rotate =
      selectedImage.rotate + IMAGE_ROTATE_DEGREE >= 360
        ? ((selectedImage.rotate + IMAGE_ROTATE_DEGREE - 360) as 0 | 90 | 180 | 270)
        : ((selectedImage.rotate + IMAGE_ROTATE_DEGREE) as 0 | 90 | 180 | 270);
    setSelectedImage({ ...selectedImage });
  };

  /**
   * Ticks as checkboxes for completing an image offer
   * @param event
   * @param index
   */
  const handleCheckbox = (value: boolean, index: number = imageIndex) => {
    dispatch(toggleOfferImage({ offerId: offer.angebot_id, imageIndex: index, value }));
  };

  ///////////////////////////////////////////
  //
  //                Rendering
  //
  ///////////////////////////////////////////

  return (
    <StyledGrid item xs={5} sx={{ height: "100%" }}>
      <Stack direction="column" style={{ height: "100%", overflow: "hidden" }}>
        <Card
          variant={"outlined"}
          className={classes.imageContainer}
          sx={{ borderColor: "contrast.light", maxHeight: `calc(100% - ${overviewHeight})` }}
          onKeyDown={handleUserKeyPress}
        >
          {offer?.upload_imgs && (
            <>
              {/* ---- Selected Image ---- */}
              <TextRecognitionImage
                src={selectedImage.src}
                ref={imageRef}
                recognizedText={showTextRecognition ? selectedImage.recognizedText : undefined}
                groupBy="groups"
                initialZoom={0}
                minZoom={0}
                zoomWidth={200}
                zoomHeight={100}
                zoomPosition="bottom left"
                zoomMargin="15px"
                enableZoom={true}
                minimapMode={true}
                zoomAllowPan={true}
                rotation={selectedImage.rotate}
                onSelectText={(annotation) => handleAnnotationSelect(annotation.text)}
                style={{ height: "100%", width: "100%", overflow: "hidden" }}
                highlightSelected="#00FF00"
                titleSelected={true}
                zoomPortalClassName={classes.zoomPortal}
                annotationClassName={classes.annotation}
                resetZoomOnUpdate={true}
              />

              {/* ---- Image Buttons ---- */}
              <Box className={classes.arrowRightButton}>
                <Button onClick={showNextImage} title={"Nächstes Bild"} style={{ borderRadius: 0 }}>
                  <FiArrowRight />
                </Button>
              </Box>
              <Box className={classes.arrowLeftButton}>
                <Button onClick={showPreviousImage} title={"Vorheriges Bild"} style={{ borderRadius: 0 }}>
                  <FiArrowLeft />
                </Button>
              </Box>
              <Box className={classes.imageButtons}>
                <Button onClick={handleImageRotateLeft} title={"Bild nach links drehen"} style={{ borderRadius: 0 }}>
                  <FiCornerUpLeft />
                </Button>
                <Button onClick={handleImageRotateRight} title={"Bild nach rechts drehen"} style={{ borderRadius: 0 }}>
                  <FiCornerUpRight />
                </Button>
                <Button
                  title={"Texte in Bild erkennen"}
                  onClick={() => setShowTextRecognition(!showTextRecognition)}
                  color={showTextRecognition ? "success" : "warning"}
                  disabled={imageRef.current?.loading}
                  sx={{
                    borderRadius: 0,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  <SiGooglelens />
                </Button>
              </Box>
              <Checkbox
                className={classes.checkbox}
                color="success"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                onChange={(_, checked) => handleCheckbox(checked)}
                checked={!!offer.upload_imgs_checked?.[imageIndex]}
              />
            </>
          )}
        </Card>
        <Button onClick={toggleOverviewHeight} size="small" color="primary">
          {overviewHeight === "60%" ? <FaCaretDown /> : <FaCaretUp />}
        </Button>
        <Card
          variant={"outlined"}
          className={classes.imagesContainer}
          sx={{
            borderColor: "contrast.light",
            flexGrow: 1,
            flexShrink: 0,
            flexBasis: overviewHeight,
            backgroundColor: isDragActive ? "primary.light" : undefined,
          }}
        >
          <Grid container p={1} pb={0} spacing={1} {...getRootProps()} sx={{ miWidth: "100%", minHeight: "100%" }}>
            <input {...getInputProps()} />
            {isDragActive && (
              <Stack
                sx={{
                  maxHeight: "100%",
                  width: "100%",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <MuiButton
                  startIcon={<FaUpload />}
                  sx={{ backgroundColor: "transparent" }}
                  variant="contained"
                  disableElevation
                >
                  {" "}
                  Fotos hier ablegen zum hochladen...
                </MuiButton>
              </Stack>
            )}
            {(uploadState.loading || uploadState.error) && (
              <Stack
                sx={{
                  maxHeight: "100%",
                  width: "100%",
                  alignContent: "center",
                  justifyContent: "center",
                  color: uploadState.error ? "error" : "text.secondary",
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={uploadState.error ? 100 : uploadState.progress}
                  color={uploadState.error ? "error" : "primary"}
                  sx={{ marginLeft: 1 }}
                />
                <Typography variant="body2" color={uploadState.error ? "error" : "text.secondary"} align="center">
                  {uploadState.error ? "Es ist ein Fehler aufgetreten!" : `${uploadState.progress}%`}
                </Typography>
                {uploadState.error && <MuiButton onClick={() => resetUpload()}>Schließen</MuiButton>}
              </Stack>
            )}
            {offer.upload_imgs &&
              offer.upload_imgs.map((image, index) => (
                <Grid
                  item
                  key={index}
                  xs={2}
                  sx={{
                    height: 120,
                    marginBottom: 2,
                    position: "relative",
                    display: isDragActive || uploadState.loading || uploadState.error ? "none" : undefined,
                  }}
                >
                  <CardMedia
                    component={"img"}
                    image={image}
                    style={{ objectFit: "cover", cursor: "pointer", height: "120px" }}
                    onClick={handleChangeImageDisplay}
                  />
                  {!!offer.images?.[index]?.upload_user && (
                    <IconButton color="error" className={classes.deleteImageButton} onClick={() => deleteImage(image)}>
                      <FaTrash />
                    </IconButton>
                  )}
                  <Checkbox
                    className={classes.checkboxSmall}
                    color="success"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    onChange={(_, checked) => handleCheckbox(checked, index)}
                    checked={!!offer.upload_imgs_checked?.[index]}
                  />
                </Grid>
              ))}
            <Grid
              item
              xs={2}
              px={1}
              sx={{
                height: 120,
                marginBottom: 2,
                position: "relative",
                paddingTop: "0 !important",
                display: isDragActive || uploadState.loading || uploadState.error ? "none" : undefined,
              }}
            >
              <Stack
                direction="column"
                border="1px dashed"
                sx={{
                  marginTop: 1,
                  height: "100%",
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "primary.main",
                }}
              >
                <FaUpload size={32} />
                <Typography mt={1} variant="caption" color="text.secondary" align="center">
                  Weitere Bilder hier hinziehen...
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Stack>
    </StyledGrid>
  );
};

export default OfferImage;
