import { useState, useEffect, useContext, useRef, KeyboardEvent, SyntheticEvent, useCallback, useMemo } from "react";
import { styled } from "@mui/material/styles";
import {
  alpha,
  Box,
  Card,
  CardMedia,
  TextField,
  Typography,
  Grid,
  Chip,
  Badge,
  Fab,
  Dialog,
  Stack,
  Autocomplete,
  IconButton,
  ButtonGroup,
  Menu,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  FaTimes,
  FaPlus,
  FaMinus,
  FaSave,
  FaArrowRight,
  FaArrowLeft,
  FaClock,
  FaPencilAlt,
  FaSearch,
  FaTrash,
} from "react-icons/fa";
import { captureException } from "@sentry/react";
import OfferSearchBody from "./OfferSearchBody";
import Button from "../Button";
import Popper from "../Popper";
import { ArticleContext, OfferContext, SearchContext, TotalValuesContext } from "../../data/contexts";
import { MARIO_URL, ARTICLE_CATEGORY, ISearchItem, IOffer, ARTICLE_CONDITIONS, AUTOSAVE_INTERVAL } from "../../data/constants";
import APIOffers from "../../helper/APIOffers";
import { useStoreDispatch, useStoreSelector } from "../../hooks/useStore";
import { closeDialog } from "../../store/dialogSlice";
import { alert } from "../../store/alertSlice";
import { selectIsLoading } from "../../store/statusSlice";
import useArticleHelpers from "../../hooks/useArticleHelpers";
import {
  ICustomArticleTemplate,
  addManualArticleTemplate,
  removeManualArticleTemplate,
  selectArticleTemplates,
} from "../../store/templatesSlice";
import { throttle } from "throttle-debounce";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";

const PREFIX = "OfferSearchContainer";

const classes = {
  articleHeader: `${PREFIX}-articleHeader`,
  articleTypes: `${PREFIX}-articleTypes`,
  articleContainer: `${PREFIX}-articleContainer`,
  customScrollbar: `${PREFIX}-customScrollbar`,
  article: `${PREFIX}-article`,
  articleImage: `${PREFIX}-articleImage`,
  articleButtons: `${PREFIX}-articleButtons`,
  articleButton: `${PREFIX}-articleButton`,
  searchInput: `${PREFIX}-searchInput`,
  manualArticleContainer: `${PREFIX}-manualArticleContainer`,
  manualArticleInput: `${PREFIX}-manualArticleInput`,
  confirmArticleCheckbox: `${PREFIX}-confirmArticleCheckbox`,
  itemListPopper: `${PREFIX}-itemListPopper`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.manualArticleContainer}`]: {
    "& .MuiDialog-paper": {
      minHeight: "100px",
      maxHeight: "80vh",
      overflowY: "auto",
      padding: "20px",
    },
  },

  [`& .${classes.manualArticleInput}`]: {
    "& fieldset": { border: "1px solid " + theme.palette.contrast.light },
    "& label": { color: theme.palette.text.primary },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      webkitAppearance: "none",
      margin: 0,
    },
    "& input[type=number]": {
      mozAppearance: "textfield",
    },
  },
  "& .MuiAutocomplete-root .MuiFormLabel-root": {
    color: theme.palette.text.primary,
  }
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.articleHeader}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  [`& .${classes.articleTypes}`]: {
    display: "flex",
    flexDirection: "row",
  },

  [`& .${classes.customScrollbar}`]: {
    "&::-webkit-scrollbar": {
      height: "10px",
      marginLeft: 0,
      marginRight: 0,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      boxShadow: "inset 0 0 15px #222",
      borderRadius: "10px",
    },
  },

  [`& .${classes.articleContainer}`]: {
    minHeight: "148px",
    display: "flex",
    flexFlow: "row nowrap",
    marginTop: "5px",
    whiteSpace: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    "&::-webkit-scrollbar": {
      height: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      boxShadow: "inset 0 0 15px #222",
      borderRadius: "10px",
    },
  },

  [`& .${classes.article}`]: {
    minWidth: "25%",
    height: "120px",
    marginRight: "5px",
    flexGrow: 0,
    flexShrink: 0,
    position: "relative",
    textAlign: "center",
  },

  [`& .${classes.articleImage}`]: {
    height: "100px",
    maxWidth: "120px",
    objectFit: "contain",
    cursor: "pointer",
  },

  [`& .${classes.articleButtons}`]: {
    position: "absolute",
    top: 0,
    right: "8px",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.articleButton}`]: {
    width: "20px !important",
    height: "20px !important",
    minWidth: "0 !important",
    minHeight: "0 !important",
    marginBottom: "5px !important",
    lineHeight: "20px !important",
    verticalAlign: "middle !important",
    fontSize: "10px !important",
    zIndex: 1,
  },

  [`& .${classes.searchInput}`]: {
    "& label, & legend": {
      fontSize: "0.7rem",
    },
    "& input": {
      padding: "4px 8px",
      fontSize: "0.8rem",
    },
  },

  [`&.${classes.manualArticleContainer}`]: {
    "& .MuiDialog-paper": {
      minHeight: "100px",
      maxHeight: "80vh",
      maxWidth: "650px",
      overflowY: "auto",
      padding: "20px",
    },
  },

  [`& .${classes.manualArticleInput}`]: {
    minWidth: "240px !important",
    "& fieldset": { border: "1px solid " + theme.palette.contrast.light },
    "& label": { color: theme.palette.text.primary },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      webkitAppearance: "none",
      margin: 0,
    },
    "& input[type=number]": {
      mozAppearance: "textfield",
    },
  },
  "& .MuiAutocomplete-root .MuiFormLabel-root": {
    color: theme.palette.text.primary,
  },
  [`& .${classes.confirmArticleCheckbox}`]: {
    background: "rgba(255,  255, 255, 0.7)",
    "&:hover": {
      background: "rgba(0, 123, 255, 0.7)",
    },
    position: "absolute !important",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const OfferSearchContainer = () => {
  const theme = useTheme();
  const { sanitizeArticleName, getArticleName } = useArticleHelpers();

  const dispatch = useStoreDispatch();

  const EXCHANGE_VALUE_RATIO = 1.3;

  const loading = useStoreSelector(selectIsLoading);
  const manualArticleTemplates = useStoreSelector(selectArticleTemplates);
  const offer = useContext(OfferContext);
  const { value: articles, changeValue: setArticles } = useContext(ArticleContext);
  const { value: totalValues, changeValue: setTotalValues } = useContext(TotalValuesContext);
  const { resetSearch } = useContext(SearchContext);
  
  const [saved, setSaved] = useState(true);
  const [doSearch, setDoSearch] = useState(false);
  const [articlesLength, setArticlesLength] = useState(articles.length);
  const [manualArticle, setManualArticle] = useState<{
    open: boolean;
    name: string;
    quantity: number;
    price: number;
    base?: Partial<ISearchItem> & Pick<ISearchItem, "article_id">;
  }>({
    open: false,
    name: "",
    quantity: 1,
    price: 0.0,
  });
  const [popper, setPopper] = useState<{
    open: boolean;
    anchorEl: null | HTMLElement;
    article: { name: string; img: string };
  }>({
    open: false,
    anchorEl: null,
    article: {
      name: "",
      img: "",
    },
  });
  const [priceContextMenu, setPriceContextMenu] = useState<{
    open: boolean;
    anchorEl: null | HTMLElement;
    article: IOffer["item_data"][number];
  }>({
    open: false,
    anchorEl: null,
    article: {} as IOffer["item_data"][number],
  });
  const [priceEditValue, setPriceEditValue] = useState<number>(0);
  const [priceEditComment, setPriceEditComment] = useState<string>("");
  const [visibleArticleIndex, setVisibleArticleIndex] = useState<number>(0);
  const articleList = useRef<FixedSizeList>(null);

  ///////////////////////////////////////////
  //
  //             Article Logic
  //
  ///////////////////////////////////////////

  /**
   * Add article to article list or increase the quantity if it already exists
   * @param item
   */
  const handleAddArticle = useCallback((item: ISearchItem, edit?: boolean) => {
    let searchArticles = articles.find((article) => `${article.matching_item_id}` === `${item.article_id}`);

    if (edit) {
      return setManualArticle({
        base: item,
        name: getArticleName(item.name).trim(),
        price: item.julian_price,
        quantity: 1,
        open: true,
      });
    }

    if (searchArticles) {
      searchArticles.quantity = searchArticles.quantity + 1;
      searchArticles.matching_purchase_price = item.julian_price;
      searchArticles.stamp_pr = (item.julian_price * searchArticles.quantity).toFixed(2);
      setArticles([...articles]);
      resetSearch(item.name.split("-")[0], true);
    } else {
      setArticles([
        ...articles,
        {
          session_item_id: Math.floor((Math.random() * 0xffffff) << 0)
            .toString(16)
            .substring(1),
          item_id: 0,
          name: sanitizeArticleName(item.name),
          img: `${MARIO_URL}/product/image.php?size=md&id=${item.article_id}`,
          quantity: 1,
          matching_item_id: item.article_id,
          matching_purchase_price: item.julian_price,
          matching_purchase_price_original: item.julian_price_original,
          matching_price_outdated: item.price_reset || item.price === 0,
          stamp_pr: item.julian_price.toFixed(2),
          cat2_id: item.cat2_id,
        },
      ]);

      resetSearch(item.name.split("-")[0], true);
    }

    setSaved(false);
  }, [articles, getArticleName, setArticles, resetSearch, sanitizeArticleName]);

  /**
   * Increases the quantity for the current article
   * @param session_item_id
   */
  const handleIncreaseArticleQuantity = useCallback((session_item_id: string) => {
    setArticles(articles.map((article) => {
      if (article.session_item_id === session_item_id) {
        article = {
          ...article,
          quantity: article.quantity + 1,
          stamp_pr: (article.matching_purchase_price * (article.quantity + 1)).toFixed(2),
        }
      }
      return article;
    }));
    setSaved(false);
  }, [articles, setArticles]);

  /**
   * Decreases the quantity for the current article
   * @param session_item_id
   */
  const handleDecreaseArticleQuantity = useCallback((session_item_id: string) => {
    setArticles(articles.reduce((articles, article) => {
      if (article.session_item_id === session_item_id) {
        const quantity = article.quantity - 1;

        if (quantity > 0) {
          articles.push({
            ...article,
            quantity,
            stamp_pr: (article.matching_purchase_price * quantity).toFixed(2),
          });
        }
      } else {
        articles.push(article);
      }

      return articles;
    }, [] as IOffer['item_data']));
    setSaved(false);
  }, [articles, setArticles]);

  const handleUpdateArticlePrice = useCallback((article: IOffer["item_data"][number], matching_purchase_price: number, comment: string) => {
    setArticles(articles.map((a) => {
      if (a.session_item_id === article.session_item_id) {
        return {
          ...article,
          stamp_pr: (matching_purchase_price * article.quantity).toFixed(2),
          matching_purchase_price,
          comment
        }
      }
      return a;
    }
    ));
    setSaved(false);
  }, [articles, setArticles]);

  /**
   * Deletes the item from the article
   * @param session_item_id
   */
  const handleDeleteFromArticles = useCallback((session_item_id: string) => {
    setArticles(articles.filter((article) => article.session_item_id !== session_item_id));
    setSaved(false);
  }, [articles, setArticles]);

  /**
   * Saves the current articles to the database and updates the article list with their new ids
   */
  const handleSaveArticles = useCallback((articles: IOffer['item_data']) => {
    new APIOffers(dispatch)
    .saveArticles(offer.angebot_id, articles, setArticles)
      .then((response) => {
        setSaved(true);
        dispatch(alert({ severity: "success", message: "Die Artikel wurden gespeichert." }));
      })
      .catch((error) => {
        dispatch(
          alert({ severity: "error", message: "Beim Speichern ist ein Fehler aufgetreten. Versuche es später erneut!" })
        );
        captureException(error);
        setSaved(false);
      });
  }, [dispatch, offer.angebot_id, setArticles]);

  // eslint-disable-next-line
  const autoSaveArticles = useCallback(throttle(AUTOSAVE_INTERVAL, (articles: IOffer['item_data']) => {
    handleSaveArticles(articles)
  }, { noLeading: true }), [handleSaveArticles]);
  useEffect(() => {
    if (!saved) autoSaveArticles(articles);
    // eslint-disable-next-line
  }, [saved, autoSaveArticles]);

  useEffect(() => (() => {
    // Cancel autosave on unmount
    autoSaveArticles.cancel();
  }), [offer.angebot_id, autoSaveArticles]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const autoCompleteList: HTMLDivElement | null = document.querySelector(
        `.${classes.itemListPopper} .MuiAutocomplete-listbox`
      );
      if (!autoCompleteList) return;

      autoCompleteList.style.maxHeight = `${
        document.body.offsetHeight - autoCompleteList.getBoundingClientRect().top - 86
      }px`;
    });
    observer.observe(document.body, { childList: true });

    return () => observer.disconnect();
  }, []);

  /**
   * Adds manually created article to the list of articles
   */
  const handleAddManualArticle = useCallback(() => {
    const newArticle = {
      session_item_id: Math.floor((Math.random() * 0xffffff) << 0)
        .toString(16)
        .substring(1),
      item_id: 0,
      name: manualArticle.name,
      img: manualArticle.base ? `${MARIO_URL}/product/image.php?size=md&id=${manualArticle.base.article_id}` : "",
      quantity: manualArticle.quantity,
      matching_item_id: "0",
      matching_purchase_price: manualArticle.price,
      matching_purchase_price_original: manualArticle.price,
      matching_price_outdated: false,
      stamp_pr: (manualArticle.price * manualArticle.quantity).toFixed(2),
      cat2_id: manualArticle.base?.cat2_id ?? ARTICLE_CATEGORY.UNDEFINED,
    };

    setArticles([...articles, newArticle]);
    dispatch(closeDialog());
  }, [articles, dispatch, manualArticle, setArticles]);

  const handleClickArticle = useCallback((article: IOffer["item_data"][number]) => {
    resetSearch(article.matching_item_id === "0" ? getArticleName(article.name) : article.matching_item_id);
  }, [getArticleName, resetSearch]);

  /**
   * Shows next/previous batch of articles
   */
  const showNextArticle = useCallback(() => {
    if (articleList.current) {
      articleList.current.scrollToItem(Math.min(visibleArticleIndex + 4, articles.length), "start")
    }
  }, [articles, visibleArticleIndex]);

  const showPreviousArticle = useCallback(() => {
    if (articleList.current) {
      articleList.current.scrollToItem(Math.max(visibleArticleIndex - 4, 0), "start")
    }
  }, [visibleArticleIndex]);

  const handleEscapePress = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      setDoSearch(false);
    }
  }, []);

  const getPriceMessage = useCallback((article: IOffer["item_data"][number]) => {
    if (article.matching_item_id === "0") return "Artikel wurde manuell hinzugefügt";
    if (article.matching_purchase_price !== article.matching_purchase_price_original)
      return "Preis wurde manuell angepasst";
    if (article.matching_price_outdated) return "Preis ist veraltet und muss geprüft werden";

    return "";
  }, []);

  const getPriceIcon = useCallback((article: IOffer["item_data"][number]) => {
    if (article.matching_item_id === "0") return <FaPlus style={{ color: "orange", marginRight: "5px" }} />;
    if (article.matching_purchase_price !== article.matching_purchase_price_original)
      return <FaPencilAlt style={{ color: "orange", marginRight: "5px" }} />;
    if (article.matching_price_outdated) return <FaClock style={{ color: "orange", marginRight: "5px" }} />;
  }, []);

  /**
   * Updates the offers' total price, article quantity, exchange value whenever the articles are updated.
   * Also scrolls to the end of the articlelist if a new article has been added
   */
  useEffect(() => {
    const adjustmentFactor = (100 + (offer.adjustment_percent ?? 0)) / 100;
    const totalPrice = articles.reduce((sum, { stamp_pr }) => sum + parseFloat(stamp_pr), 0) * adjustmentFactor;
    const totalQuantity = articles.reduce((sum, { quantity }) => sum + quantity, 0);
    const unverifiedItems = articles.reduce(
      (count, article) =>
        (article.matching_price_outdated || // Outdated price
          article.matching_item_id === "0" || // Manually Added
          article.matching_purchase_price !== article.matching_purchase_price_original) && // Price has been changed
        !article.checked
          ? count + 1
          : count,
      0
    );
    const totalExchangeValue = totalPrice * EXCHANGE_VALUE_RATIO;
    setTotalValues({ totalPrice, totalQuantity, totalExchangeValue, unverifiedItems });

    if (articlesLength < articles.length) {
      articleList.current?.scrollToItem(articles.length);
      setArticlesLength(articles.length);
    }

    // eslint-disable-next-line
  }, [articles, offer.adjustment_percent]);

  ///////////////////////////////////////////
  //
  //               Rendering
  //
  ///////////////////////////////////////////

  const closeManualArticleDialog = useCallback(() => setManualArticle({ ...manualArticle, open: false }), [manualArticle]);

  const saveArticleTemplate = useCallback(() => {
    const template = {
      name: manualArticle.name,
      baseArticleId: manualArticle.base?.article_id,
      image: manualArticle.base ? `${MARIO_URL}/product/image.php?size=md&id=${manualArticle.base.article_id}` : "",
    };
    dispatch(addManualArticleTemplate(template));
    dispatch(alert({ severity: "success", message: `Vorlage für ${template.name} wurde gespeichert.` }));
  }, [dispatch, manualArticle]);

  const removeArticleTemplate = useCallback((template: ICustomArticleTemplate) => {
    dispatch(removeManualArticleTemplate(template));
    dispatch(alert({ severity: "success", message: `Vorlage für ${template.name} wurde gelöscht.` }));
  }, [dispatch]);

  const selectArticleTemplate = useCallback((event: SyntheticEvent, template: ICustomArticleTemplate | null) => {
    if (!template) return;

    setManualArticle({
      ...manualArticle,
      name: template.name,
      quantity: 1,
      price: 0,
      base: template.baseArticleId ? { article_id: template.baseArticleId } : undefined,
    });
  }, [manualArticle]);

  const ManualArticleDialog = useMemo(() => (
    <StyledDialog
      open={manualArticle.open}
      onClose={closeManualArticleDialog}
      className={classes.manualArticleContainer}
    >
      <Typography variant={"h5"} mb={2}>
        Neuen Artikel hinzufügen [{offer.angebot_id}]
      </Typography>
      <Autocomplete
        autoHighlight
        options={manualArticleTemplates}
        getOptionLabel={(option) => `${option.baseArticleId ?? "0"}: ${option.name}`}
        onChange={selectArticleTemplate}
        renderOption={(props, option) => (
          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
            {option.baseArticleId && (
              <img
                src={`${MARIO_URL}/product/image.php?size=md&id=${option.baseArticleId}`}
                alt={option.name}
                style={{ maxWidth: "36px", maxHeight: "100%" }}
              />
            )}
            <Typography variant={"subtitle1"}>{option.name}</Typography>
            <IconButton onClick={() => removeArticleTemplate(option)} aria-label="delete" sx={{ marginLeft: "auto" }}>
              <FaTrash />
            </IconButton>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Suche eine Vorlage"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
      <Grid container mt={"auto"} spacing={1} pl={1} pt={2} sx={{ alignItems: "center" }}>
        {manualArticle.base && (
          <Grid>
            <img
              src={`${MARIO_URL}/product/image.php?size=md&id=${manualArticle.base.article_id}`}
              alt={manualArticle.base.name}
              style={{ maxWidth: "150px", maxHeight: "100%" }}
            />
          </Grid>
        )}
        <Grid item xs={true}>
          <TextField
            autoFocus
            label={"Name"}
            fullWidth
            variant={"outlined"}
            className={classes.manualArticleInput}
            style={{ marginBottom: 6 }}
            value={manualArticle.name}
            onChange={(e) => setManualArticle({ ...manualArticle, name: e.target.value })}
          />
          <ButtonGroup variant="text" sx={{ maxWidth: "100%", flexWrap: "wrap", marginBottom: 1, marginLeft: "-4px", marginRight: "-4px" }}>
            {ARTICLE_CONDITIONS.map((condition) => (
              <Chip
                key={condition}
                label={`+ ${condition}`}
                size="small"
                onClick={() => setManualArticle({ ...manualArticle, name: `${manualArticle.name} (${condition})` })}
                sx={{margin: "4px"}}
              />
            ))}
          </ButtonGroup>
          <TextField
            label={"Anzahl"}
            type={"number"}
            fullWidth
            variant={"outlined"}
            className={classes.manualArticleInput}
            style={{ marginTop: 6, marginBottom: 6 }}
            value={manualArticle.quantity}
            onChange={(e) => setManualArticle({ ...manualArticle, quantity: parseInt(e.target.value) })}
          />
          <TextField
            label={"Preis"}
            type={"number"}
            fullWidth
            variant={"outlined"}
            className={classes.manualArticleInput}
            style={{ marginTop: 6, marginBottom: "16px" }}
            value={manualArticle.price}
            onChange={(e) => setManualArticle({ ...manualArticle, price: parseFloat(e.target.value) })}
          />
        </Grid>
      </Grid>
      <Grid container mt={"auto"} spacing={1} style={{ justifyContent: "flex-end" }}>
        <Grid item>
          <Button onClick={saveArticleTemplate} color="primary" sx={{ marginRight: "auto" }}>
            Vorlage speichern
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={closeManualArticleDialog} color={"error"}>
            Abbrechen
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              handleAddManualArticle();
              closeManualArticleDialog();
            }}
            color={"success"}
          >
            Hinzufügen
          </Button>
        </Grid>
      </Grid>
    </StyledDialog>
  ), [manualArticle, closeManualArticleDialog, offer, manualArticleTemplates, selectArticleTemplate, saveArticleTemplate, removeArticleTemplate, handleAddManualArticle]);

  const ImagePopper = useMemo(() => (
    <Popper open={popper.open} anchorEl={popper.anchorEl} placement={"left"}>
      <Grid style={{ maxWidth: "200px", padding: "5px" }}>
        <Typography style={{ textAlign: "center", wordWrap: "normal", maxWidth: "100%" }}>
          {popper.article.name}
        </Typography>
        <img
          src={popper.article.img !== "" ? popper.article.img : process.env.PUBLIC_URL + "/img/keinBild.png"}
          alt={popper.article.name}
          style={{ width: "100%", height: "auto" }}
        />
      </Grid>
    </Popper>
  ), [popper]);

  const PriceContextMenu = useMemo(
    () => (
      <Menu
        open={priceContextMenu.open}
        anchorEl={priceContextMenu.anchorEl}
        onClose={() => setPriceContextMenu({ ...priceContextMenu, open: false })}
      >
        <MenuItem disabled>
          <Typography variant={"subtitle1"}>
            Preis pro Artikel (Gesamt: {(priceEditValue * priceContextMenu.article.quantity).toFixed(2)}€)
          </Typography>
        </MenuItem>
        <MenuItem>
          <TextField
            autoFocus
            label={"Preis"}
            type={"number"}
            variant={"outlined"}
            className={classes.manualArticleInput}
            style={{ marginTop: 6 }}
            value={priceEditValue}
            onChange={(e) => setPriceEditValue(parseFloat(e.target.value))}
            onKeyDown={(e) => {
              if (e.key === "Enter" && priceEditComment) {
                handleUpdateArticlePrice(priceContextMenu.article, priceEditValue, priceEditComment);
                setPriceContextMenu({ ...priceContextMenu, open: false });
              }
            }}
          />
        </MenuItem>
        <MenuItem>
          <TextField
            label={"Kommentar"}
            variant={"outlined"}
            className={classes.manualArticleInput}
            style={{ marginBottom: "16px" }}
            value={priceEditComment}
            onChange={(e) => setPriceEditComment(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && priceEditComment) {
                handleUpdateArticlePrice(priceContextMenu.article, priceEditValue, priceEditComment);
                setPriceContextMenu({ ...priceContextMenu, open: false });
              }
            }}
          />
        </MenuItem>
        <MenuItem>
          <Button
            sx={{ marginLeft: "auto" }}
            color={"success"}
            disabled={!priceEditComment}
            onClick={() => {
              handleUpdateArticlePrice(priceContextMenu.article, priceEditValue, priceEditComment);
              setPriceContextMenu({ ...priceContextMenu, open: false });
            }}
          >
            Speichern
          </Button>
        </MenuItem>
      </Menu>
    ),
    [handleUpdateArticlePrice, priceContextMenu, priceEditValue, priceEditComment]
  );

  const renderItem = useCallback(({index, style}: ListChildComponentProps) => {
    const article = articles[index];

    return (
      <Box
        style={style}
        onContextMenu={(e) => {
          if (offer.status_id !== 1.6) return;

          e.preventDefault();
          setPriceEditValue(article.matching_purchase_price);
          setPriceEditComment("");
          setPriceContextMenu({ open: true, anchorEl: e.currentTarget, article });
        }}
      >
        <Badge
          color={"secondary"}
          sx={{ left: "50%", transform: "translateX(-50%) translateX(-8px)" }}
          badgeContent={article.quantity}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onMouseEnter={(e) =>
            setPopper({
              open: true,
              anchorEl: e.currentTarget,
              article: { name: article.name, img: article.img },
            })
          }
          onMouseLeave={() => setPopper({ open: false, anchorEl: null, article: { name: "", img: "" } })}
          onClick={() => handleClickArticle(article)}
        >
          <CardMedia
            className={classes.articleImage}
            component={"img"}
            image={article.img !== "" ? article.img : process.env.PUBLIC_URL + "/img/keinBild.png"}
            alt={article.name}
            title={article.matching_item_id}
          />
        </Badge>
        <Box
          sx={{ color: "text.main", mr: 1, mt: 1 }}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          title={getPriceMessage(article)}
        >
          {(article.matching_item_id === "0" ||
          article.matching_purchase_price !== article.matching_purchase_price_original) && offer.status_id < 2 && (
            <Checkbox
              onClick={(e) => e.stopPropagation()}
              onContextMenu={(e) => e.stopPropagation()}
              checked={article.checked}
              color="error"
              size="small"
              sx={{
                color: (theme) => theme.palette.error.main,
              }}
              onChange={(e) =>
                setArticles(
                  articles.map((a) =>
                    a.session_item_id === article.session_item_id ? { ...a, checked: e.target.checked } : a
                  )
                )
              }
              // className={classes.confirmArticleCheckbox}
            />
          )}
          {getPriceIcon(article)}
          <Typography style={{ color: getPriceMessage(article) !== "" ? "red" : "unset" }}>
            {article.stamp_pr + " €"}
          </Typography>
        </Box>
        <Box className={classes.articleButtons}>
          <Fab
            className={classes.articleButton}
            sx={{
              backgroundColor: "success.main",
              color: "#fff",
              "&:hover": {
                backgroundColor: alpha(theme.palette.success.main, theme.palette.action.hoverOpacity),
              },
            }}
            onClick={() => handleIncreaseArticleQuantity(article.session_item_id)}
            disabled={offer.status_id >= 2}
            title={"Anzahl erhöhen"}
          >
            <FaPlus />
          </Fab>
          <Fab
            className={classes.articleButton}
            sx={{
              backgroundColor: "error.main",
              color: "#fff",
              "&:hover": {
                backgroundColor: alpha(theme.palette.error.main, theme.palette.action.hoverOpacity),
              },
            }}
            disabled={offer.status_id >= 2}
            onClick={() => handleDecreaseArticleQuantity(article.session_item_id)}
            title={"Anzahl verringern"}
          >
            <FaMinus />
          </Fab>
          <Fab
            className={classes.articleButton}
            sx={{
              backgroundColor: "error.main",
              color: "#fff",
              "&:hover": {
                backgroundColor: alpha(theme.palette.error.main, theme.palette.action.hoverOpacity),
              },
            }}
            disabled={offer.status_id >= 2}
            onClick={() => handleDeleteFromArticles(article.session_item_id)}
            title={"Aus den Artikeln löschen"}
          >
            <FaTimes />
          </Fab>
        </Box>
      </Box>
    );
    // eslint-disable-next-line
  }, [articles])

  return (
    <StyledGrid item xs={4} style={{ height: "100%" }}>
      <Card variant={"outlined"} style={{ height: "100%", borderColor: "contrast.light" }}>
        <Stack direction="column" p={1} style={{ height: "100%" }}>
          {/* ---- Articles ---- */}
          <Box className={classes.articleHeader}>
            <Box className={classes.articleTypes}>
              <Typography variant={"subtitle1"} style={{ marginRight: "5px", fontWeight: "bold" }}>
                Gesamtanzahl
              </Typography>
              <Chip size={"small"} label={totalValues.totalQuantity} color={"secondary"} title="Artikel Gesamt" />
              {totalValues.unverifiedItems > 0 && (
                <Chip
                  size={"small"}
                  sx={{ ml: 1 }}
                  label={totalValues.unverifiedItems}
                  color={"error"}
                  title="Zu prüfende Artikel"
                />
              )}
            </Box>
            <Box>
              <Button
                color={"primary"}
                style={{ marginRight: "5px" }}
                onClick={() => showPreviousArticle()}
                disabled={visibleArticleIndex <= 0}
                title={"Vorherige Artikel anzeigen"}
              >
                <FaArrowLeft />
              </Button>
              <Button
                color={"primary"}
                style={{ marginRight: "5px" }}
                onClick={() => showNextArticle()}
                disabled={visibleArticleIndex >= articles.length - 4}
                title={"Nächste Artikel anzeigen"}
              >
                <FaArrowRight />
              </Button>
              <Button
                color={"success"}
                style={{ marginRight: "5px" }}
                disabled={offer.status_id >= 2 || loading || saved}
                onClick={() => {
                  autoSaveArticles.cancel();
                  handleSaveArticles(articles);
                }}
                title={`Loading ${loading}, Saved ${saved}`}
              >
                <FaSave />
              </Button>
              <Button
                color={"warning"}
                style={{ marginRight: "5px" }}
                onClick={() =>
                  setManualArticle({
                    ...manualArticle,
                    quantity: 1,
                    price: 0,
                    base: undefined,
                    open: true,
                  })
                }
                disabled={offer.status_id >= 2}
                title={"Artikel manuell hinzufügen"}
              >
                <FaPlus />
              </Button>
              <Button
                color={"secondary"}
                title={"Artikel Suchen"}
                disabled={(!articles || !articles.length) && !doSearch}
                style={{ minWidth: 0, padding: "6px" }}
                onClick={() => setDoSearch(!doSearch)}
              >
                <FaSearch />
              </Button>
            </Box>
          </Box>
          <Grid className={classes.articleContainer} style={{ position: "relative" }}>
            {doSearch && (
              <Card
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  zIndex: 2,
                  width: "100%",
                  height: "100%",
                  paddingTop: "10px",
                }}
              >
                <Autocomplete
                  autoHighlight
                  fullWidth
                  open
                  options={articles}
                  componentsProps={{ popper: { className: classes.itemListPopper } }}
                  getOptionLabel={(article) => `${article.matching_item_id}: ${article.name}`}
                  onKeyDown={handleEscapePress}
                  onChange={(event, article) => article && handleClickArticle(article)}
                  renderOption={(props, article) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                      onContextMenu={(e) => {
                        if (offer.status_id !== 1.6) return;

                        e.preventDefault();
                        setPriceEditValue(article.matching_purchase_price);
                        setPriceEditComment("");
                        setPriceContextMenu({ open: true, anchorEl: e.currentTarget, article });
                      }}
                    >
                      <Badge
                        color={"secondary"}
                        badgeContent={article.quantity}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        sx={{ marginBottom: "8px", marginRight: "16px" }}
                      >
                        <CardMedia
                          className={classes.articleImage}
                          sx={{ height: "100px" }}
                          component={"img"}
                          image={article.img !== "" ? article.img : process.env.PUBLIC_URL + "/img/keinBild.png"}
                          alt={article.name}
                        />
                        {(article.matching_item_id === "0" ||
                          article.matching_purchase_price !== article.matching_purchase_price_original) && (
                          <Checkbox
                            onClick={(e) => e.stopPropagation()}
                            checked={article.checked}
                            sx={{
                              background: "rgba(255,  255, 255, 0.7)",
                              "&:hover": {
                                background: "rgba(0, 123, 255, 0.7)",
                              },
                              position: "absolute !important",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                            onChange={(e) =>
                              setArticles(
                                articles.map((a) =>
                                  a.session_item_id === article.session_item_id
                                    ? { ...a, checked: e.target.checked }
                                    : a
                                )
                              )
                            }
                            className={classes.confirmArticleCheckbox}
                          />
                        )}
                      </Badge>
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant={"subtitle1"}>
                          {article.matching_item_id.toString() !== "0" && `${article.matching_item_id}: `}
                          {article.name}
                        </Typography>
                        <Box mt={1} title={getPriceMessage(article)} sx={{ display: "flex", alignItems: "center" }}>
                          {getPriceIcon(article)}
                          <Typography style={{ color: getPriceMessage(article) !== "" ? "red" : "unset" }}>
                            {article.stamp_pr + " €"}
                          </Typography>
                        </Box>
                      </Box>
                      <IconButton
                        onClick={() => handleDecreaseArticleQuantity(article.session_item_id)}
                        disabled={offer.status_id >= 2}
                        color="error"
                        size="small"
                        sx={{ marginLeft: "auto" }}
                      >
                        <FaMinus />
                      </IconButton>
                      <IconButton
                        onClick={() => handleIncreaseArticleQuantity(article.session_item_id)}
                        disabled={offer.status_id >= 2}
                        color="success"
                        size="small"
                        sx={{ marginLeft: "auto" }}
                      >
                        <FaPlus />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteFromArticles(article.session_item_id)}
                        disabled={offer.status_id >= 2}
                        aria-label="delete"
                        size="small"
                        sx={{ marginLeft: "auto" }}
                      >
                        <FaTrash />
                      </IconButton>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Suche einen Artikel"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Card>
            )}
            <AutoSizer>
              {({ width, height }: Size) => (
                <FixedSizeList
                  width={width}
                  height={height}
                  itemSize={141}
                  key={offer.angebot_id}
                  itemKey={(index) => articles[index].session_item_id}
                  layout="horizontal"
                  itemCount={articles.length}
                  className={classes.customScrollbar}
                  style={{ overflowX: "auto" }}
                  ref={articleList}
                  onItemsRendered={({ visibleStartIndex }) => setVisibleArticleIndex(visibleStartIndex)}
                >
                  {renderItem}
                </FixedSizeList>
              )}
            </AutoSizer>
          </Grid>

          <OfferSearchBody handleAddArticle={handleAddArticle} disabled={offer.status_id >= 2} />
        </Stack>
      </Card>
      {ManualArticleDialog}
      {ImagePopper}
      {PriceContextMenu}
    </StyledGrid>
  );
};

export default OfferSearchContainer;
