import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

export interface IDialogActions {
    label: string;
    onPress: () => void;
    type: 'primary' | 'success' | 'error' | 'warning';
}

interface DialogValuesContent {
    title?: string;
    content?: string;
}

interface DialogValuesConfirm extends DialogValuesContent{
    actions: IDialogActions[];
}

interface DialogValuesCustom extends DialogValuesContent {
    disagreelabel?: string;
    agreeLabel?: string;
    handleDisagree: () => void;
    handleAgree: () => void;
}

type DialogValues = DialogValuesConfirm | DialogValuesCustom;

export interface IDialogValues {
    open: boolean;
    title?: string;
    content?: string;
    actions: IDialogActions[];
}

const initialState: IDialogValues = {
    open: false,
    actions: [
        { label: 'Abbrechen', onPress: () => closeDialog(), type: 'error'},
        { label: 'Bestätigen', onPress: () => closeDialog(), type: 'success'}
    ]
}

export const dialogSlice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        openDialog: (state, action: PayloadAction<DialogValues>) => {
            state.open = true;
            state.title = action.payload.title;
            state.content = action.payload.content;
            state.actions = 'actions' in action.payload
                ? (action.payload as DialogValuesConfirm).actions
                : [
                    { label: action.payload.disagreelabel ?? 'Abbrechen', onPress: action.payload.handleDisagree, type: 'error'},
                    { label: action.payload.agreeLabel ?? 'Bestätigen', onPress: action.payload.handleAgree, type: 'success'}
                ]
        },
        closeDialog: () => initialState
    }
})

export const { openDialog, closeDialog } = dialogSlice.actions;

export const selectDialog = (state: RootState) => state.dialog;
export const selectDialogOpen = (state: RootState) => state.dialog.open;

export default dialogSlice.reducer;