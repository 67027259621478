import { Box, Paper, Popper as MUIPopper, ClickAwayListener } from '@mui/material';

interface IProps {
    open?: boolean;
    anchorEl?: any;
    placement?: 'top' | 'left' | 'right' | 'bottom';
    timeout?: number;
    onClose?: () => any;
    children: React.ReactNode;
}

const Popper = ({ open = false, anchorEl, placement = 'top', timeout = 0, onClose = () => { }, children }: IProps) => {

    return (
        <MUIPopper
            open={open}
            anchorEl={anchorEl}
            placement={placement}
            style={{ zIndex: 10000 }}
            modifiers={[
                {
                    name: 'offset',
                    enabled: true,
                    options: {
                        offset: [0, 10]
                    }
                },
                {
                    name: 'preventOverflow',
                    options: {
                        boundary: 'window'
                    }
                },
                {
                    name: 'flip',
                    enabled: false
                }
            ]}
        >
            <ClickAwayListener onClickAway={onClose}>
                <Paper sx={{ border: '1px solid', borderColor: 'contrast.light' }}>
                    <Box>{children}</Box>
                </Paper>
            </ClickAwayListener>
        </MUIPopper>
    );
};

export default Popper;
