import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";

export interface IStatus {
  loading: boolean;
  sessionId: string;
}

const initialState: IStatus = {
  loading: false,
  // Use same mechanism as in ankauf frontend
  sessionId: Math.random().toString(36).substring(2, 5).toUpperCase() + new Date().getTime(),
};

export const statusSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    resetSessionId: (state) => {
      state.sessionId = Math.random().toString(36).substring(2, 5).toUpperCase() + new Date().getTime();
    },
  },
});

export const { startLoading, stopLoading, resetSessionId } = statusSlice.actions;

export const selectIsLoading = (state: RootState) => state.status.loading;
export const selectSessionId = (state: RootState) => state.status.sessionId;

export default statusSlice.reducer;
