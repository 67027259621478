import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

export interface ICustomFilter {
    enabled: boolean;
    comparator: '=' | '<' | '>' | '<=' | '>=';
    value: any;
}

export interface IFilterState {
    keyword: string | number;
    withHistory: boolean;
    status: ICustomFilter;
    date: ICustomFilter;
}

const initialState: IFilterState = {
    keyword: '',
    withHistory: false,
    status: {
        enabled: true,
        comparator: '=',
        value: 1.00
    },
    date: {
        enabled: false,
        comparator: '=',
        value: new Date()
    }
}

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilter: (state, action: PayloadAction<IFilterState>) => {
            state.keyword = action.payload.keyword;
            state.status = action.payload.status;
            state.date = action.payload.date;
        },
        toggleFilter(state, action: PayloadAction<'status' | 'date'>) {
            if (action.payload === 'status') {
                state.status.enabled = !state.status.enabled;
            } else if (action.payload === 'date') {
                state.date.enabled = !state.date.enabled;
            }
        },
        setFilterKeyword: (state, action: PayloadAction<{ keyword: string | number }>) => {
            state.keyword = action.payload.keyword;
        },
        resetFilter: () => initialState,
        setWithHistory: (state, action: PayloadAction<boolean>) => {
            state.withHistory = action.payload;
        },
    }
})

export const { setFilter, setFilterKeyword, resetFilter, toggleFilter, setWithHistory } = filterSlice.actions;

export const selectFilter = (state: RootState) => state.filter;
export const selectKeyword = (state: RootState) => state.filter.keyword;
export const selectStatus = (state: RootState) => state.filter.status;
export const selectDate = (state: RootState) => state.filter.date;
export const selectWithHistory = (state: RootState) => state.filter.withHistory;

export default filterSlice.reducer;