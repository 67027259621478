import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { captureException } from "@sentry/react";
import axios from "axios";
import {
  KONSOLENKOST_URL,
  IOffer,
  IOfferImage,
  IOfferHistorySummary,
  IOfferPositionHistory,
  IOfferHistorySummaryEntry,
} from "../data/constants";
import type { RootState } from "./store";

export interface ISearchOffersResponse {
  0: number;
  1: IOffer[];
}

export interface IStatusDescriptions {
  status_id: number;
  beschreibung: string;
}

export enum EWordfilterBlocked {
  POSITIVE = 0,
  BLOCKED = 1,
  HIGHLIGHT = 2,
  REPLACE = 3,
}

export interface IWordfilterResponse {
  id: number;
  word: string;
  isBlocked: EWordfilterBlocked;
  wordException: string;
}

export interface IWordfilter {
  id: number;
  word: string;
  isBlocked: EWordfilterBlocked;
  wordException: string[];
}

export interface IOffersState {
  maxAmount: number;
  offers: IOffer[];
  currentOfferIndex: number;
  isSearching: boolean;
  statusDescriptions: IStatusDescriptions[];
  wordfilter: IWordfilter[];
}

const initialState: IOffersState = {
  maxAmount: 0, // the amount of potential unfiltered offers
  offers: [], // actual shown offers
  isSearching: false,
  currentOfferIndex: 0,
  statusDescriptions: [],
  wordfilter: [],
};

const reduceHistory = (history: IOfferPositionHistory[]): IOfferHistorySummary[] => {
  const itemPositionLookup: Record<string, { price: number; quantity: number }> = {};
  const reduced = history.reduce<Record<string, IOfferHistorySummary>>((reduced, current) => {
    const { angebot_id, position_id, timestamp, created, deleted, deltaQuantity, deltaPrice } = current;
    const previousPosition = itemPositionLookup[position_id] ?? { price: 0, quantity: 0 };
    const currentPosition = {
      price: previousPosition.price + deltaPrice,
      quantity: previousPosition.quantity + deltaQuantity,
    };

    const entry = reduced[timestamp] ?? {
      angebot_id,
      timestamp,
      delta: { items: 0, quantity: 0, price: 0 },
      total: { items: 0, quantity: 0, price: 0 },
      priceChangedCount: 0,
      quantityChangedCount: 0,
      users: [],
      items: [],
    };

    if (created) {
      entry.delta.items++;
    } else if (deleted) {
      entry.delta.items--;
    } else {
      if (deltaQuantity !== 0) entry.quantityChangedCount++;
      if (deltaPrice !== 0) entry.priceChangedCount++;
    }
    entry.delta.quantity += deltaQuantity;
    entry.delta.price += (previousPosition.quantity) * deltaPrice + deltaQuantity * currentPosition.price;
    if (current.user_name && entry.users.indexOf(current.user_name) < 0) entry.users.push(current.user_name);
    entry.items.push(current);

    itemPositionLookup[position_id] = currentPosition
    reduced[timestamp] = entry;
    return reduced;
  }, {});

  let runningTotal: IOfferHistorySummaryEntry = { items: 0, quantity: 0, price: 0 };
  const result = Object.values(reduced)
    .sort((a, b) => (new Date(a.timestamp) > new Date(b.timestamp) ? 1 : -1))
    .map((entry) => {
      runningTotal = {
        items: runningTotal.items + entry.delta.items,
        quantity: runningTotal.quantity + entry.delta.quantity,
        price: runningTotal.price + entry.delta.price,
      };

      return {
        ...entry,
        total: runningTotal,
      };
    });

  return result;
};

// search for offers and then updates state using extraReducers
export const fetchOffers = createAsyncThunk<ISearchOffersResponse, boolean | undefined, { state: RootState }>(
  "searchOffers",
  async (reset, { getState, rejectWithValue }) => {
    const { offers, filter } = getState();
    const AMOUNT_LOAD_OFFERS = 10;

    try {
      const response = await axios.get<ISearchOffersResponse>(`${KONSOLENKOST_URL}/api/ankauf/searchOffers.php`, {
        params: {
          amountOffers: reset ? 0 : offers.offers.length + AMOUNT_LOAD_OFFERS,
          keyword: filter.keyword,
          ...(filter.status.enabled && { statusComparator: filter.status.comparator, status: filter.status.value }),
          ...(filter.date.enabled && {
            dateComparator: filter.date.comparator,
            date: new Date(filter.date.value).getTime(),
          }),
          withHistory: filter.withHistory,
        },
      });

      return response.data;
    } catch (error) {
      captureException(error);
      return rejectWithValue({ 0: 0, 1: [] });
    }
  }
);

export const fetchStatusDescriptions = createAsyncThunk<IStatusDescriptions[], void, { state: RootState }>(
  "fetchStatusDescriptions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<IStatusDescriptions[]>(
        `${KONSOLENKOST_URL}/api/ankauf/getStatusDescriptions.php`
      );
      return response.data;
    } catch (error) {
      captureException(error);
      return rejectWithValue([]);
    }
  }
);

// retrieves the wordfilter list from the api
export const fetchWordfilter = createAsyncThunk<IWordfilterResponse[], void, { state: RootState }>(
  "fetchWordfilter",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<IWordfilterResponse[]>(`${KONSOLENKOST_URL}/api/ankauf/getWordFilter.php`);
      return response.data;
    } catch (error) {
      captureException(error);
      return rejectWithValue([]);
    }
  }
);

export const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    removeOffer: (state, action: PayloadAction<{ offerId: number }>) => {
      state.offers = state.offers.filter((offer) => offer.angebot_id !== action.payload.offerId);
    },
    incrementOfferIndex: (state) => {
      state.currentOfferIndex = Math.min(state.currentOfferIndex + 1, state.maxAmount - 1);
    },
    decrementOfferIndex: (state) => {
      state.currentOfferIndex = Math.max(state.currentOfferIndex - 1, 0);
    },
    resetOfferIndex: (state, action: PayloadAction<number | undefined>) => {
      state.currentOfferIndex = action.payload ?? 0;
    },
    selectOffer: (state, action: PayloadAction<{ offerId: number }>) => {
      state.currentOfferIndex = state.offers.findIndex((offer) => offer.angebot_id === action.payload.offerId);
    },
    checkOffer: (state, action: PayloadAction<{ offerId: number }>) => {
      state.offers = state.offers.map((offer) =>
        offer.angebot_id === action.payload.offerId ? { ...offer, checked: !offer.checked } : { ...offer }
      );
    },
    reviewOffer: (
      state,
      action: PayloadAction<{ offerId: number; userId: number; userName: string; totalValue: number }>
    ) => {
      state.offers = state.offers.map((offer) =>
        offer.angebot_id === action.payload.offerId
          ? {
              ...offer,
              confirm_users: [
                ...(offer.confirm_users ?? []),
                {
                  angebot_id: action.payload.offerId,
                  auszahlt_wert: action.payload.totalValue,
                  user_id: action.payload.userId,
                  user_name: action.payload.userName,
                  timestamp: new Date().toLocaleString(),
                },
              ],
            }
          : offer
      );
    },
    setOfferAdjustment: (state, action: PayloadAction<{ offerId: number; adjustment: number; comment: string; }>) => {
      state.offers = state.offers.map((offer) =>
        offer.angebot_id === action.payload.offerId
          ? { ...offer, adjustment_percent: action.payload.adjustment, adjustment_comment: action.payload.comment }
          : offer
      );
    },
    toggleOfferImage: (state, action: PayloadAction<{ offerId: number; imageIndex: number; value?: boolean }>) => {
      state.offers = state.offers.map((offer) => {
        if (offer.angebot_id !== action.payload.offerId) return offer;
        const upload_imgs_checked = offer.upload_imgs_checked ?? [];
        offer.upload_imgs.forEach((_, index) => {
          if (index === action.payload.imageIndex)
            upload_imgs_checked[index] = action.payload.value ?? !upload_imgs_checked[index];
        });

        return { ...offer, upload_imgs_checked };
      });
    },
    checkAllOffers: (state) => {
      state.offers = state.offers.map((offer) => ({ ...offer, checked: !offer.checked }));
    },
    sortOffers: (state, action: PayloadAction<{ order: "asc" | "desc"; orderBy: keyof IOffer }>) => {
      const order = action.payload.order;
      const orderBy = action.payload.orderBy;

      state.offers =
        order === "asc"
          ? (state.offers = state.offers
              .slice()
              .sort((a, b) =>
                (a[orderBy] + "").toLowerCase() > (b[orderBy] + "").toLowerCase()
                  ? 1
                  : (b[orderBy] + "").toLowerCase() > (a[orderBy] + "").toLowerCase()
                  ? -1
                  : 0
              ))
          : (state.offers = state.offers
              .slice()
              .sort((a, b) =>
                (a[orderBy] + "").toLowerCase() < (b[orderBy] + "").toLowerCase()
                  ? 1
                  : (b[orderBy] + "").toLowerCase() < (a[orderBy] + "").toLowerCase()
                  ? -1
                  : 0
              ));
    },
    decreaseMaxAmountOffers: (state) => {
      state.maxAmount = state.maxAmount - 1;
    },
    addOfferImages: (state, action: PayloadAction<{ offerId: number; images: IOfferImage[] }>) => {
      state.offers = state.offers.map((offer) => {
        if (offer.angebot_id !== action.payload.offerId) return offer;
        const upload_imgs = offer.upload_imgs ?? [];
        const upload_imgs_checked = offer.upload_imgs_checked ?? [];
        const addedImages = action.payload.images.filter(({ path }) => !upload_imgs.includes(path));

        offer.upload_imgs = [...upload_imgs, ...addedImages.map(({ path }) => path)];
        offer.upload_imgs_checked = [...upload_imgs_checked, ...addedImages.map(() => false)];
        if (offer.images) offer.images = [...offer.images, ...addedImages];
        return { ...offer };
      });
    },
    deleteOfferImage: (state, action: PayloadAction<{ offerId: number; image: string }>) => {
      state.offers = state.offers.map((offer) => {
        if (offer.angebot_id !== action.payload.offerId) return offer;
        const upload_imgs = offer.upload_imgs ?? [];
        const upload_imgs_checked = offer.upload_imgs_checked ?? [];
        const images = offer.images ?? [];

        offer.upload_imgs = upload_imgs.filter((img) => img !== action.payload.image);
        offer.upload_imgs_checked = upload_imgs_checked.filter(
          (_, index) => upload_imgs[index] !== action.payload.image
        );
        if (offer.images) offer.images = images.filter((img) => img.path !== action.payload.image);
        return { ...offer };
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffers.pending, (state) => {
        state.isSearching = true;
      })
      .addCase(fetchOffers.fulfilled, (state, action) => {
        const offers = action.payload[1];

        // add all duplicate emails and a corresponding color to a list
        const duplicateEmails = offers
          ?.filter(
            (offer: IOffer, index: number, array: IOffer[]) =>
              array.findIndex((element) => element.email === offer.email) !== index
          )
          ?.map((data) => {
            return { email: data.email, color: stringToHex(data.email) };
          });

        // fill offerdata with additional data
        const newData = offers?.map(
          (data) =>
            (data = {
              ...data,
              checked: false,
              duplicate_email_color: duplicateEmails?.find((email) => email.email === data.email)?.color ?? "",
              item_data: data.item_data?.map((item) => ({ ...item, session_item_id: randomizer() })),
              history: data.history && reduceHistory(data.history as unknown as IOfferPositionHistory[]),
            })
        );

        state.maxAmount = action.payload[0];
        state.offers = newData;
        state.isSearching = false;
      })
      .addCase(fetchStatusDescriptions.fulfilled, (state, action) => {
        state.statusDescriptions = action.payload;
      })
      .addCase(fetchWordfilter.fulfilled, (state, action) => {
        state.wordfilter = action.payload.map((filter) => ({
          ...filter,
          wordException: filter.wordException !== null ? filter.wordException.toString().split(", ") : [],
        }));
      });
  },
});

export const {
  removeOffer,
  checkOffer,
  checkAllOffers,
  sortOffers,
  decreaseMaxAmountOffers,
  incrementOfferIndex,
  decrementOfferIndex,
  resetOfferIndex,
  selectOffer,
  toggleOfferImage,
  setOfferAdjustment,
  addOfferImages,
  deleteOfferImage,
  reviewOffer,
} = offersSlice.actions;

export const selectOffersMaxAmount = (state: RootState) => state.offers.maxAmount;
export const selectOffersAmount = (state: RootState) => state.offers.offers.length;
export const selectOffers = (state: RootState) => state.offers.offers;
export const selectCurrentOfferIndex = (state: RootState) => state.offers.currentOfferIndex;
export const selectCurrentOffer = (state: RootState) => state.offers.offers[state.offers.currentOfferIndex];
export const selectOfferById = (state: RootState, offerId: number) =>
  state.offers.offers.find((offer) => offer.angebot_id === offerId);
export const selectOffersChecked = (state: RootState) => state.offers.offers.filter((offer) => offer.checked);
export const selectIsSearching = (state: RootState) => state.offers.isSearching;
export const selectStatusDescriptions = (state: RootState) => state.offers.statusDescriptions;
export const selectWordfilter = (state: RootState) => state.offers.wordfilter;

export default offersSlice.reducer;

const stringToHex = (string: string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

const randomizer = () => {
  return Math.floor((Math.random() * 0xffffff) << 0)
    .toString(16)
    .substring(1);
};
