
import React from 'react';
import { PaletteMode } from '@mui/material';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { useStoreSelector } from '../hooks/useStore';
import { selectTheme } from '../store/themeSlice';

declare module '@mui/material/styles' {

    interface Palette {
        contrast: {
            default: string;
            light: string;
        }
    }

    interface Theme {

    }

    interface ThemeOptions {
        mode: PaletteMode;
    }

}

interface IProps {
    children: React.ReactNode;
}

const ThemeProvider = ({ children }: IProps) => {
    const mode = useStoreSelector(selectTheme);

    const theme = createTheme({
        mode,
        palette: {
            primary: { main: '#007bff' },
            secondary: { main: '#7b42a1' },
            error: { main: '#dc3545' },
            success: { main: '#4caf50' },
            warning: { main: '#f1c40f' },
            ...(mode === 'light'
                ? {
                    // palette values for light mode
                    background: { default: '#ededed', paper: '#fafafa' },
                    text: { primary: '#111' },
                    contrast: { default: '#212121', light: '#ccc' }
                }
                : {
                    // palette values for dark mode
                    background: { default: '#1f1f1f', paper: '#303030' },
                    text: { primary: '#fff' },
                    contrast: { default: '#fff', light: '#666' }
                }),
            action: {
                hoverOpacity: 0.7
            }
        },
        shape: {
            borderRadius: 3
        }
    });

    return (
        <MUIThemeProvider theme={theme}>
            {children}
        </MUIThemeProvider>
    )
};

export default ThemeProvider;