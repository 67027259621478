import axios, { AxiosInstance } from 'axios';
import { SEARCH_ROWS, SOLR_URL } from '../data/constants';

export default class APISearch {
    private init = (): AxiosInstance => {    
        const headers = {
            "Accept": "application/json, text/plain"
        }
        return axios.create({
            baseURL: SOLR_URL,
            headers: headers
        })
    }

    searchArticles = (searchString: string, filterCategory: number, rows = SEARCH_ROWS) => {
        return this.init().get("/select", {
          params: {
            q: searchString.trim().replace(/\s*(-|\.)\s*/, ' ').replace(/\s+|(?!<\*)$/g, "* "),
            fq: filterCategory !== 0 ? `cat2_id:${filterCategory}` : '-text:" nv"',
            wt: "json",
            rows,
            index: true,
            sort: `sales desc`,
          },
        });
    }
}
