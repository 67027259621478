import { useCallback, useEffect, useState } from "react";
import { selectIsLoading } from "../store/statusSlice";
import { useStoreSelector } from "./useStore";

const useLoadingQueue = () => {
  const loading = useStoreSelector(selectIsLoading);
  const [running, setRunning] = useState(false);
  const [queue, setQueue] = useState<
    {
      callback: () => any;
      resolve: (args: any) => any;
      reject: (args: any) => any;
    }[]
  >([]);

  const enqueueCallback = useCallback((callback: () => any) => {
    return new Promise((resolve, reject) => {
      setQueue((prev) => [...prev, { callback, resolve, reject }]);
    });
  }, []);

  useEffect(() => {
    if (loading || running || queue.length === 0) return;

    const next = queue.shift()!;
    setRunning(true);
    (async () => await next.callback())()
      .then(next.resolve)
      .catch(next.reject)
      .finally(() => setRunning(false));
  }, [loading, running, queue]);

  return enqueueCallback;
};

export default useLoadingQueue;
