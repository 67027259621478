import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

export interface ICustomArticleTemplate {
  name: string;
  baseArticleId?: string;
  image?: string;
}

export interface ITemplatesState {
  manualArticles: ICustomArticleTemplate[];
}

const initialState: ITemplatesState = {
  manualArticles: localStorage.getItem("manualArticleTenplates")
    ? JSON.parse(localStorage.getItem("manualArticleTenplates")!)
    : [],
};

export const tenplatesSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    addManualArticleTemplate: (state, action: PayloadAction<ICustomArticleTemplate>) => {
      state.manualArticles.push(action.payload);
      localStorage.setItem("manualArticleTenplates", JSON.stringify(state.manualArticles));
    },
    updateManualArticleTemplate: (
      state,
      action: PayloadAction<{ original: ICustomArticleTemplate; updated: ICustomArticleTemplate }>
    ) => {
      state.manualArticles = state.manualArticles.map((article) => {
        if (article.name === action.payload.original.name) return action.payload.updated;
        return article;
      });
      localStorage.setItem("manualArticleTenplates", JSON.stringify(state.manualArticles));
    },
    removeManualArticleTemplate: (state, action: PayloadAction<ICustomArticleTemplate>) => {
      state.manualArticles = state.manualArticles.filter((article) => article.name !== action.payload.name);
      localStorage.setItem("manualArticleTenplates", JSON.stringify(state.manualArticles));
    },
    clearManualArticleTemplates: (state) => {
      state.manualArticles = [];
      localStorage.removeItem("manualArticleTenplates");
    },
  },
});

export const { addManualArticleTemplate, clearManualArticleTemplates, removeManualArticleTemplate } =
  tenplatesSlice.actions;

export const selectArticleTemplates = (state: RootState) => state.templates.manualArticles;
export const searchArticleTemplate = (state: RootState, name: string, normalizeName: (name: string) => string) => {
  return state.templates.manualArticles.filter(
    (article) => normalizeName(article.name).includes(normalizeName(name)) || article.baseArticleId === name
  );
};
export const getArticleTemplate = (state: RootState, name: string) => {
  return state.templates.manualArticles.find((article) => article.name === name);
};

export default tenplatesSlice.reducer;
