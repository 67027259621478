import { useEffect, useRef, useState } from "react";

const useLoadMoreButtonRef = (loadMore: () => void) => {
  const [intersectionObserver, setIntersectionObserver] = useState<IntersectionObserver | null>(null)
  const loadMoreRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
        if (entries.some(entry => entry.isIntersecting)) {
            loadMore()
        }
    }, { threshold: 1 });
    setIntersectionObserver(observer);

    return () => {
        observer.disconnect();
    }
  }, [loadMore]);

  useEffect(() => {
    const { current } = loadMoreRef;
    if (current) {
        intersectionObserver?.observe(current);
        return () => intersectionObserver?.unobserve(current);
    }
}, [intersectionObserver, loadMoreRef]);

  return loadMoreRef;
}

export default useLoadMoreButtonRef;