import { TextRecognitionImageProps } from "@konsolenkost-de/react-image-mousetools";
import { IUserState } from "../store/userSlice";

export const DEBUG = process.env.NODE_ENV !== "production";

export const MARIO_URL = "https://mario.konsolenkost-tools.de";
export const KONSOLENKOST_URL = "https://app.konsolenkost-tools.de/KonsolenkostApplication"; // DEBUG ? 'http://localhost:8081' : 'https://app.konsolenkost-tools.de/KonsolenkostApplication'
export const SOLR_URL = "https://articles-search.konsolenkost-tools.de/solr/ebay3/";

export const AUTOSAVE_INTERVAL = 60000;
export const SEARCH_THROTTLE = 200;
export const SEARCH_DELAY = 500;
export const SEARCH_ROWS = 50;
export const SEARCH_PREVIEW_ROWS = 5;
export const REVIEW_PRICE_THRESHOLD = 1000;
export const REVIEW_PRICE_REVIEWERS = 2;

export const DEBUG_USER: IUserState = {
  id: 92,
  name: "aram",
  loginString:
    "rhdKrThPt//wdwCjj7nKIPxPLDDuL5IqHRUGICpIkOOPeTqV5b4zitypNXhBeb86T3yhanYBfwmOro/pb9S/24LUa/q1u/HPhwH7jiWz28ZtZK8XfkgTuw61fRFrNnQriRUr1Pn+iBjkJs+ul85qzxQmn42visuQir84bzdtu/qjNAmgkFghi+g35/hIfz3o",
};

export const VIEW_HISTORY_USERS = [
  6, // Sebastian
  29, // Kenneth
  92, // Aram
];

export const ADJUSTMENT_USERS: Record<number, number[]> = {
  5: [0, -10, -20, 10], // Ben
  6: [0, -10, -20, 10, 20], // Sebastian
  29: [0, -10, -20, 10], // Kenneth
  92: [0, -10, -20, 10], // Aram
};

export enum MAIL_TYPE {
  SEND_OFFER = 1,
  TOO_LOW = 2,
  NOT_ACCEPTED = 4,
  CUSTOM_OFFER = 6,
}

export enum ARTICLE_CATEGORY {
  UNDEFINED = 0,
  ACCESSORY = 214,
  GAME = 224,
  CONSOLE = 1559,
}

export enum HISTORY_TYPE {
  MATCHING_PURCHASE_PRICE = 1,
  QUANTITY = 2,
}

export const ARTICLE_CONDITIONS = [
  "mit OVP",
  "sehr guter Zustand",
  "gebraucht",
  "starke Gebrauchsspuren",
  "DEFEKT",
  "Modul",
  "nur CD",
  "ohne Spiel",
  "Dritthersteller",
  "Platinum",
  "Essentials",
  "Player's Choice",
  "Playstation Hits",
  "DE Version",
  "UK Version",
  "US Version",
  "JAP Import",
];

export const parseReadableDate = (parse_date: string | number | Date) => {
  var options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  var date = new Date(parse_date);
  return date.toLocaleDateString("de-DE", options);
};

export interface IOfferImage {
  id: string;
  angebot_id: string;
  path: string;
  rotation: string;
  checked?: string;
  vision_data?: {
    simplifiedTextAnnotations: TextRecognitionImageProps["recognizedText"];
  };
  upload_user: string | null;
  updated_at: string;
  created_at: string;
}

export interface IOffer {
  amount: number;
  angebot_id: number;
  auszahlungsart: string;
  bankleitzahl: string;
  bar_value: number;
  checked: boolean;
  comment: string;
  comments: {
    angebot_id: number;
    comment: string;
    date: string;
    id: number;
    username: string;
  }[];
  coupon_value: number;
  description: string;
  duplicate_email_color: string;
  email: string;
  first_status_date: string;
  incoming_amount: string;
  item_data: {
    cat2_id: ARTICLE_CATEGORY;
    img: string;
    item_id: number;
    matching_item_id: string;
    matching_purchase_price: number;
    matching_purchase_price_original: number;
    matching_price_outdated?: boolean;
    name: string;
    quantity: number;
    session_item_id: string;
    stamp_pr: string;
    checked?: boolean;
    comment?: string;
  }[];
  kontoinhaber: string;
  kontonummer: string;
  kreditinstitut: string;
  kunden_id: number;
  label_cost: number;
  land: string;
  name: string;
  offer_count_under_2_1: number;
  status_date: string;
  status_description: string;
  status_id: number;
  token: string;
  total_upload_foto: number;
  unrelease: number;
  upload_foto: string;
  upload_imgs: string[];
  upload_imgs_checked?: boolean[];
  images: IOfferImage[] | null;
  adjustment_percent?: number;
  adjustment_comment?: string;
  history?: IOfferHistorySummary[];
  confirm_users?: {
    id?: number;
    angebot_id?: number;
    auszahlt_wert: number;
    user_id: number;
    user_name: string;
    timestamp: string;
  }[];
}

export interface ISearchItem {
  actions: string[];
  amazon_de_timestamp: string;
  article_id: string;
  basic: 0 | 1;
  bedarf30: number;
  bedarf30_group: number;
  bedarf90: number;
  bedarf180: number;
  bedarf365: number;
  bestand: number;
  bundle: number;
  cat1_id: number;
  cat2_id: ARTICLE_CATEGORY;
  ebayKeyExclude: string;
  ebayKeyInclude: string;
  ebayKeyOr: string;
  frozen: number;
  group_id: number;
  group_type: number;
  image_name: string;
  julian_price: number;
  julian_price_original: number;
  julian_price_source: string;
  label_id: number[];
  name: string;
  on_demand: number;
  price: number;
  price_reset: boolean;
  pricing_id: number;
  pricing_status: string;
  pricing_timestamp: string;
  purchase_price: number;
  purchase_rank: number;
  sales: number;
  sales30: number;
  sales90: number;
  sales180: number;
  sales999: number;
  score: number;
  search_string: string;
  stock: number;
  stockValue: number;
  stock_turnover_day: number;
  stock_turnover_day_min: number;
  super_group_id: number;
  text: string[];
  vk30: number;
  vk90: number;
  vk180: number;
  vk365: number;
  vk999: number;
}

export interface ISearchResult {
  docs: ISearchItem[];
  maxScore: number;
  numFound: number;
  start: 0;
}

export interface IOfferPriceHistory {
  matching_item_id: string;
  matching_item_name: string;
  average_price: number;
  average_delta: number;
  average_manual_price: number;
  average_manual_delta: number;
  manual_count: number;
  last_update: string;
  history: {
    id: string;
    angebot_id: string;
    position_id: string;
    matching_item_id: string;
    matching_item_name: string;
    type: number;
    value: number;
    comment: string;
    user_id: string;
    user_name: string;
    timestamp: string;
    previous_value: number;
    delta: number;
  }[];
}

export interface IOfferPositionHistory {
  angebot_id: string;
  position_id: string;
  price: number;
  quantity: number;
  comment: string;
  timestamp: string;
  matching_item_id: string;
  matching_item_name: string;
  user_name: string;
  deltaPrice: number;
  deltaQuantity: number;
  created?: boolean;
  deleted?: boolean;
}

export interface IOfferHistorySummaryEntry {
  items: number;
  quantity: number;
  price: number;
}

export interface IOfferHistorySummary {
  angebot_id: string;
  timestamp: string;
  total: IOfferHistorySummaryEntry;
  delta: IOfferHistorySummaryEntry;
  quantityChangedCount: number;
  priceChangedCount: number;
  users: string[];
  items: IOfferPositionHistory[];
}
