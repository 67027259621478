import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Card, Box, TextField, Typography, Grid, Stack, Button as MuiButton } from "@mui/material";
import OfferSearchContainer from "./OfferSearchContainer";
import Image from "./OfferImage";
import { CommentContext, ISearchValue, OfferContext, SearchContext } from "../../data/contexts";
import { parseReadableDate } from "../../data/constants";
import getSelectedText from "../../helper/getSelectedText";
import APIOffers from "../../helper/APIOffers";
import { useStoreDispatch, useStoreSelector } from "../../hooks/useStore";
import { selectWordfilter } from "../../store/offersSlice";
import { alert } from "../../store/alertSlice";
import useArticleHelpers from "../../hooks/useArticleHelpers";
import { OpenInNew } from "@mui/icons-material";

const PREFIX = "OfferBody";

const classes = {
  descriptionContainer: `${PREFIX}-descriptionContainer`,
  descriptionHeader: `${PREFIX}-descriptionHeader`,
  descriptionContent: `${PREFIX}-descriptionContent`,
  commentContainer: `${PREFIX}-commentContainer`,
  commentContent: `${PREFIX}-commentContent`,
  inputField: `${PREFIX}-inputField`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.descriptionContainer}`]: {
    height: 0,
    marginBottom: "10px",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.descriptionHeader}`]: {
    display: "flex",
    flexGrow: 0,
    flexShrink: 0,
    alignItems: "center",
    justifyContent: "space-between",
    height: "50px",
    padding: "0 10px",
    borderBottom: "2px solid " + theme.palette.primary.main,
  },

  [`& .${classes.descriptionContent}`]: {
    flexGrow: 1,
    padding: "5px",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    fontWeight: "bold",
  },

  [`& .${classes.descriptionContent}, & .${classes.commentContainer}`]: {
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      boxShadow: "inset 0 0 15px #222",
      borderRadius: "10px",
    },
  },

  [`& .${classes.commentContent}`]: {
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      boxShadow: "inset 0 0 15px #222",
      borderRadius: "10px",
    },
  },

  [`& .${classes.inputField}`]: {
    "& fieldset": {
      borderColor: theme.palette.contrast.light,
    },
  },
}));

const OfferBody = () => {
  const dispatch = useStoreDispatch();
  const { replaceTerms, wordFilterDescription } = useArticleHelpers();

  const offer = useContext(OfferContext);
  const comments = useContext(CommentContext).value;
  const setComments = useContext(CommentContext).changeValue;
  const wordfilter = useStoreSelector(selectWordfilter);

  const [description, setDescription] = useState(
    (offer.description + "").replaceAll("<", "&lt;")?.replaceAll(">", "&gt;")
  );
  const [commentContent, setCommentContent] = useState("");

  const [searchString, setSearchString] = useState("");
  const [searchValues, setSearchValues] = useState<ISearchValue[]>(searchString ? [{ title: searchString }] : []);
  const [currentSearchValue, setCurrentSearchValue] = useState<ISearchValue>({ title: "" });
  const [searchValueIndex, setSearchValueIndex] = useState<number>(1);

  /**
   * Adds a string to the searchbar with or without spaces before depending on the current state
   * @param original
   */
  const addSearchValue = (original: string, index?: number, pinned = false) => {
    if (original === "") return;

    const maxPinnedIndex = (searchValues as any).findLastIndex((value: ISearchValue) => value.pinned);
    if (index !== undefined) {
      index = pinned ? Math.min(index, maxPinnedIndex + 1) : Math.max(index, maxPinnedIndex + 1);
    } else if (pinned) {
      index = maxPinnedIndex + 1;
    }
    
    setSearchValues((prev) => {
      const newSearchValues = [...prev];
      const title = replaceTerms(original, wordfilter);
      newSearchValues.splice(index ?? newSearchValues.length, 0, title === original ? { title, pinned } : { title, original, pinned });

      return newSearchValues;
    });
    setSearchValueIndex(searchValues.length + 1);
  };

  const clearSearchValues = () => {
    setSearchValues([]);
    setCurrentSearchValue({ title: "" });
  };

  useEffect(() => {
    const parts = searchValues.map((value) => value.title);
    if (currentSearchValue.title !== "") parts.push(currentSearchValue.title);
    setSearchString(parts.join(" "));
  }, [searchValues, currentSearchValue]);

  ///////////////////////////////////////////
  //
  //          Description Logic
  //
  ///////////////////////////////////////////

  /**
   * Posts a new comment to the offer
   * @param e
   * @returns when comment is empty
   */
  const handleSubmitComment = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (commentContent === "" || e.key !== "Enter") return;

    new APIOffers(dispatch)
      .postComment(offer.angebot_id, commentContent)
      .then((response) => {
        if (response.data.length !== 0) {
          setCommentContent("");
          setComments(response.data);
          dispatch(alert({ severity: "success", message: "Kommentar gespeichert." }));
        } else {
          dispatch(alert({ severity: "error", message: "Fehler beim Speichern des Kommentars." }));
        }
      })
      .catch((error) => {
        dispatch(alert({ severity: "error", message: "Fehler beim Speichern des Kommentars." }));
      });
  };

  ///////////////////////////////////////////
  //
  //                Rendering
  //
  ///////////////////////////////////////////

  useEffect(() => {
    setDescription(wordFilterDescription(description, wordfilter));
    // eslint-disable-next-line
  }, [wordfilter]);

  return (
    <SearchContext.Provider
      value={{
        searchString,
        searchValues,
        currentSearchValue,
        searchValueIndex,
        setCurrentSearchValue,
        setSearchValueIndex,
        setSearchValues,
        addSearchValue,
        clearSearchValues,
        resetSearch: (title, pinned = false) => {
          setSearchValues([{ title, pinned }]);
          setCurrentSearchValue({ title: "" });
        },
      }}
    >
      <StyledGrid item container spacing={1} xs={true} sx={{ overflow: "hidden" }}>
        {/* ---- Image ---- */}
        <Image />

        {/* ---- Description/Comments ---- */}
        <Grid item xs={3} sx={{ height: "100%" }}>
          <Stack direction="column" spacing={0} style={{ height: "100%" }}>
            {/* ---- Description ---- */}
            <Card variant={"outlined"} className={classes.descriptionContainer} sx={{ borderColor: "contrast.light", flex: "1 1 auto", overflowY: "auto" }}>
              <Stack direction="row" spacing={1} className={classes.descriptionHeader}>
                <Typography variant={"subtitle1"}>
                  <b>Beschreibung</b>
                </Typography>
                <Typography variant={"subtitle1"} >
                  <MuiButton startIcon={<OpenInNew />} href={`https://ankauf.konsolenkost.de#/offer?offer=${offer.token}`} target="_blank">
                    Angebotslink
                  </MuiButton>
                </Typography>
              </Stack>
              <Typography
                variant={"subtitle2"}
                className={classes.descriptionContent}
                onClick={() => addSearchValue(getSelectedText())}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Card>
            {/* ---- Comments ---- */}
            <Card variant={"outlined"} className={classes.commentContainer} sx={{ borderColor: "contrast.light", minHeight: "200px" }}>
              <Box p={1} className={classes.commentContent}>
                {comments?.map((comment, commentIndex) => (
                  <Typography variant={"subtitle2"} key={commentIndex}>
                    {parseReadableDate(comment.date)} - {comment.username}: {comment.comment}
                  </Typography>
                ))}
              </Box>
            </Card>
            <Card>
              <TextField
                className={classes.inputField}
                placeholder={"Kommentar eingeben"}
                fullWidth
                multiline={false}
                value={commentContent}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setCommentContent(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => handleSubmitComment(e)}
              />
            </Card>
          </Stack>
        </Grid>

        {/* ---- Search Container ---- */}
        <OfferSearchContainer />
      </StyledGrid>
    </SearchContext.Provider>
  );
};

export default OfferBody;
