import axios from "axios";
import { KONSOLENKOST_URL, IOffer, MAIL_TYPE, IOfferImage, IOfferPriceHistory } from "../data/constants";
import { store } from "../store/store";
import { useStoreDispatch } from "../hooks/useStore";
import { startLoading, stopLoading } from "../store/statusSlice";
import { EWordfilterBlocked, addOfferImages, deleteOfferImage, fetchWordfilter } from "../store/offersSlice";

export default class APIOffers {
  private userId;
  private username;
  private userLoginString;
  private dispatch?: ReturnType<typeof useStoreDispatch>;

  private client;

  constructor(dispatch?: ReturnType<typeof useStoreDispatch>) {
    this.userId = store.getState().user.id;
    this.username = store.getState().user.name;
    this.userLoginString = store.getState().user.loginString;
    this.dispatch = dispatch;

    this.client = axios.create({
      baseURL: `${KONSOLENKOST_URL}/api/ankauf`,
    });
  }

  sendOffer = (offerId: number, mailType: MAIL_TYPE, articles: IOffer["item_data"], offerTotalPrice: number) => {
    const data = {
      offerId: offerId,
      type: mailType,
      articles: articles,
      offerTotalPrice: offerTotalPrice,
      sendMail: true,
      userId: this.userId,
      loginString: this.userLoginString,
    };

    this.dispatch && this.dispatch(startLoading());
    return this.client.post("/closeOpenOfferNewAnkauf.php", JSON.stringify(data)).finally(() => {
      this.dispatch && this.dispatch(stopLoading());
    });
  };

  changeOfferStatus = (offerId: number, oldStatus: number, newStatus: number) => {
    const data = {
      offerId: offerId,
      oldStatus: oldStatus,
      newStatus: newStatus,
      userId: this.userId,
      loginString: this.userLoginString,
    };

    this.dispatch && this.dispatch(startLoading());
    return this.client.post("/changeOfferStatus.php", JSON.stringify(data)).finally(() => {
      this.dispatch && this.dispatch(stopLoading());
    });
  };

  sendEmail = (offerId: number, mailType: MAIL_TYPE, content: string) => {
    const data = {
      offerId: offerId,
      type: mailType,
      customMailMessage: content,
      userId: this.userId,
      userName: this.username,
      loginString: this.userLoginString,
    };

    this.dispatch && this.dispatch(startLoading());
    return this.client.post("/sendMailToCustomer.php", JSON.stringify(data)).finally(() => {
      this.dispatch && this.dispatch(stopLoading());
    });
  };

  postComment = (offerId: number, comment: string) => {
    const data = {
      offerId: offerId,
      comment: comment,
      userId: this.userId,
      username: this.username,
      loginString: this.userLoginString,
    };

    this.dispatch && this.dispatch(startLoading());
    return this.client.post("/setOfferComment.php", JSON.stringify(data)).finally(() => {
      this.dispatch && this.dispatch(stopLoading());
    });
  };

  saveArticles = async (offerId: number, articles: IOffer["item_data"], setArticles?: (articles: IOffer["item_data"]) => void) => {
    const data = {
      offerId: offerId,
      articles: articles,
      userId: this.userId,
      loginString: this.userLoginString,
    };

    this.dispatch && this.dispatch(startLoading());
    const response = await this.client.post<IOffer["item_data"]>("/saveOfferArticles.php", JSON.stringify(data)).finally(() => {
      this.dispatch?.(stopLoading());
    });

    setArticles?.(
      articles.map((item) => {
        const newItem = response.data.find(
          (article) => article.item_id === item.item_id || article.session_item_id === item.session_item_id
        );
        if (newItem) return { ...item, item_id: newItem.item_id };
        return item;
      })
    );

    return response;
  };

  mergeOffers = (offers: number[]) => {
    const data = {
      offerIds: offers,
      userId: this.userId,
      userName: this.username,
      loginString: this.userLoginString,
    };

    this.dispatch && this.dispatch(startLoading());
    return this.client.post("/mergeOffer.php", JSON.stringify(data)).finally(() => {
      this.dispatch && this.dispatch(stopLoading());
    });
  };

  undoMergeOffer = (offerId: number) => {
    const data = {
      offerId: offerId,
      userId: this.userId,
      userName: this.username,
      loginString: this.userLoginString,
    };

    this.dispatch && this.dispatch(startLoading());
    return this.client.post("/undoMergeOffer.php", JSON.stringify(data)).finally(() => {
      this.dispatch && this.dispatch(stopLoading());
    });
  };

  getStatusDescriptions = () => {
    return this.client.get<{ status_id: number; beschreibung: string }[]>("/getStatusDescriptions.php");
  };

  requestVerifyOffer = (offerId: number, articles: IOffer["item_data"]) => {
    const data = {
      offerId: offerId,
      articles: articles,
      userId: this.userId,
      loginString: this.userLoginString,
    };

    this.dispatch && this.dispatch(startLoading());
    return this.client.post("/requestVerifyOffer.php", JSON.stringify(data)).finally(() => {
      this.dispatch && this.dispatch(stopLoading());
    });
  };

  saveWordListEntry = (word: string, isBlocked: EWordfilterBlocked, wordException: string[]) => {
    const data = {
      word: word,
      isBlocked: isBlocked,
      wordException: wordException,
      userId: this.userId,
      loginString: this.userLoginString,
    };

    this.dispatch && this.dispatch(startLoading());
    return this.client.post("/saveWordFilter.php", JSON.stringify(data)).finally(() => {
      if (this.dispatch) {
        this.dispatch(stopLoading());
        this.dispatch(fetchWordfilter());
      }
    });
  };

  deleteWordListEntry = (word: string, isBlocked: EWordfilterBlocked) => {
    const data = {
      word: word,
      isBlocked: isBlocked,
      userId: this.userId,
      loginString: this.userLoginString,
    };

    this.dispatch && this.dispatch(startLoading());
    return this.client.post("/deleteWordFilter.php", JSON.stringify(data)).finally(() => {
      if (this.dispatch) {
        this.dispatch(stopLoading());
        this.dispatch(fetchWordfilter());
      }
    });
  };

  addOfferImages = (offerId: number, images: string[]) => {
    const data = {
      offerId: offerId,
      images: images,
      userId: this.userId,
      loginString: this.userLoginString,
    };

    this.dispatch && this.dispatch(startLoading());
    return this.client
      .post<IOfferImage[]>("/addOfferImages.php", JSON.stringify(data))
      .then((result) => {
        this.dispatch?.(addOfferImages({ offerId, images: result.data }));
      })
      .finally(() => {
        this.dispatch && this.dispatch(stopLoading());
      });
  };

  deleteOfferImage = (offerId: number, image: string) => {
    const data = {
      offerId: offerId,
      image: image,
      userId: this.userId,
      loginString: this.userLoginString,
    };

    this.dispatch && this.dispatch(startLoading());
    return this.client
      .post<IOfferImage[]>("/deleteOfferImage.php", JSON.stringify(data))
      .then(() => {
        this.dispatch?.(deleteOfferImage({ offerId, image }));
      })
      .finally(() => {
        this.dispatch && this.dispatch(stopLoading());
      });
  };

  saveOfferAttributes = (offerId: number, offer: Partial<Pick<IOffer, "adjustment_percent">>) => {
    const data = {
      offerId: offerId,
      offer,
      userId: this.userId,
      loginString: this.userLoginString,
    };

    this.dispatch && this.dispatch(startLoading());
    return this.client.post("/setOfferAttributes.php", JSON.stringify(data)).finally(() => {
      this.dispatch && this.dispatch(stopLoading());
    });
  };

  confirmReviewOffer = (offerId: number, value: number) => {
    const data = {
      offerId,
      value,
      userId: this.userId,
      userName: this.username,
      loginString: this.userLoginString,
    };

    this.dispatch && this.dispatch(startLoading());
    return this.client.post("/confirmReviewOffer.php", JSON.stringify(data)).finally(() => {
      this.dispatch && this.dispatch(stopLoading());
    });
  };

  getOfferPriceHistory = (from?: Date | null, to?: Date | null, includeAutoPrice?: boolean) => {
    const params: string[] = [];

    if (from) {
      params.push(`from=${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`);
    }
    if (to) {
      params.push(`to=${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}%2023:59:59`);
    }
    if (includeAutoPrice) {
      params.push(`includeAutoPrice=${includeAutoPrice}`);
    }

    return this.client.get<IOfferPriceHistory[]>(
      `/getOfferPriceHistory.php${params.length > 0 ? `?${params.join("&")}` : ""}`
    );
  };
}
