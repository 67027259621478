interface IProps {
    width: string | number;
    height: string | number;
    color: string;
    style?: any;
}

export const Accessory = ({ width, height, color, style }: IProps) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 160.000000 160.000000" preserveAspectRatio="xMidYMid meet" style={style}>
            <g transform="translate(0.000000,160.000000) scale(0.100000,-0.100000)" fill={color} stroke={color} strokeWidth="25">
                <path d="M448 1200 c-99 -53 -168 -203 -213 -464 -35 -208 0 -329 102 -343 55
                -7 112 22 200 102 93 85 120 97 229 103 141 7 179 -6 283 -95 91 -79 148 -112
                194 -113 48 0 87 28 109 77 50 113 -1 448 -92 608 -34 59 -94 120 -133 134
                -50 19 -129 12 -222 -19 -49 -16 -96 -30 -106 -30 -9 0 -57 14 -107 30 -109
                36 -188 39 -244 10z m236 -67 c95 -32 133 -32 249 6 155 50 214 30 280 -97 71
                -134 122 -401 98 -512 -15 -69 -25 -80 -71 -80 -42 0 -96 35 -180 116 -25 24
                -67 53 -95 65 -68 30 -198 37 -284 15 -70 -18 -99 -36 -183 -113 -69 -63 -110
                -85 -151 -81 -69 7 -85 118 -47 324 24 130 42 190 82 265 68 131 133 151 302
                92z"/>
                <path d="M526 1024 c-3 -9 -6 -27 -6 -40 0 -19 -5 -24 -24 -24 -45 0 -56 -10
                -56 -50 0 -40 11 -50 56 -50 19 0 24 -5 24 -24 0 -45 10 -56 50 -56 40 0 50
                11 50 56 0 19 5 24 24 24 45 0 56 10 56 50 0 40 -11 50 -56 50 -19 0 -24 5
                -24 24 0 45 -10 56 -50 56 -25 0 -40 -5 -44 -16z"/>
                <path d="M1027 1012 c-18 -19 -22 -59 -9 -78 9 -13 46 -34 62 -34 19 0 60 48
                60 70 0 50 -79 80 -113 42z"/>
                <path d="M897 889 c-50 -38 -24 -111 40 -111 29 0 63 33 63 62 0 23 -29 57
                -56 64 -13 4 -30 -1 -47 -15z"/>
            </g>
        </svg>
    )
}

export const Console = ({ width, height, color, style }: IProps) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 160.000000 160.000000" preserveAspectRatio="xMidYMid meet" style={style}>
            <g transform="translate(0.000000,160.000000) scale(0.100000,-0.100000)" fill={color} stroke={color} strokeWidth="25">
                <path d="M765 1330 c-52 -17 -91 -20 -300 -20 -173 -1 -244 -4 -256 -13 -38
                -29 -39 -44 -39 -527 l0 -472 29 -29 29 -29 572 0 571 0 24 25 25 24 0 486 0
                486 -25 24 c-23 24 -28 25 -172 25 -120 1 -158 4 -203 20 -72 25 -176 25 -255
                0z m260 -61 c77 -29 154 -102 191 -181 68 -148 20 -346 -105 -433 -21 -14 -42
                -25 -49 -25 -7 0 -12 31 -14 92 l-3 93 -155 0 -155 0 -3 -92 c-3 -114 -10
                -116 -91 -35 -80 80 -104 145 -99 267 3 79 8 98 38 153 43 81 122 146 210 173
                53 16 177 10 235 -12z m-415 -25 c0 -3 -18 -26 -40 -51 -62 -71 -84 -139 -85
                -258 0 -94 2 -105 34 -170 130 -264 472 -319 671 -109 149 158 155 388 15 552
                l-37 42 96 0 96 0 -2 -422 -3 -423 -560 0 -560 0 -3 163 -2 162 79 0 c58 0 82
                4 91 15 10 12 10 18 0 30 -9 11 -33 15 -91 15 l-79 0 0 30 0 30 79 0 c58 0 82
                4 91 15 10 12 10 18 0 30 -9 11 -33 15 -91 15 l-79 0 0 30 0 30 73 0 c80 0
                113 14 101 43 -5 14 -20 17 -90 17 l-84 0 0 35 0 35 79 0 c60 0 82 4 92 16 23
                28 -14 44 -98 44 l-73 0 0 45 0 45 190 0 c105 0 190 -3 190 -6z m380 -568 l0
                -84 -45 -7 c-25 -3 -70 -3 -100 0 l-55 7 0 84 0 84 100 0 100 0 0 -84z"/>
                <path d="M685 1098 c-51 -27 -61 -89 -22 -126 22 -21 32 -22 228 -22 190 0
                207 1 226 20 12 10 24 28 28 40 9 27 -14 72 -45 87 -32 16 -385 18 -415 1z
                m403 -70 c3 -17 -11 -18 -192 -18 -119 0 -197 4 -201 10 -16 26 12 30 198 28
                173 -3 192 -5 195 -20z"/>
                <path d="M338 659 c-15 -8 -18 -24 -18 -82 0 -77 9 -97 45 -97 36 0 45 20 45
                97 0 61 -3 74 -19 83 -24 12 -31 12 -53 -1z"/>
                <path d="M467 563 c-4 -3 -7 -21 -7 -39 0 -38 19 -48 86 -42 32 2 40 8 48 30
                14 41 -6 58 -69 58 -28 0 -55 -3 -58 -7z"/>
            </g>
        </svg>
    )
}

export const Game = ({ width, height, color, style }: IProps) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 160.000000 160.000000" preserveAspectRatio="xMidYMid meet" style={style}>
            <g transform="translate(0.000000,160.000000) scale(0.100000,-0.100000)" fill={color} stroke={color} strokeWidth="25">
                <path d="M202 1368 c-17 -17 -17 -1139 0 -1156 17 -17 939 -17 956 0 7 7 12
                41 12 85 l0 72 42 30 c94 65 149 137 180 234 50 162 -15 343 -161 445 l-60 43
                -3 127 -3 127 -475 3 c-362 2 -479 -1 -488 -10z m908 -673 l0 -435 -430 0
                -430 0 0 435 0 435 430 0 430 0 0 -435z m125 314 c42 -37 91 -117 79 -128 -17
                -15 -132 -60 -138 -54 -3 4 -6 53 -6 110 0 118 6 124 65 72z m68 -360 c15 -7
                27 -16 27 -21 0 -23 -46 -94 -85 -134 -70 -69 -75 -64 -75 74 0 65 3 121 6
                125 6 5 61 -14 127 -44z"/>
                <path d="M500 915 l0 -45 -45 0 -45 0 0 -180 0 -180 40 0 40 0 0 45 0 45 190
                0 190 0 0 -45 0 -45 40 0 40 0 0 180 0 180 -45 0 -45 0 0 45 0 45 -40 0 -40 0
                0 -45 0 -45 -100 0 -100 0 0 45 0 45 -40 0 -40 0 0 -45z m90 -186 l0 -50 -47
                3 -48 3 -3 48 -3 47 50 0 51 0 0 -51z m280 1 l0 -50 -50 0 -50 0 0 50 0 50 50
                0 50 0 0 -50z"/>
            </g>
        </svg>
    )
}
