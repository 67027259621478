import { useCallback } from "react";

const useDeltaStyle = () => {
  const getDeltaColor = useCallback((delta: number) => (delta === 0 ? "default" : delta > 0 ? "success" : "error"), []);
  const getDeltaLabel = useCallback(
    (delta: number, fixed = 2) => `${delta >= 0 ? "+" : ""}${delta.toFixed(fixed)}`,
    []
  );

  return { getDeltaColor, getDeltaLabel };
};

export default useDeltaStyle;
