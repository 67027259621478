import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { Checkbox, IconButton, TableCell, TableRow } from '@mui/material';
import { FaSearch, FaUndo } from 'react-icons/fa';
import { captureException, captureMessage } from '@sentry/react';
import { OfferContext } from '../../data/contexts';
import { parseReadableDate } from '../../data/constants';
import { useStoreDispatch } from '../../hooks/useStore';
import { checkOffer, selectOffer } from '../../store/offersSlice';
import { alert } from '../../store/alertSlice';
import APIOffers from '../../helper/APIOffers';

const DashboardOffer = () => {
    const navigate = useNavigate();
    const dispatch = useStoreDispatch();
    const offerApi = new APIOffers(dispatch);

    const offer = useContext(OfferContext);

    const isMergeOfferIdentifier = 'Angebot wurde zusammengefasst aus folgenden Angeboten: ';

    /**
     * Undos the merge of one or more offers into one
     * @param offerId 
     */
    const handleUndoMergeOffer = () => {
        offerApi.undoMergeOffer(offer.angebot_id)
            .then(response => {
                if (response.data) {
                    dispatch(alert({ severity: 'success', message: `Die Angebote wurden erfolgreich zurückgesetzt. Angebot Ids sind ${response.data}.` }));
                } else {
                    dispatch(alert({ severity: 'error', message: 'Bei dem Zurücksetzen der Angebote ist ein Fehler aufgetreten.' }));
                    captureMessage('Bei dem Zurücksetzen der Angebote ist ein Fehler aufgetreten ' + response);
                }
            })
            .catch(error => {
                dispatch(alert({ severity: 'error', message: 'Bei dem Zurücksetzen der Angebote ist ein Fehler aufgetreten.' }));
                captureException(error);
            })
    }

    /**
     * Sets the searchbar to the chosen offers' id and redirects to the homepage
     */
    const handleHomepageLink = () => {
        dispatch(selectOffer({ offerId: offer.angebot_id }));
        navigate('/');
    }

    return (
        <TableRow
            hover
            onClick={() => dispatch(checkOffer({ offerId: offer.angebot_id }))}
            role={'checkbox'}
            aria-checked={offer.checked}
            tabIndex={-1}
            key={offer.angebot_id}
            selected={offer.checked}
            style={{ cursor: 'pointer' }}
        >
            <TableCell padding={'checkbox'} sx={{ bgcolor: offer.duplicate_email_color === '' ? 'background.paper' : offer.duplicate_email_color }}>
                <Checkbox
                    checked={offer.checked}
                    inputProps={{ 'aria-labelledby': `enhanced.table-checkbox-${offer.angebot_id}` }}
                    sx={{ color: 'text.primary' }}
                />
            </TableCell>
            <TableCell component={'th'} id={`enhanced.table-checkbox-${offer.angebot_id}`} scope={'row'} padding={'none'}>
                {offer.angebot_id}
            </TableCell>
            <TableCell>{offer.status_id.toFixed(2)}</TableCell>
            <TableCell>{parseReadableDate(offer.first_status_date)}</TableCell>
            <TableCell>{offer.name}</TableCell>
            <TableCell>{offer.email}</TableCell>
            <TableCell align={'right'}>
                {offer.comment.includes(isMergeOfferIdentifier) &&
                    <IconButton size={'small'} onClick={() => handleUndoMergeOffer()} sx={{ color: 'text.primary' }} title={'Zurücksetzen'}>
                        <FaUndo />
                    </IconButton>
                }
                <IconButton size={'small'} onClick={() => handleHomepageLink()} sx={{ color: 'text.primary' }} title={'Auf Homepage öffnen'}>
                    <FaSearch />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default DashboardOffer;