import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface IAlertValues {
    severity?: 'success' | 'info' | 'warning' | 'error';
    message: string;
    duration?: number;
}

export interface IAlertState extends IAlertValues {
    open: boolean;
}

const initialState: IAlertState = {
    open: false,
    severity: 'error',
    message: '',
    duration: 20000
}

export const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        alert: (state, action: PayloadAction<IAlertValues>) => {
            state.open = true;
            state.severity = action.payload.severity ?? 'error';
            state.message = action.payload.message;
            state.duration = action.payload.duration ?? 20000;
        },
        stopAlert: () => initialState
    }
})

export const { alert, stopAlert } = alertSlice.actions;

export const selectAlert = (state: RootState) => state.alert;

export default alertSlice.reducer;