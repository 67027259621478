import { alpha, Button as ButtonMUI } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface IProps {
    style?: any;
    sx?: any;
    color?: 'primary' | 'secondary' | 'success' | 'error' | 'warning';
    backgroundColor?: string;
    size?: 'small' | 'medium' | 'large';
    onClick?: () => void;
    disabled?: boolean;
    title?: string;
    children: React.ReactChild;
}

const Button = ({ style, sx, color, backgroundColor, size = 'medium', onClick = () => { }, disabled = false, title, children }: IProps) => {
    const theme = useTheme();

    const getPropColor = (): string => {
        let propColor = '';
        switch (color ?? backgroundColor) {
            case 'primary': propColor = theme.palette.primary.main; break;
            case 'secondary': propColor = theme.palette.secondary.main; break;
            case 'success': propColor = theme.palette.success.main; break;
            case 'error': propColor = theme.palette.error.main; break;
            case 'warning': propColor = theme.palette.warning.main; break;
            default: propColor = backgroundColor as string;
        }

        return propColor !== '' ?  propColor ?? theme.palette.primary.main : theme.palette.primary.main;
    }

    return (
        <ButtonMUI
            onClick={onClick}
            variant={'contained'}
            disabled={disabled}
            size={size}
            title={title}
            sx={{
                fontWeight: 'bold',
                backgroundColor: getPropColor(),
                '&:hover': {
                    backgroundColor: alpha(getPropColor(), theme.palette.action.hoverOpacity)
                },
                ...sx
            }}
            style={style}
        >
            {children}
        </ButtonMUI>
    )
}

export default Button;