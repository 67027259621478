import { Select } from "@mui/material";
import { IFilterState } from "../../store/filterSlice";
import { IStatusDescriptions } from "../../store/offersSlice";

const StatusFilter = ({
  value,
  open,
  onChange,
  className,
  statusDescriptions,
}: {
  value: IFilterState["status"];
  open?: boolean;
  onChange: (value: string) => void;
  className?: string;
  statusDescriptions: IStatusDescriptions[];
}) => {
  return (
    <Select
      value={value.value}
      open={open}
      onChange={(e) => onChange(e.target.value)}
      disabled={!value.enabled}
      className={className}
      native
    >
      {statusDescriptions.map((desc) => (
        <option key={desc.status_id} value={desc.status_id}>
          {desc.status_id.toFixed(2)} {desc.beschreibung}
        </option>
      ))}
    </Select>
  );
};

export default StatusFilter;
